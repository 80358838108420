import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { deleteReview } from "../../actions/reviews";
import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

const ConfirmDeleteReview = () => {

    const navigate = useNavigate(); 
    const dispatch = useDispatch();

    const location = useLocation();
    const reviewId = location.state.reviewId;
    const review = useSelector((state) => (reviewId ? state.userReviews.reviews.find((message) => message._id === reviewId) : null));

    const reviewAddress = review.reviewAddress;

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(deleteReview(reviewId));
        navigate("/user/dashboard");
    };

    return (
        <>
            <Navbar />
            <Box
                component="main"
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "left",
                }}
            >
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={10} sm={8} md={6}>
        
                                <Box sx={{ mt: 20, mb: 5 }}>

                                    <Typography
                                        variant="h5"
                                    >
                                        Confirming you want to delete this review?
                                    </Typography>

                                </Box>

                                <Box sx={{ mb: 5 }}>

                                    <Typography
                                        variant="h7"
                                    >
                                        {reviewAddress}
                                    </Typography>

                                </Box>
    
                                <Box sx={{ mt: 3, mb: 20 }}>
                                    <Button
                                        className="Button Submit"
                                        variant="contained"
                                        style={{ backgroundColor: "#484848" }}
                                        size="small"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Confirm Delete
                                    </Button>
                                </Box>
                   
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <StickyFooter />
        </>
    );
};

export default ConfirmDeleteReview;
