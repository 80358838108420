import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { contactusform } from "../../actions/users";
import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";
import "./styles.css";
import useStyles from "./styles";

const initialState = { name: "", email: "", enquiry: "" };

const ContactUs = () => {
  const classes = useStyles();
  const [formData, setForm] = useState(initialState);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(contactusform(formData));
    setIsSubmit(true);
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Contact Us - Aussie Rent Reviews</title>
          <meta
            name="description"
            content="Email or call us today to learn more about Aussie Rent Reviews and how many people are now earning for a simple review"
          />
        </Helmet>
      </div>
      <Navbar />
      <Grid container component="main" sx={{ minHeight: "90vh" }}>
        <Grid
          item
          xxs={false}
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: "url(/beazy-aX1TTOuq83M-unsplash.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            mb: -10,
          }}
        />

        <Grid
          item
          xxs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          sx={{ mb: -10 }}
          backgroundColor="#f9f9f9"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              mt: 10,
              pl: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: { xxs: 20 },
                mb: { xxs: 20 },
                mr: { xxs: 3, sm: 0, md: 5 },
                ml: { xxs: 1, sm: 1, md: 3 },
              }}
            >
              <Typography variant="h2" sx={{ fontSize: "40px" }}>
                We'd love to hear from you...
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Box
            sx={{
              mt: 7,
              mb: 7,
              ml: { xxs: 5, sm: 6, md: 8 },
              alignItems: "left",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h7" sx={{ mr: 3 }}>
              Phone (Australia)
            </Typography>

            <Typography sx={{ mt: 1, mr: 3, pb: 3 }} variant="body2">
              +61 2 8806 1309
            </Typography>

            <Typography variant="h7" sx={{ mr: 3 }}>
              Email (Direct)
            </Typography>

            <Typography sx={{ mt: 1, mr: 3 }} variant="body2">
              <a href="mailto:enquiries@aussierentreviews.com.au">
                enquiries@aussierentreviews.com.au
              </a>
            </Typography>
          </Box>

          <Divider sx={{ mb: 7 }} />

          <Box sx={{ mb: 3, ml: { xxs: 5, sm: 6, md: 8 }, mr: { xxs: 2 } }}>
            <Typography variant="h7">Or contact us here</Typography>

            <form
              autoComplete="off"
              noValidate
              className="centerContactForm"
              onSubmit={handleSubmit}
            >
              <Box>
                <TextField
                  style={{ background: "white" }}
                  label="Your Name"
                  className={classes.root}
                  width="600px"
                  variant="outlined"
                  color="secondary"
                  margin="normal"
                  value={formData.name}
                  onChange={(e) =>
                    setForm({ ...formData, name: e.target.value })
                  }
                  InputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                />
              </Box>
              <Box>
                <TextField
                  style={{ background: "white" }}
                  label="Your Email"
                  className={classes.root}
                  variant="outlined"
                  color="secondary"
                  margin="normal"
                  fullWidth
                  value={formData.email}
                  onChange={(e) =>
                    setForm({ ...formData, email: e.target.value })
                  }
                  InputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                />
              </Box>
              <Box>
                <TextField
                  style={{ background: "white" }}
                  label="Enquiry"
                  className={classes.root}
                  variant="outlined"
                  color="secondary"
                  margin="normal"
                  fullWidth
                  value={formData.enquiry}
                  onChange={(e) =>
                    setForm({ ...formData, enquiry: e.target.value })
                  }
                  multiline
                  rows={12}
                  InputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                />
              </Box>
              <Box mt={1} mb={2}>
                <Button
                  className="Button Submit"
                  variant="contained"
                  size="small"
                  type="submit"
                  style={{
                    borderRadius: "2px",
                    backgroundColor: "#333333", //"#FF7F50",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  sx={{ color: "#ffffff" }}
                >
                  Submit
                </Button>
              </Box>
            </form>

            {isSubmit ? (
              <Box mt={1} mb={15}>
                <Typography variant="body1">
                  Message sent. We will be in touch soon!
                </Typography>
              </Box>
            ) : (
              <Box mt={1} mb={17.5}></Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <StickyFooter />
    </>
  );
};

export default ContactUs;
