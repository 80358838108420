import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import Navbar from "../../components/Navbar/Navbar";
import { updateBuyOrder } from "../../actions/users";
import useStyles from "./styles";

const EditBuyOrderForm = () => {

const navigate = useNavigate(); 
        
  const classes = useStyles();

  const location = useLocation();
  const orderId = location.state.orderId;

  const order = useSelector((state) => (orderId? state.orders.ordersBuyer.find((message) => message._id === orderId) : null));
  console.log(order)



  const [orderData, setOrderData] = useState({ buyerMobile: order.buyerMobile });

  
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);


  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(orderData));
    setIsSubmit(true);
  };



  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(updateBuyOrder(orderId, { ...orderData }));
      navigate("/user/dashboard");
    } else {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const validate = (values) => {
    const errors = {};

    if (!values.buyerMobile) {
      errors.buyerMobile = "Mobile number is required!";
    } else if (values.buyerMobile.substring(0, 1) === "0") {
      if (values.buyerMobile.length !== 10) {
        errors.buyerMobile = "This is not a valid mobile number!";
      }
    } else if (values.buyerMobile.substring(0, 2) === "61") {
      if (values.buyerMobile.length !== 11) {
        errors.buyerMobile = "This is not a valid mobile number!";
      }
    } else if (values.buyerMobile.substring(0, 2) === "+61") {
      if (values.buyerMobile.length !== 12) {
        errors.buyerMobile = "This is not a valid mobile number!";
      }
    } else {
      errors.buyerMobile = "This is not a valid mobile number!";
    }

    return errors;
  };

  return (
    <>
      <Navbar />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "left",
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xxs={1}></Grid>

            <Grid item xxs={10}>
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box sx={{ mt: 20 }}>
                  <Typography
                    variant="h4" 
                    style={{ fontSize: 24, fontWeight:400 }}
                                        gutterBottom
                                      >
                    Edit your Order Phone Number
              
                  </Typography>
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="h5"
                    color="light-grey"
                    gutterBottom
                  >
                    {/* {reviewData.reviewAddress} */}
                  </Typography>
                </Box>

                <Box sx={{ mt: 5, mb: 2 }}>
                  <Box sx={{ mb: -1 }}>
                    <Typography variant="h5" gutterBottom>
                      Mobile Number
                    </Typography>
                  </Box>

                  <TextField
                    style={{ background: "white" }}
                    label="Enter a valid phone number to receive calls"
                    className={classes.root}
                    variant="outlined"
                    color="secondary"
                    margin="normal"
                    fullWidth
                    value={orderData.buyerMobile}
                    onChange={(e) =>
                      setOrderData({
                        ...orderData,
                        buyerMobile: e.target.value,
                      })
                    }
                    InputProps={{
                      style: { fontSize: 16 },
                    }}
                    sx={{
                      width: { xxs: "100%", sm: 400 },
                    }}
                  />
                  <Typography variant="body2" color="red" >
                    {formErrors.buyerMobile}
                  </Typography>
                </Box>

                <Box sx={{ mt: { xxs: 12, lg: 2 } }}>
                      <Button
                        className="Button Submit"
                        variant="contained"
                        style={{ backgroundColor: "#484848 " }}
                        size="large"
                        type="submit"
                        sx={{ mt: { xxs: -20, sm: -20, md: -20, lg: 0 } }}
                      >
                        Submit
                      </Button>
                  </Box>

              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default EditBuyOrderForm;
