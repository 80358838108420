import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';


const Input = ({ name, handleChange, label, half, autoFocus, type, handleShowPassword,value }) => (

    <TextField
      name={name}
      sx={{
        "& .MuiFormLabel-root ": {
          "font-size": "16px !important",
        },
      }}

      value = {value}
      onChange={handleChange}
      variant="outlined"
      margin="normal"
      required
      fullWidth
      label={label}
      autoFocus={autoFocus}
      type={type}
      InputProps={name === 'password' ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword}>
              {type === 'password' }
              {/* {type === 'password' ? <Visibility /> : <VisibilityOff />} */}
            </IconButton>
          </InputAdornment>
        ),
        style: { fontSize: 16 },
      } : {style: { fontSize: 16 }}}



    />

);

export default Input;
