import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  logo: {
    maxWidth: 80,
  },


  textField: {

    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    backgroundColor: "#FFFFFF",
    fontSize:16,
    

    color: "black",

    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#fda94f",
        borderWidth: 0.1,
      },
    },
  },

  [theme.breakpoints.up("xxs")]: {
    textFieldStyle: {
      width: 150,
      borderRadius: "0px",
    },
  },

  [theme.breakpoints.up("xxs1")]: {
    textFieldStyle: {
      width: 200,
      borderRadius: "0px",
    },
  },



  [theme.breakpoints.up("xs")]: {
    textFieldStyle: {
      width: 180,
      borderRadius: "0px",
    },
  },


  [theme.breakpoints.up("xs1")]: {
    textFieldStyle: {
      width: 212,
      borderRadius: "0px",
    },
  },

  [theme.breakpoints.up("xs2")]: {
    textFieldStyle: {
      width: 244,
      borderRadius: "0px",
    },
  },

  [theme.breakpoints.up("xs3")]: {
    textFieldStyle: {
      width: 272,
      borderRadius: "0px",
    },
  },


  [theme.breakpoints.up("xs4")]: {
    textFieldStyle: {
      width: 303,
      borderRadius: "0px",
    },
  },

  [theme.breakpoints.up("xs5")]: {
    textFieldStyle: {
      width: 333,
      borderRadius: "0px",
    },
  },


  [theme.breakpoints.up("xs6")]: {
    textFieldStyle: {
      width: 364,
      borderRadius: "0px",
    },
  },

  [theme.breakpoints.up("xs7")]: {
    textFieldStyle: {
      width: 394,
      borderRadius: "0px",
    },
  },

  [theme.breakpoints.up("xs8")]: {
    textFieldStyle: {
      width: 424,
      borderRadius: "0px",
    },
  },


  [theme.breakpoints.up("xl")]: {
    textFieldStyle: {
      width: 600,
      borderRadius: "0px",
    },
  },


}));
