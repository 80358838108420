import * as actionType from '../constants/actionTypes.mjs';

const orderReducer = (state = { isLoading: true, ordersReviewer: [], ordersBuyer: [], order: [] }, action) => {

    switch (action.type) {

        case actionType.FETCH_ORDERS_BY_BUYERID:
                return { ...state, ordersBuyer: action.payload.data };

        case actionType.FETCH_ORDERS_BY_REVIEWERID:
            return { ...state, ordersReviewer: action.payload.data };

        case actionType.UPDATE_ORDER:
            // return { ...state, order: action.payload.data };
            return { ...state, ordersBuyer: state.ordersBuyer.map((order) => (order._id === action.payload._id ? action.payload : order)) };


        default:
            return state;

    }
}

export default orderReducer