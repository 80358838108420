import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import NavbarHomePg from "../../components/Navbar/NavbarHomePg";
import StickyFooter from "../../components/Footer/Footer";
import { signin, signup } from "../../actions/users";

import Input from "./Input";
import useStyles from "./styles";

const initialState = { firstName: "", lastName: "", email: "", password: "", confirmPassword: "", };

const SignUp = () => {



  const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [switchAttempt, setSwitchAttempt] = useState(false);
  const [signInErrorMessage, setSignInErrorMessage] = useState('');
  const [emailExistErrorMessage, setEmailExistErrorMessage] = useState('');
  const [generalErrorMessage, setGeneralErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useNavigate();
  const classes = useStyles();

  const errorMessage = useSelector((state) => state.auth.message);

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  
  const switchMode = () => {
    setSwitchAttempt(true)
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (isSignup) {
      setFormErrors(validate(form));
      setIsSubmit(true);
    } else {
        dispatch(signin(form, history))
    }
  };

  useEffect(() => {
    if (isSignup) {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        dispatch(signup(form, history));
        history("/user/activation");
      }
    }
  }, [formErrors]);




  useEffect(() => {

    if (isSignup) { 
      setSignInErrorMessage('')

      if (errorMessage === 'Something went wrong with the signup form') {
        setEmailExistErrorMessage('')
        setGeneralErrorMessage(errorMessage)
      } else {
        setGeneralErrorMessage('')
        setEmailExistErrorMessage(errorMessage)
      }
      
    }
    else {
      setSignInErrorMessage(errorMessage)
      setEmailExistErrorMessage('')
      setGeneralErrorMessage('')
    }

  }, [errorMessage]);

  const validate = (values) => {

    const errors = {};

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;


    if (!values.firstName) {
      errors.firstName = "Name is required!";
    } 

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    if (!values.password) {
      errors.password = "Password is required!";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Password 2 is required!";
    }

    if (values.password && values.confirmPassword) {
      if (values.password.length < 6 || values.password.length > 18) {
        errors.password = "Password needs to be between 6 to 18 characters!";
      }
      if (values.password != values.confirmPassword) {
        errors.password = "Passwords do not match!";
      }
    }
    return errors;
  };


  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });


    useEffect(() => {
      setSwitchAttempt(false)
      setSignInErrorMessage('')
      setEmailExistErrorMessage('')
      setGeneralErrorMessage('')
  }, [switchAttempt]);


  return (

    <>
        <div>
          <Helmet>
            <title>Log In - Aussie Rent Reviews</title>
            <meta name="description" content="Log in or sign up to access your Aussie Rent Reviews account."/>
          </Helmet>
        </div>
      <NavbarHomePg />
      <Grid container component="main" sx={{ height: "100vh" }}>

        <Grid
          item
          xxs={false}
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(/apartment.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xxs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 15,
            }}
          >

            <a href="/">
                <img
                  src="/logoimgc.png"
                  alt="logo"
                  className={classes.logo}
                />
              </a>

            <Typography  variant="h5" mt={1}>
              {isSignup ? "Sign up" : "Sign in"}
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >

              <Input
              autoFocus
                name="email"
                label="Email Address"
                value = {form.email}
                handleChange={handleChange}
                type="email"
                
              />

              <Typography variant="body2" color="red" >
                  {formErrors.email}

                  {emailExistErrorMessage}
              </Typography>
              {isSignup && (
                <>


                  <Input
                    name="firstName"
                    value = {form.firstName}
                    label="First Name"
                    handleChange={handleChange}
                    
                    half
                  />
                  <Typography variant="body2" color="red" >
                      {formErrors.firstName}
                  </Typography>

                  <Input
                    name="lastName"
                    value = {form.lastName}
                    label="Last Name"
                    handleChange={handleChange}
                    half
                  />
                </>
              )}


              <Input
                name="password"
                label="Password"
                value = {form.password}
                handleChange={handleChange}
                type={showPassword ? "text" : "password"}
                handleShowPassword={handleShowPassword}
              />
              <Typography variant="body2" color="red" >
                  {formErrors.password}
                  {signInErrorMessage}
              </Typography>

              {isSignup && (
                <>
                <Input
                  name="confirmPassword"
                  value = {form.confirmPassword}
                  label="Repeat Password"
                  handleChange={handleChange}
                  type="password"
                />

                <Typography variant="body2" color="red" sx={{mb:1}} >
                {formErrors.confirmPassword}
                {generalErrorMessage}

                </Typography>
                </>
              )}



              <Box sx={{ mb: 1 }}>
              
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  style={{ backgroundColor: "#484848" }}
                >
                  {isSignup ? "Sign Up" : "Sign In"}
                </Button>
              </Box>

              <Grid container>
                <Grid item >
                  <Button style= {{ fontSize: 12 ,fontFamily: 'Poppins', fontWeight:'400' }} component={Link} to="/user/forgot-password" variant="body2">
                    Forgot Password?
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={switchMode} variant="body2" style= {{ fontSize: 12 ,fontFamily: 'Poppins', fontWeight:'400' }}>
                    {isSignup
                      ? "Already have an account? Sign in"
                      : "Don't have an account? Sign Up"}
                  </Button>
                </Grid>
                
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <StickyFooter />
     
    </>
  );
};

export default SignUp;
