import React, { useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Navbar from "../../components/Navbar/Navbar";

const PasswordResetSuccess = () => {

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/auth')
        }, 10000)
        }, [])
   
  return (
    <>
      <Navbar />

      <Grid container xxs={12}>
        <Grid item xxs={12} sm={12}>
          <Container>
            <Grid container spacing={3} alignItems="center" textAlign="center" display="flex"  justifyContent="center">

                  <Box sx={{ml:2,mr:1}}>
                    <Typography varient='body2' sx={{mt:30,mb:20}}>
                        
                        Your password has been reset. Please log in!
                    
                    </Typography>

                  </Box>

            </Grid>
          </Container>
        </Grid>

      </Grid>
 
    </>
  );
};

export default PasswordResetSuccess;
