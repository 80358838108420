import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({


    [theme.breakpoints.up("xxs")]: {
        root: {
            width:"100%",
            marginBottom:20,

        },
        imgCarousel: {
            width:"100%",
            height: 150,

        },

    },

    [theme.breakpoints.up("xs")]: {

        imgCarousel: {
            width:"100%",
            height: 200,

        },

    },

    
    [theme.breakpoints.up("xs4")]: {

        imgCarousel: {
            width:"100%",
            height: 300,

        },

    },


    [theme.breakpoints.up("sm")]: {
        root: {
            maxWidth:580,
            minWidth:450,
            marginBottom:20,
            // minHeight:408,
        },

        imgCarousel: {
            width:"100%",
            height: 300,

        },
    },

    [theme.breakpoints.up("md")]: {
        root: {
            maxWidth:580,
            minWidth:450,
            marginBottom:20,
            // maxHeight:200,
       
        },
    },



    }));