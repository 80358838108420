import React from 'react';
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { reducers } from './reducers';
import App from './App';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';  
import 'bootstrap/dist/js/bootstrap.js';
//import 'bootstrap/dist/css/bootstrap.css';  

const store = createStore(reducers, {}, compose(applyMiddleware(thunk)));

// React 17 version deprecated
ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );

// react 18 version
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//     <Provider store={store}>
//       <App />
//     </Provider>,
// );

  
  