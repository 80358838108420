import React from "react";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

const MarketplaceTerms = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Marketplace Terms and Conditions - Aussie Rent Reviews</title>
        </Helmet>
      </div>
      <Navbar />

      <Grid container xxs={12} sx={{ minHeight: "70vh" }}>
        <Grid item xxs={12} sm={12}>
          <Container>
            <Grid container spacing={3} alignItems="center">
              <Box sx={{ ml: 4, mr: 1, mt: 15 }}>
                <p>
                  <strong>WHAT PARTS OF THESE TERMS APPLY TO ME? </strong>
                </p>
                <p>
                  This agreement governs your use of the property review website
                  located at www.aussierentreviews.com.au (
                  <strong>Platform</strong>) and any other services made
                  available through the Platform. By using the Platform, you
                  agree to be bound by this agreement which forms a binding
                  contractual agreement between you, the User, and us, Aussie
                  Rent Reviews Pty Ltd ACN 664109022 (
                  <strong>Aussie Rent Reviews</strong>, <strong>we, us</strong>{" "}
                  or <strong>our</strong>).
                </p>
                <p>
                  The remainder of this agreement is divided into three parts:
                </p>
                <p>
                  ● Part A (<strong>All Users</strong>), which sets out terms
                  that apply to all Users;
                </p>
                <p>
                  ● Part B (<strong>Reviewers</strong>), which sets out
                  additional terms that apply to <strong>Reviewers</strong>,
                  being former tenants of a particular property who provide a
                  reviews of the property on the Platform in exchange for fees;
                  and
                </p>
                <p>
                  ● Part C (<strong>Buyers</strong>), which sets out additional
                  terms that apply to <strong>Buyers</strong>, being people who
                  which to purchase a review of a specific property from a
                  Reviewer or a recording of an existing review linked to a
                  specific property.
                </p>
                <p>
                  If you intend to use the Platform as a Reviewer, only Parts A
                  and B of these terms will apply to you.
                </p>
                <p>
                  If you intend to use the Platform as a Buyer, only Parts A and
                  C of these terms will apply to you.
                </p>
                <p>
                  When we talk about the “<strong>Services</strong>” in this
                  agreement, we are referring to the services of connecting
                  people seeking property reviews to former tenants of
                  properties providing reviews available through our website and
                  any associated services we offer.
                </p>
                <p>
                  When we talk about the “<strong>Property</strong>” in this
                  agreement, we are referring to properties for which a Reviewer
                  is able to provide a Rent Review.
                </p>
                <p>
                  When we talk about the “<strong>Rent Reviews</strong>” in this
                  agreement, we are referring to the audio or video link
                  conversation in which the Reviewer will provide a review of a
                  specific Property that a Buyer can purchase from a Reviewer.
                </p>
                <p>
                  When we talk about the “<strong>Recordings</strong>” in this
                  agreement, we are talking about a recording of an audio or
                  video Rent Review that the Platform makes at the time a Rent
                  Review is provided and that is available to be purchased
                  through the Platform. Please see section 20 of Part B for more
                  information.
                </p>
                <p>
                  We may use Google Maps/Earth mapping services, including
                  Google Maps API(s). Your use of Google Maps/Earth is subject
                  to the Google Maps/Google Earth Additional Terms of Service.
                </p>
                <br clear="all" />
                <p>
                  <a name="_Ref124848741"></a>
                  <a name="_heading=h.gjdgxs"></a>
                  <strong>Part A </strong>
                  <strong>All Users</strong>
                </p>
                <p>
                  <a name="_heading=h.30j0zll"></a>1{" "}
                  <strong>ELIGIBILITY</strong>
                </p>
                <p>
                  (a) This Platform is not intended for unsupervised use by any
                  person under the age of 18 years old or any person who has
                  previously been suspended or prohibited from using the
                  Platform. By using the Platform, you represent and warrant
                  that you are either:
                </p>
                <p>
                  (i) over the age of 18 years and accessing the Platform for
                  personal use; or
                </p>
                <p>
                  (ii) accessing the Platform on behalf of someone under the age
                  of 18 years old and consent to that person’s use of the
                  Platform.
                </p>
                <p>
                  (b) Please do not access the Platform if you are under the age
                  of 18 years old and do not have your parent or guardian’s
                  consent, or if you have previously been suspended or
                  prohibited from using the Platform.
                </p>
                <p>
                  (c) If you use the Platform on behalf of a company or
                  organisation you warrant that you have the necessary authority
                  from that company or organisation to do so. If you are signing
                  up not as an individual but on behalf of your company, your
                  employer, an organisation, government or other legal entity (
                  <strong>Represented Entity</strong>), then “you” or “User”
                  means the Represented Entity and you are binding the
                  Represented Entity to this agreement. If you are accepting
                  this agreement and using our Services on behalf of a
                  Represented Entity, you represent and warrant that you are
                  authorised to do so.
                </p>
                <p>
                  <a name="_heading=h.1fob9te"></a>2 <strong>ACCOUNTS</strong>
                </p>
                <p>
                  (a) In order to use most of the functionality of the Platform,
                  all Users are required to sign-up, register and receive an
                  account through the Platform (an <strong>Account</strong>).
                </p>
                <p>
                  (b) As part of the Account registration process and as part of
                  your continued use of the Platform, you are required to
                  provide personal information and details, such as your email
                  address, first and last name, preferred username, a secure
                  password, billing, postal and physical addresses, copies of
                  tenancy contract(s) related to Properties, mobile phone
                  number, and other information as determined by Aussie Rent
                  Reviews from time to time.
                </p>
                <p>
                  (c) You warrant that any information you give to Aussie Rent
                  Reviews in the course of completing the Account registration
                  process will always be accurate, honest, correct and up to
                  date.
                </p>
                <p>
                  (d) Correspondence between Users must take place on the
                  Platform. You agree to ensure that your Account does not
                  display any of your personal contact information at any time
                  such that it can be viewed by any other User. You agree to not
                  give your contact details to any other User.
                </p>
                <p>
                  (e) Once you complete the Account registration process, Aussie
                  Rent Reviews may, in its absolute discretion, choose to accept
                  you as a registered user within the Platform and provide you
                  with an Account.
                </p>
                <p>
                  (f) Aussie Rent Reviews reserves the right to contact you
                  about any concerning behaviour by you, or to seek a resolution
                  with you.
                </p>
                <p>
                  (g) Aussie Rent Reviews may, in its absolute discretion,
                  suspend or cancel your Account for any reason, including for
                  any failure to comply with this agreement.
                </p>
                <p>
                  <a name="_heading=h.3znysh7"></a>3{" "}
                  <strong>USER OBLIGATIONS</strong>
                </p>
                <p>As a User, you agree:</p>
                <p>
                  (a) not to intimidate, harass, impersonate, stalk, threaten,
                  bully or endanger any other User or distribute unsolicited
                  commercial content, junk mail, spam, bulk content or
                  harassment;
                </p>
                <p>
                  (b) to not share your Account with any other person and that
                  any use of your Account by any other person is strictly
                  prohibited. You must immediately notify Aussie Rent Reviews of
                  any unauthorised use of your Account, password or email, or
                  any other breach or potential breach of the Platform’s
                  security;
                </p>
                <p>
                  (c) to not use the Platform for any purpose other than for the
                  purpose of making arrangements to provide or receive services,
                  including by not using the Platform:
                </p>
                <p>
                  (i) in a manner that is illegal or fraudulent or facilitates
                  illegal or fraudulent activity (including requesting or
                  accepting a job which includes illegal activities or
                  purposes); and
                </p>
                <p>
                  (ii) in connection with any commercial or money making or
                  other promotional or marketing endeavours except those that
                  are endorsed herein, or as approved in writing by Aussie Rent
                  Reviews;
                </p>
                <p>
                  (d) not to act in any way that may harm the reputation of
                  Aussie Rent Reviews or associated or interested parties or do
                  anything at all contrary to the interests of Aussie Rent
                  Reviews or the Platform;
                </p>
                <p>
                  (e) not to make any automated use of the Platform and you must
                  not copy, reproduce, translate, adapt, vary or modify the
                  Platform without the express written consent of Aussie Rent
                  Reviews;
                </p>
                <p>
                  (f) that Aussie Rent Reviews may change any features of the
                  Platform or Services offered through the Platform at any time
                  without notice to you;
                </p>
                <p>
                  (g) that information given to you through the Platform, by
                  Aussie Rent Reviews or another User including a Reviewer, is
                  general in nature and we take no responsibility for anything
                  caused by any actions you take in reliance on that
                  information; and
                </p>
                <p>
                  (h) that Aussie Rent Reviews may cancel your account at any
                  time if it considers, in its absolute discretion, that you are
                  in breach or are likely to breach this clause 3.
                </p>
                <p>
                  <a name="_Ref126862326"></a>
                  <a name="_heading=h.2et92p0"></a>
                  <strong>4 </strong>
                  <strong>
                    CONFERENCE CALLS, CALL CREDITS, CALL WINDOW AND REFUNDS
                  </strong>
                  <strong></strong>
                </p>
                <p>Each User acknowledges and agrees that:</p>
                <p>
                  (a) a listed Rent Review purchased by a Buyer and accepted by
                  a Reviewer entitles the Buyer to make five (5) call attempts
                  to the Review via the Platform to receive the Rent Review (
                  <strong>Call Credits</strong>) in the time period ending at
                  5pm, two (2) days following the day a Buyer purchases and the
                  Reviewer accepts a Rent Review (<strong>Call Window</strong>);
                </p>
                <p>
                  (b) the Buyer’s right to contact the Reviewer, and the
                  Reviewer’s obligation to provide a Review to the Buyer ends at
                  the expiry of the Call Window;
                </p>
                <p>
                  (c) the Reviewer is not obligated to answer any call attempt
                  from the Buyer, but must do so to complete the Rent Review and
                  receive the associated payment;
                </p>
                <p>
                  (d) when a call attempt from the Buyer to the Reviewer results
                  in a connection, a conference call (
                  <strong>Conference Call</strong>) shall be deemed to have
                  begun;
                </p>
                <p>
                  <a name="_Ref126856061">
                    (e) each call attempt must be made by the Buyer;
                  </a>
                </p>
                <p>
                  (i) between 9am and 5pm in the time zone in which the Review
                  is located; and
                </p>
                <p>(ii) before the expiry of the Call Window;</p>
                <p>
                  (f) a Conference Call relating to a specific Rent Review must
                  be:
                </p>
                <p>(i) up to 20 minutes in length;</p>
                <p>(ii) conducted in a question and answer format;</p>
                <p>
                  (iii) initiated and/or accepted by either party with the
                  intent to commit to a full 20 minutes for completion.
                </p>
                <p>
                  (g) A Conference Call relating to a specific Rent Review shall
                  be deemed completed, and entitle the Reviewer to payment, if
                  it runs for 20 minutes or is completed before the expiry of 20
                  minutes, at the Buyer’s discretion.
                </p>
                <p>(h) The Call Credits will expire:</p>
                <p>
                  (i) at the completion of a Conference Call (in which case the
                  Reviewer shall be entitled to payment in respect of the
                  relevant Rent Review); or
                </p>
                <p>
                  (ii) following 5 unsuccessful call attempts (in which case the
                  Buyer will be entitled to a refund and the Reviewer shall not
                  be entitled to payment).
                </p>
                <p>
                  (i) Refunds will be processed less any payment processing fees
                  which are non-refundable. Payment processing fees are portion
                  of the service fee.
                </p>
                <p>
                  (j) If the Users experience a connection failure during a
                  Conference Call, the Buyer will be entitled to the balance of
                  time that would make the total duration of the Conference Call
                  20 minutes in length, which the Buyer can redeem at any time
                  by re-attempting the Conference Call in accordance with this
                  clause 4.
                </p>
                <p>
                  (k) In the event of multiple Conference Call disconnections,
                  Aussie Rent Reviews may, in its absolute discretion, decide
                  whether the Call Credits relating to the specific Rent Review
                  have expired and, whether the Buyer is entitled to a refund or
                  the Reviewer to a payment in relation to the specific Rent
                  Review.
                </p>
                <p>
                  5 <strong>POSTED MATERIALS</strong>
                </p>
                <p>5.1 WARRANTIES</p>
                <p>
                  By providing or posting any information, materials or other
                  content on the Platform, including in any Rent Review, (
                  <strong>Posted Material</strong>), you represent and warrant
                  that:
                </p>
                <p>
                  (a) you are authorised to provide the Posted Material
                  (including by being authorised to provide any services that
                  you represent you provide);
                </p>
                <p>
                  (b) the Posted Material is accurate and true at the time it is
                  provided;
                </p>
                <p>
                  (c) any Posted Material which is in the form of a review or
                  feedback is honest, accurate and presents a fair view of the
                  relevant person and/or your experience;
                </p>
                <p>
                  (d) the Posted Material is free from any harmful,
                  discriminatory, defamatory or maliciously false implications
                  and does not contain any offensive or explicit material;
                </p>
                <p>
                  (e) the Posted Material is not “passing off” of any product or
                  service and does not constitute unfair competition;
                </p>
                <p>
                  (f) the Posted Material does not infringe any Intellectual
                  Property Rights, including copyright, trademarks, business
                  names, patents, confidential information or any other similar
                  proprietary rights, whether registered or unregistered,
                  anywhere in the world;
                </p>
                <p>
                  (g) the Posted Material does not contain any viruses or other
                  harmful code, or otherwise compromise the security or
                  integrity of the Platform or any network or system; and
                </p>
                <p>
                  (h) the Posted Material does not breach or infringe any
                  applicable laws.
                </p>
                <p>5.2 LICENCE</p>
                <p>
                  (a) You grant to Aussie Rent Reviews a perpetual, irrevocable,
                  transferable, worldwide and royalty-free licence (including
                  the right to sublicense) to use, copy, modify, reproduce and
                  adapt any Intellectual Property Rights in any Posted Material
                  in order for Aussie Rent Reviews to use, exploit or otherwise
                  enjoy the benefit of such Posted Material.
                </p>
                <p>
                  (b) If it is determined that you retain moral rights
                  (including rights of attribution or integrity) in any Posted
                  Material, you forever release Aussie Rent Reviews from any and
                  all claims that you could assert against Aussie Rent Reviews
                  by virtue of any such moral rights.
                </p>
                <p>
                  (c) You indemnify Aussie Rent Reviews against all damages,
                  losses, costs and expenses incurred by Aussie Rent Reviews
                  arising out of any third party claim that your Posted Material
                  infringes any third party’s Intellectual Property Rights.
                </p>
                <p>5.3 REMOVAL</p>
                <p>
                  (a) Aussie Rent Reviews acts as a passive conduit for the
                  online distribution of Posted Material and has no obligation
                  to screen Posted Material in advance of it being posted.
                  However, Aussie Rent Reviews may, in its absolute discretion,
                  review and remove any Posted Material (including links to you,
                  your profile or listings you have posted on the Platform) at
                  any time without giving any explanation or justification for
                  removing the Posted Material.
                </p>
                <p>
                  (b) You agree that you are responsible for keeping and
                  maintaining records of Posted Material.
                </p>
                <p>
                  <a name="_heading=h.tyjcwt"></a>6{" "}
                  <strong>
                    REFUNDS, SERVICE INTERRUPTIONS AND CANCELLATIONS
                  </strong>
                </p>
                <p>
                  Aussie Rent Reviews will have no liability or obligation to
                  you if:
                </p>
                <p>
                  (a) a Buyer or Reviewer cancels at any time after the time for
                  performance of the Rent Review is agreed; or
                </p>
                <p>
                  (b) for whatever reason, including technical faults, the
                  services in a Rent Review cannot be performed or completed,
                </p>
                <p>
                  and you will not be entitled to any compensation from Aussie
                  Rent Reviews.
                </p>
                <p>
                  <a name="_heading=h.3dy6vkm"></a>
                  <strong>7 </strong>
                  <strong> IDENTITY VERIFICATION</strong>
                </p>
                <p>
                  (a) (<strong>Verification</strong>) We may offer or require
                  Users to verify their details (including their name and places
                  of their former residence) using our processes or an external
                  identity verification service as applicable (
                  <strong>Verification Service</strong>).
                </p>
                <p>
                  (b) (<strong>Your personal information and privacy</strong>)
                  We will collect your personal information in accordance with
                  our Privacy Policy as set out in clause 16. Where a
                  Verification Service is used, you acknowledge and agree that:
                </p>
                <p>
                  (i) we may contact and share your personal information with a
                  Verification Service to verify your details;
                </p>
                <p>
                  (ii) you consent to us receiving, sharing and using this
                  information to enable us to carry out the Verification
                  Service.
                </p>
                <p>
                  (c) (<strong>Fees</strong>) We may charge non-refundable fees
                  for the Verification Service, as set out on the Platform.
                </p>
                <p>
                  (d) (<strong>Warranty and Indemnity</strong>) You acknowledge
                  and agree that:
                </p>
                <p>
                  (i) we are reliant on the information provided by the
                  Verification Service to verify your identity and to the extent
                  permitted by law, we disclaim all warranties that the
                  Verification Service will be accurate or guarantee that the
                  Verification Service will ensure you contract with a suitable
                  User; and
                </p>
                <p>
                  (ii) we do not endorse any User, Rent Review or Verification
                  Service.
                </p>
                <p>
                  <a name="_heading=h.1t3h5sf"></a>8{" "}
                  <strong>ONLINE PAYMENT PARTNER</strong>
                </p>
                <p>
                  (a) We may use third-party online payment partner, currently
                  Stripe ( <strong>Online Payment Partner</strong>) to collect
                  payments on the Platform, including for our Services.
                </p>
                <p>
                  (b) The processing of payments by the Online Payment Partner
                  will be, in addition to this agreement, subject to the terms,
                  conditions and privacy policies of the Online Payment Partner.
                </p>
                <p>
                  (c) You agree to release Aussie Rent Reviews and its employees
                  and agents in respect of all liability for loss, damage or
                  injury which may be suffered by any person arising from any
                  act or omission of the Online Payment Partner, including any
                  issue with security or performance of the Online Payment
                  Partner’s platform or any error or mistake in processing your
                  payment.
                </p>
                <p>
                  (d) We reserve the right to correct, or to instruct our Online
                  Payment Partner to correct, any errors or mistakes in
                  collecting your payment.
                </p>
                <p>
                  <a name="_Ref124848736">
                    <strong>9 </strong>
                    <strong>RATINGS AND FEEDBACK</strong>
                  </a>
                  <strong> </strong>
                </p>
                <p>
                  (a) Buyers may rate a Reviewer, a Rent Review or a Recording,
                  and Reviewers may rate a Buyer (each a ‘
                  <strong>Rating’</strong>) and Users may provide feedback to
                  other Users regarding the relevant services or experience with
                  that User (<strong>Feedback</strong>).
                </p>
                <p>
                  (b) Ratings and Feedback may be able to be viewed by other
                  Users and these may remain viewable until the relevant Account
                  and/or Rent Review is removed or terminated.
                </p>
                <p>
                  (c) Users must provide true, fair and accurate information in
                  their Feedbacks.
                </p>
                <p>
                  (d) If we consider that a Feedback is untrue, unfair,
                  inaccurate, offensive or inappropriate, we may delete the
                  Feedback or ban the relevant User from posting the Feedback,
                  or future Feedback. We do not undertake to review each
                  Feedback made by a User.
                </p>
                <p>
                  (e) If you consider that a Feedback is untrue, unfair,
                  inaccurate, offensive or inappropriate, please follow our
                  dispute resolution procedure set out in clause 14.
                </p>
                <p>
                  (f) To the maximum extent permitted by law, we are not
                  responsible for the content of any Feedbacks.
                </p>
                <p>
                  (g) You must not publish Feedback for Users to which you have
                  personal or professional relations.
                </p>
                <p>
                  (h) Users can only write a Feedback about another User if they
                  have had a buying or selling experience with that User, which
                  means that:
                </p>
                <p>(i) they have purchased a service from that Reviewer;</p>
                <p>(ii) they have sold a service to that Buyer; or</p>
                <p>
                  (iii) you can otherwise document that you had a buying or
                  selling experience with that User, including via
                  correspondence or other interaction with the User,
                </p>
                <p>
                  (collectively referred to as a{" "}
                  <strong>Service Experience</strong>).
                </p>
                <p>
                  (i) You must only write about your own Service Experience. You
                  are not permitted to write a Feedback about somebody else’s
                  Service Experience, such as that of a family member or friend.
                </p>
                <p>
                  (j) If the Reviewer is a business, you must not write a review
                  about a Reviewer you have previously owned, currently own, or
                  which an immediate family member currently owns, or if you are
                  an executive or employee of that Reviewer, or work for the
                  Reviewer. Similarly, you must not write a Feedback about a
                  direct competitor to the Reviewer you own, are employed by or
                  work for.
                </p>
                <p>
                  (k) Your Service Experience must have occurred within the last
                  12 months. This means within 12 months from the date on which
                  you write the Feedback.
                </p>
                <p>
                  (l) You are encouraged to be specific and factual in your
                  Feedbacks. If you have been offered an incentive by a User to
                  write a Feedback, you should include information about this in
                  your Feedback. Incentives include the User offering you a
                  gift, reward, discount or advantage for writing a Feedback
                  about the User on the Platform.
                </p>
                <p>
                  10 <strong>SERVICE LIMITATIONS</strong>
                </p>
                <p>
                  The Platform is made available to you strictly on an ‘as is’
                  basis. Without limitation, you acknowledge and agree that
                  Aussie Rent Reviews cannot and does not represent, warrant or
                  guarantee that:
                </p>
                <p>(a) the Platform will be free from errors or defects;</p>
                <p>(b) the Platform will be accessible at all times;</p>
                <p>
                  (c) messages sent through the Platform will be delivered
                  promptly, or delivered at all;
                </p>
                <p>
                  (d) information you receive or supply through the Platform
                  will be secure or confidential; or
                </p>
                <p>
                  (e) any information provided through the Platform is accurate
                  or true.
                </p>
                <p>
                  <a name="_heading=h.4d34og8"></a>
                  11 <strong>INTELLECTUAL PROPERTY </strong>
                </p>
                <p>
                  <a name="_heading=h.2s8eyo1"></a>
                  (a) Aussie Rent Reviews retains ownership of and provides you
                  with a non-transferable, revocable sublicense to use as
                  permitted by this agreement and only for the duration of your
                  Account all materials developed or provided (or both, as the
                  case may be) in connection with the Services (including any
                  Rent Reviews, Recordings, text, graphics, logos, design,
                  icons, images, sound and video recordings, pricing, downloads
                  and software) (<strong>Service</strong>{" "}
                  <strong>Content</strong>) and reserves all rights in any
                  Intellectual Property Rights owned or licensed by it not
                  expressly granted to you.
                </p>
                <p>
                  (b) You may make a temporary electronic copy of all or part of
                  the Service Content for the sole purpose of viewing it and
                  adapting it for the purpose of making a Rent Review. You must
                  not otherwise reproduce, transmit, adapt, distribute, sell,
                  modify or publish the Service Content without prior written
                  consent from Aussie Rent Reviews or as permitted by law.
                </p>
                <p>
                  <a name="_heading=h.17dp8vu"></a>
                  (c) In this clause 9, “
                  <strong>Intellectual Property Rights</strong>” means all
                  copyright, trade mark, design, patent, semiconductor and
                  circuit layout rights, trade, business, company and domain
                  names, confidential and other proprietary rights, and any
                  other rights to registration of such rights whether created
                  before or after the date of this agreement both in Australia
                  and throughout the world.
                </p>
                <p>
                  <a name="_heading=h.3rdcrjn"></a>
                  12 <strong>THIRD PARTY CONTENT</strong>
                </p>
                <p>
                  <a name="_heading=h.26in1rg"></a>
                  The Platform may contain text, images, data and other content
                  provided by a third party and displayed on the Platform ({" "}
                  <strong>Third Party Content</strong>). Aussie Rent Reviews
                  accepts no responsibility for Third Party Content and makes no
                  representation, warranty or guarantee about the quality,
                  suitability, accuracy, reliability, currency or completeness
                  of Third Party Content.
                </p>
                <p>
                  <a name="_heading=h.lnxbz9"></a>
                  13 <strong>THIRD PARTY TERMS</strong>
                </p>
                <p>
                  (a) Any service that requires Aussie Rent Reviews to acquire
                  goods and services supplied by a third party on behalf of the
                  Buyer (including a third party payment service) may be subject
                  to the terms and conditions of that third party (
                  <strong>Third Party Terms</strong>), including ‘no refund’
                  policies.
                </p>
                <p>
                  (b) Users agree to familiarise themselves with any Third Party
                  Terms applicable to any such goods and services and, by
                  instructing Aussie Rent Reviews to acquire the goods or
                  services on the User’s behalf, the User will be taken to have
                  agreed to such Third Party Terms.
                </p>
                <p>
                  <a name="_Ref124783390"></a>
                  <a name="_Ref126158943"></a>
                  <a name="_heading=h.35nkun2"></a>
                  14 <strong>DISPUTES BETWEEN USERS</strong>
                </p>
                <p>
                  (a) The process set out in this clause 14 applies to any
                  dispute that falls outside the Policy set out in clause 4
                  above.
                </p>
                <p>
                  (b) You should direct any complaint relating to another User
                  to that User. Users must take all reasonable steps to resolve
                  any dispute with another User with that User.
                </p>
                <p>
                  (c) If any issue or problem relating to the Platform remains
                  unresolved after directing a complaint to a relevant User, or
                  if the complaint does not relate to another User, you must
                  report it to Aussie Rent Reviews via the functionality of the
                  Platform. We will assess the complaint and attempt to quickly
                  and satisfactorily resolve it.
                </p>
                <p>
                  (d) Any costs you incur in relation to a complaint or dispute
                  will be your responsibility.
                </p>
                <p>
                  (e) Aussie Rent Reviews has the option to appoint an
                  independent mediator or arbitrator, if needed. The cost of any
                  mediator or arbitrator must be shared equally between each of
                  the parties to the dispute.
                </p>
                <p>
                  (f) Aussie Rent Reviews reserves the right to hold funds in
                  relation to a dispute until the dispute is resolved by the
                  relevant parties or by a mediator or arbitrator.
                </p>
                <p>
                  (g) If you have a dispute with Aussie Rent Reviews, you agree
                  to notify us first and enter into discussion, mediation or
                  arbitration with us for a minimum of a 120-day period before
                  pursuing any other proceedings.
                </p>
                <p>
                  (h) Notwithstanding any other provision of this clause 14, you
                  or Aussie Rent Reviews may at any time cancel your Account or
                  discontinue your use of the Platform.
                </p>
                <p>
                  <a name="_heading=h.1ksv4uv"></a>
                  15 <strong>SECURITY</strong>
                </p>
                <p>
                  <a name="_heading=h.44sinio"></a>
                  Aussie Rent Reviews does not accept responsibility for loss or
                  damage to computer systems, mobile phones or other electronic
                  devices arising in connection with your use of the Platform.
                  You should take your own precautions to ensure that the
                  process you employ to access the Platform does not expose you
                  to the risk of viruses, malicious computer code or other forms
                  of interference.
                </p>
                <p>
                  <a name="_heading=h.2jxsxqh"></a>
                  16 <strong>DISCLAIMER</strong>
                </p>
                <p>
                  (a) (<strong>Introduction service</strong>) Aussie Rent
                  Reviews is a medium that facilitates the introduction of
                  Buyers and Reviewers for the purposes of allowing them to
                  provide or receive Rent Reviews and Recordings (as the case
                  may be). Aussie Rent Reviews simply collects a service fee in
                  consideration for providing this introduction service and does
                  not have any obligations or liabilities to, and is not a party
                  to any contract between, Buyers and Reviewers in relation to
                  such services or otherwise resulting from the introduction.
                </p>
                <p>
                  (b) (<strong>Limitation of liability</strong>) To the maximum
                  extent permitted by applicable law, Aussie Rent Reviews
                  excludes completely all liability to any person for loss or
                  damage of any kind, however arising whether in contract, tort
                  (including negligence), statute, equity, indemnity or
                  otherwise, arising from or relating in any way to the Platform
                  or its use or any services provided by any Reviewer. This
                  includes the transmission of any computer virus.
                </p>
                <p>
                  (c) (<strong>Disclaimer</strong>) All express or implied
                  representations and warranties are, to the maximum extent
                  permitted by applicable law, excluded.
                </p>
                <p>
                  (d) (<strong>Consumer law</strong>) Nothing in this agreement
                  is intended to limit the operation of the Australian Consumer
                  Law contained in the{" "}
                  <em>Competition and Consumer Act 2010</em> (Cth) (
                  <strong>ACL</strong>). Under the ACL, you may be entitled to
                  certain remedies (like a refund, replacement or repair) if
                  there is a failure with the goods or services we provide.
                </p>
                <p>
                  (e) (<strong>Indemnity</strong>) You agree to indemnify Aussie
                  Rent Reviews and its employees and agents in respect of all
                  liability for loss, damage or injury which may be suffered by
                  any person arising from you or your representatives’:
                </p>
                <p>(i) breach of any term of this agreement;</p>
                <p>(ii) use of the Platform; or</p>
                <p>
                  (iii) your provision or receipt of Services from another User.
                </p>
                <p>
                  (f) (<strong>Consequential loss</strong>) To the maximum
                  extent permitted by law, under no circumstances will Aussie
                  Rent Reviews be liable for any incidental, special or
                  consequential loss or damages, or damages for loss of data,
                  business or business opportunity, goodwill, anticipated
                  savings, profits or revenue arising under or in connection
                  with the Platform, this agreement or their subject matter, or
                  any services provided by any Reviewer (except to the extent
                  this liability cannot be excluded under the{" "}
                  <em>Competition and Consumer Act 2010</em> (Cth)).
                </p>
                <p>
                  <a name="_heading=h.z337ya"></a>
                  17 <strong>CONFIDENTIALITY</strong>
                </p>
                <p>You agree that:</p>
                <p>
                  (a) no information owned by Aussie Rent Reviews, including
                  system operations, documents, marketing strategies, staff
                  information and client information, may be disclosed or made
                  available to any third parties; and
                </p>
                <p>
                  (b) all communications involving the details of other users on
                  this Platform and of the Reviewer are confidential, and must
                  be kept as such by you and must not be distributed nor
                  disclosed to any third party.
                </p>
                <p>
                  <a name="_heading=h.3j2qqm3"></a>
                  18 <strong>PRIVACY</strong>
                </p>
                <p>
                  You agree to be bound by the clauses outlined in Aussie Rent
                  Reviews’ Privacy Policy, which can be accessed here
                  www.aussierentreviews.com.au/privacy-policy.
                </p>
                <p>
                  <strong>19 </strong>
                  <strong>COLLECTION NOTICE</strong>
                </p>
                <p>
                  (a) We collect personal information about you in order to
                  enable you to access and use the Platform, to provide and
                  access Rent Reviews and/or Recordings, to contact and
                  communicate with you, to respond to your enquiries and for
                  other purposes set out in our Privacy Policy.
                </p>
                <p>
                  (b) Our Privacy Policy contains more information about how we
                  use, disclose and store your information and details how you
                  can access and correct your personal information.
                </p>
                <p>
                  <a name="_Ref124860925"></a>
                  <a name="_heading=h.1y810tw"></a>
                  <strong>20 </strong>
                  <strong>RECORDINGS</strong>
                  <strong></strong>
                </p>
                <p>
                  (a) You acknowledge and agree that the Platform may record any
                  of the Rent Reviews provided on the Platform and use the
                  Recordings for dispute resolution purposes in accordance with
                  clause 14 above or to make the Recordings available to other
                  Users for a fee.
                </p>
                <p>
                  (b) You expressly give permission for any information you
                  supply in a Rent Review to be converted and used by the
                  Platform as a Recording that can be sold to third parties.
                </p>
                <p>
                  (c) You acknowledge and agree that where a Recording of a Rent
                  Review you provide and/or receive (as the case may be) is
                  subsequently purchased by another User from the Platform you
                  will not be entitled to any fee in connection with such a
                  purchase.
                </p>
                <p>
                  21 <strong>TERMINATION</strong>
                </p>
                <p>
                  (a) Aussie Rent Reviews reserves the right to terminate a
                  User’s access to any or all of the Platform (including any
                  listings, memberships or Accounts) at any time without notice,
                  for any reason.
                </p>
                <p>(b) In the event that a User’s Account is terminated:</p>
                <p>
                  (i) the User’s access to all posting tools on the Platform
                  will be revoked;
                </p>
                <p>
                  (ii) the User will be unable to view the details of all other
                  Users (including contact details, geographic details, any
                  other personal details and Rent Reviews, Recordings or
                  requests); and
                </p>
                <p>
                  (iii) the User may be unable to view the details of other
                  Users (including contact details, geographic details and any
                  other details), and all information previously posted by the
                  respective User will also be removed from the Platform.
                </p>
                <p>
                  (c) Users may terminate their Account, and any other
                  membership they hold in connection with the Platform, at any
                  time by using the Platform’s functionality, where such
                  functionality is available. Where such functionality is not
                  available, Aussie Rent Reviews will effect such termination
                  within a reasonable time after receiving written notice from
                  the User.
                </p>
                <p>
                  (d) Notwithstanding termination or expiry of your Account,
                  this agreement, or any other membership you hold in connection
                  with the Platform, the provisions of Part A and any other
                  provision in this agreement which by its nature would
                  reasonably be expected to be complied with after termination
                  or expiry, will continue to apply.
                </p>
                <p>
                  <a name="_heading=h.4i7ojhp"></a>
                  22 <strong>TAX</strong>
                </p>
                <p>
                  You are responsible for the collection and remission of all
                  taxes associated with the services you provide or receive or
                  any transactions through your use of the Platform, and Aussie
                  Rent Reviews will not be held accountable in relation to any
                  transactions between Buyers and Reviewers where tax related
                  misconduct has occurred.
                </p>
                <p>
                  <a name="_heading=h.2xcytpi"></a>
                  23 <strong>RECORD / AUDIT</strong>
                </p>
                <p>
                  To the extent permitted by law, Aussie Rent Reviews reserves
                  the right to keep all records of any and all transactions and
                  communications made through this Platform between you and
                  other Users (including conversations, user posts, job request
                  bids, comments, feedback, cookies, and I.P. address
                  information) for administration purposes and also holds the
                  right to produce these records in the event of any legal
                  dispute involving Aussie Rent Reviews.
                </p>
                <p>
                  24 <strong>NOTICES</strong>
                </p>
                <p>
                  (a) A notice or other communication to a party under this
                  agreement must be:
                </p>
                <p>(i) in writing and in English; and</p>
                <p>
                  (ii) delivered via email to the other party, to the email
                  address specified in this agreement, or if no email address is
                  specified in this agreement, then the email address most
                  regularly used by the parties to correspond regarding the
                  subject matter of this agreement as at the date of this
                  agreement (<strong>Email Address</strong>). The parties may
                  update their Email Address by notice to the other party.
                </p>
                <p>
                  (b) Unless the party sending the notice knows or reasonably
                  ought to suspect that an email was not delivered to the other
                  party’s Email Address, notice will be taken to be given:
                </p>
                <p>
                  (i) 24 hours after the email was sent, unless that falls on a
                  Saturday, Sunday or a public holiday in the state or territory
                  whose laws govern this agreement, in which case the notice
                  will be taken to be given on the next occurring business day
                  in that state or territory; or
                </p>
                <p>(ii) when replied to by the other party,</p>
                <p>whichever is earlier.</p>
                <p>
                  25 <strong>GENERAL</strong>
                </p>
                <p>25.1 GOVERNING LAW AND JURISDICTION</p>
                <p>
                  This agreement is governed by the law applying in New South
                  Wales, Australia. Each party irrevocably submits to the
                  exclusive jurisdiction of the courts of New South Wales and
                  courts of appeal from them in respect of any proceedings
                  arising out of or in connection with this agreement. Each
                  party irrevocably waives any objection to the venue of any
                  legal process on the basis that the process has been brought
                  in an inconvenient forum.
                </p>
                <p>25.2 WAIVER</p>
                <p>
                  No party to this agreement may rely on the words or conduct of
                  any other party as a waiver of any right unless the waiver is
                  in writing and signed by the party granting the waiver.
                </p>
                <p>25.3 SEVERANCE</p>
                <p>
                  Any term of this agreement which is wholly or partially void
                  or unenforceable is severed to the extent that it is void or
                  unenforceable. The validity and enforceability of the
                  remainder of this agreement is not limited or otherwise
                  affected.
                </p>
                <p>25.4 JOINT AND SEVERAL LIABILITY</p>
                <p>
                  An obligation or a liability assumed by, or a right conferred
                  on, two or more persons binds or benefits them jointly and
                  severally.
                </p>
                <p>25.5 ASSIGNMENT</p>
                <p>
                  A party cannot assign, novate or otherwise transfer any of its
                  rights or obligations under this agreement without the prior
                  written consent of the other party.
                </p>
                <p>25.6 COSTS</p>
                <p>
                  Except as otherwise provided in this agreement, each party
                  must pay its own costs and expenses in connection with
                  negotiating, preparing, executing and performing this
                  agreement.
                </p>
                <p>25.7 ENTIRE AGREEMENT</p>
                <p>
                  This agreement embodies the entire agreement between the
                  parties and supersedes any prior negotiation, conduct,
                  arrangement, understanding or agreement, express or implied,
                  in relation to the subject matter of this agreement.
                </p>
                <p>25.8 INTERPRETATION</p>
                <p>
                  (a) (<strong>singular and plural</strong>) words in the
                  singular includes the plural (and vice versa);
                </p>
                <p>
                  (b) (<strong>gender</strong>) words indicating a gender
                  includes the corresponding words of any other gender;
                </p>
                <p>
                  (c) (<strong>defined terms</strong>) if a word or phrase is
                  given a defined meaning, any other part of speech or
                  grammatical form of that word or phrase has a corresponding
                  meaning;
                </p>
                <p>
                  (d) (<strong>person</strong>) a reference to “person” or “you”
                  includes an individual, the estate of an individual, a
                  corporation, an authority, an association, consortium or joint
                  venture (whether incorporated or unincorporated), a
                  partnership, a trust and any other entity;
                </p>
                <p>
                  (e) (<strong>party</strong>) a reference to a party includes
                  that party’s executors, administrators, successors and
                  permitted assigns, including persons taking by way of novation
                  and, in the case of a trustee, includes any substituted or
                  additional trustee;
                </p>
                <p>
                  (f) (<strong>this agreement</strong>) a reference to a party,
                  clause, paragraph, schedule, exhibit, attachment or annexure
                  is a reference to a party, clause, paragraph, schedule,
                  exhibit, attachment or annexure to or of this agreement, and a
                  reference to this agreement includes all schedules, exhibits,
                  attachments and annexures to it;
                </p>
                <p>
                  (g) (<strong>document</strong>) a reference to a document
                  (including this agreement) is to that document as varied,
                  novated, ratified or replaced from time to time;
                </p>
                <p>
                  (h) (<strong>headings</strong>) headings and words in bold
                  type are for convenience only and do not affect
                  interpretation;
                </p>
                <p>
                  (i) (<strong>includes</strong>) the word “includes” and
                  similar words in any form is not a word of limitation; and
                </p>
                <p>
                  (j) (<strong>adverse interpretation</strong>) no provision of
                  this agreement will be interpreted adversely to a party
                  because that party was responsible for the preparation of this
                  agreement or that provision.
                </p>
                <br clear="all" />
                <p>
                  <a name="_Ref124860942"></a>
                  <a name="_heading=h.1ci93xb"></a>
                  <strong>Part B </strong>
                  <strong>Reviewers</strong>
                </p>
                <p>
                  1 <strong>ELIGIBILITY AND QUALIFICATIONS</strong>
                </p>
                <p>
                  If in any of your Rent Reviews or anywhere on your Account you
                  hold yourself out to possess certain qualifications, including
                  but not limited to having resided at a specific Property in
                  the past, ( <strong>Qualifications</strong>), you warrant to
                  Aussie Rent Reviews that you do hold such Qualifications and
                  if requested, will promptly provide Aussie Rent Reviews with
                  evidence of the Qualifications.
                </p>
                <p>
                  <strong>2 </strong>
                  <strong>PROPERTIES</strong>
                </p>
                <p>
                  You acknowledge and agree that: <strong></strong>
                </p>
                <p>
                  (a) you can only provide Rent Reviews in relation to the
                  Properties in which you have resided in the past;
                </p>
                <p>
                  (b) by listing yourself as a Reviewer on the Platform and
                  selecting a specific Property to review, you warrant that you
                  have actually resided at such a Property and that you are able
                  and willing to provide a Rent Review of the Property;
                </p>
                <p>
                  (c) when requested by the Platform, you must provide evidence
                  that you have resided at a particular Property via the
                  functionality of the Platform; and
                </p>
                <p>
                  (d) Aussie Rent Reviews may choose not to allow you to provide
                  Rent Reviews for certain Properties and/or may limit the
                  number of properties you may provide Rent Reviews for.
                </p>
                <p>
                  <strong>3 </strong>
                  <strong>REVIEWS</strong>
                </p>
                <p>You acknowledge and agree that:</p>
                <p>
                  (a) by providing Rent Reviews on the Platform, you are acting
                  as a service provider to the Buyers of such Rent Reviews;
                </p>
                <p>
                  (b) you must provide each Rent Review in accordance with
                  clause 4 of Part A of this agreement;
                </p>
                <p>
                  (c) you must use your best endeavours to provide as much
                  information as possible in any Rent Review;
                </p>
                <p>
                  (d) any information you supply in a Rent Reviews must be true,
                  timely and accurate;
                </p>
                <p>
                  (e) you must be courteous, polite and professional in all your
                  Rent Reviews and/or interactions with other Users of the
                  Platform or with the Platform itself;
                </p>
                <p>
                  (f) you must take all reasonable steps to provide a Rent
                  Review that you accept to perform for Buyer, including by not
                  cancelling any part of such a Rent Review;
                </p>
                <p>
                  (g) you must deal with any dispute with a Buyer in accordance
                  with clause 14 of Part A;
                </p>
                <p>
                  (h) any additional terms and conditions relating to a Rent
                  Review are solely between you and the relevant Buyer and do
                  not involve Aussie Rent Reviews in any way, except that they
                  must not be inconsistent with your or the Buyer’s obligations
                  under this agreement; and
                </p>
                <p>
                  (i) Aussie Rent Reviews will have no responsibility for the
                  accuracy, reliability or timeliness of any Buyer’s response to
                  a Rent Review.
                </p>
                <p>
                  4 <strong>PROVISION OF SERVICES</strong>
                </p>
                <p>
                  (a) You must ensure that all services specified in a Rent
                  Review that is accepted by a Buyer are provided:
                </p>
                <p>
                  (i) within the Call Window as specified in clause 4 of Part A
                  to be eligible to receive payment
                </p>
                <p>
                  (ii) in accordance with all applicable laws, regulations, tax
                  obligations and industry standards;
                </p>
                <p>
                  (iii) with due care and skill and in a professional, punctual
                  and diligent manner; and
                </p>
                <p>
                  (iv) so that the services are fit for their intended purpose.
                </p>
                <p>
                  (b) You acknowledge and agree that a Buyer may review any Rent
                  Review or services you provide under a Rent Review on the
                  Platform in accordance with clause 9 of Part A of this
                  agreement.
                </p>
                <p>
                  (c) If a Buyer requests to reschedule the delivery time for
                  the Rent Review, you may choose to accept or reject such a
                  request.
                </p>
                <p>
                  5 <strong>FEES</strong>
                </p>
                <p>
                  (a) Viewing the Platform and making yourself available to
                  review Properties is free.
                </p>
                <p>
                  (b) The Platform will mandate a set price that Buyers will pay
                  in exchange for receiving a Rent Review from you (
                  <strong>Review Fee</strong>). We will charge you a percentage
                  of the Review Fee (<strong>Service Fee</strong>) for each Rent
                  Review that is accepted by a Buyer. The specific amounts that
                  the Platform mandates for the Review Fee and the Service Fee
                  will be made available on the Platform at all times at
                  www.aussierentreviews.com.au/pricing.
                </p>
                <p>
                  (c) The balance of the Review Fee minus the Service Fee ({" "}
                  <strong>Your Fee</strong>) will be held in your Online Payment
                  Partner account, by the Online Payment Partner, and
                  transferred to your nominated bank account upon completion of
                  a Conference Call relating to a Rent Review, as specified in
                  clause 4, Part A and in accordance with the Online Payment
                  Partner’s terms and conditions.
                </p>
                <p>(d) You:</p>
                <p>
                  (i) appoint the Online Payment Partner as your limited
                  collection agent solely for the purpose of accepting Your Fee
                  from the Buyer;
                </p>
                <p>
                  (ii) agree that payments made by Users to the Online Payment
                  Partner (acting as your collection agent) will be considered
                  the same as payments made directly to you; and
                </p>
                <p>
                  (iii) agree that all payments to your nominated bank accounts
                  will be made in accordance with the timing specified by the
                  Online Payment Partner, which may vary depending on your
                  geographic location. The Online Payment Partner’s current
                  payment terms are available here
                  <a href="https://stripe.com/docs/payouts">
                    https://stripe.com/docs/payouts
                  </a>
                </p>
                <p>
                  <a name="_heading=h.3whwml4"></a>
                  (e) Aussie Rent Reviews reserves the right to change or waive
                  the Service Fee at any time by updating this agreement, on
                  written notice to you. We will provide you with at least 14
                  days’ written notice if this occurs, and upon receipt of such
                  notice you will have the right to terminate this agreement
                  immediately, on written notice to us. Your continued use of
                  the Services after you receive such written notice will
                  constitute your consent to the change and/or waiver set out in
                  that notice.
                </p>
                <p>
                  (f) You acknowledge and agree that, unless applicable laws or
                  regulations require otherwise, taxes (including GST) will be
                  calculated and charged on the Review Fee and we will calculate
                  the Service Fee on an amount equal to the Review Fee plus any
                  taxes applicable to the Review Fee.
                </p>
                <p>
                  <a name="_heading=h.3as4poj"></a>6{" "}
                  <strong>REFUNDS &amp; CANCELLATIONS</strong>
                </p>
                <p>
                  (a) Without limiting or otherwise affecting the terms of this
                  agreement, if you wish to cancel a service set out in an
                  accepted Rent Review, before you have performed all the
                  relevant services, you must contact us using the Platform’s
                  functionality, including by providing details as to why you
                  are cancelling. If Aussie Rent Reviews decides to investigate
                  your request, you must provide assistance and information to
                  Aussie Rent Reviews as reasonably requested.
                </p>
                <p>
                  <a name="_Ref126160710">
                    (b) You acknowledge that the refund policy set out in clause
                  </a>
                  4 of Part A of this agreement will apply if you fail to be
                  available for all of the call attempts a Buyer is entitled to
                  for each Rent Review you accept to provide.
                </p>
                <p>
                  (c) Subject to clause 6(b) of Part B above, you must ensure
                  that your cancellation policy and refund policy in relation to
                  you or Buyers cancelling an accepted Rent Review is in
                  compliance with all applicable laws.
                </p>
                <p>
                  (d) If we accept your request to cancel a service set out in
                  an accepted Rent Review, we may take one or more of the
                  following actions:
                </p>
                <p>
                  (i) cancel your Account and/or any membership you hold in
                  connection with the Platform;
                </p>
                <p>(ii) refund the Review Fee to the relevant Buyer; and</p>
                <p>
                  (iii) if we refund the Review Fee, require you to pay all or
                  part of the Review Fee to us, and issue you with an invoice
                  for that amount, if the Review Fee has already been
                  transferred to you.
                </p>
                <p>
                  (e) The Service Fee is by default non-refundable for change of
                  mind. However, Aussie Rent Reviews may, in its absolute
                  discretion, issue refunds of the Service Fee in certain
                  circumstances.
                </p>
                <p>
                  <a name="_heading=h.1pxezwc"></a>
                  (f) You agree to honour and comply with the process set out in
                  this clause 6 in the event of a pricing error in a Rent
                  Review.
                </p>
                <p>
                  <a name="_heading=h.49x2ik5"></a>7 <strong>BYPASSING</strong>
                </p>
                <p>
                  (a) You agree that while you are a Reviewer on the Platform,
                  and for one (1) year after you cease using the Platform,
                  regardless of the reason that your Account was suspended or
                  cancelled, you will not, either directly or indirectly,
                  solicit or attempt to solicit any business, work, income or
                  other benefit, associated with providing property reviews from
                  any Buyer whom you came to know about, or with whom you
                  provided services to directly or indirectly in connection with
                  the Platform. This provision will apply whether or not the
                  relevant Buyer or their representative is still active on the
                  Platform.
                </p>
                <p>
                  (b) Aussie Rent Reviews may, in its absolute discretion,
                  cancel your Account and suspend you from using the Platform if
                  it finds or suspects that you have breached or are in breach
                  of this clause 7.
                </p>
                <p>
                  <a name="_heading=h.2p2csry"></a>8{" "}
                  <strong>BINDING CONTRACT</strong>
                </p>
                <p>
                  You agree that when a Buyer submits an enquiry in response to
                  your Rent Review, this constitutes their intention and offer
                  to enter into a contract with you, where you will provide the
                  Buyer with the service as specified in the relevant Rent
                  Review, in exchange for payment of the relevant Review Fee. A
                  contract is formed in this respect (between you and the Buyer)
                  when you respond to an email or message on the Platform
                  confirming that you accept the Buyer’s offer.
                </p>
                <p>
                  <a name="_heading=h.147n2zr"></a>9{" "}
                  <strong>RELATIONSHIP</strong>
                </p>
                <p>
                  (a) You acknowledge and agree that the relationship between
                  you and Aussie Rent Reviews is one of independent contractors.
                  Nothing in this agreement, or your use of the Platform or the
                  Services, shall create an employment or an agency relationship
                  between you and Aussie Rent Reviews.
                </p>
                <p>
                  (b) We do not, and shall not be deemed to, direct or control
                  you generally or in your performance under this agreement
                  specifically, including in connection with your provision of
                  Rent Reviews, your acts or omissions. Other than expressly
                  stated otherwise in this agreement, you retain the sole right
                  to determine when, how and for how long you will utilize the
                  Platform or the Services and/or provide Rent Reviews.
                </p>
                <p>
                  10 <strong>WARRANTIES</strong>
                </p>
                <p>
                  By listing yourself as a Reviewer on the Platform, or
                  responding to a Rent Review request, you represent and warrant
                  that:
                </p>
                <p>
                  (a) you are able to fulfil the requirements of the services
                  specified in the Rent Review request;
                </p>
                <p>(b) you will provide services to each Buyer:</p>
                <p>
                  (i) using suitably qualified and trained personnel exercising
                  due care and skill in a professional, efficient, diligent and
                  safe manner, and to the best industry standards; and
                </p>
                <p>(ii) in compliance with all applicable laws; and</p>
                <p>
                  (c) Any individuals involved in your performance of services
                  to any Buyer have not been previously convicted of an
                  indictable offence, and any companies involved in your
                  performance of services have no current legal, criminal, civil
                  or administrative proceedings against them.
                </p>
                <br clear="all" />
                <p>
                  <a name="_heading=h.2bn6wsx"></a>
                  <strong>Part C </strong>
                  <strong>Buyers</strong>
                </p>
                <p>
                  <a name="_heading=h.qsh70q"></a>
                  <strong>1 </strong>
                  <strong>REVIEWS, SERVICE REQUESTS AND FEES</strong>
                </p>
                <p>(a) You acknowledge and agree that:</p>
                <p>
                  (i) the Platform will make available to you for booking Rent
                  Reviews associated with specific Properties;
                </p>
                <p>
                  (ii) if you book a Rent Review, that will constitute your
                  offer and intention to enter into a contract with the
                  Reviewer;
                </p>
                <p>
                  (iii) for each offer you submit in response to a Rent Review
                  on the Platform that is accepted by the relevant Reviewer, the
                  Review Fee will be debited from your Account and Aussie Rent
                  Reviews will keep a Service Fee which will be calculated as a
                  percentage of the Review Fee and will not be an additional
                  charge to you; and
                </p>
                <p>
                  (iv) any terms and conditions relating to services or a quote
                  provided via the Platform are solely between you and the
                  relevant Reviewer and do not involve Aussie Rent Reviews in
                  any way, except that such terms and conditions must not be
                  inconsistent with your or the Reviewer’s obligations under
                  this agreement.
                </p>
                <p>
                  <a name="_heading=h.ihv636"></a>
                  <strong>2 </strong>
                  <strong>RECORDINGS</strong>
                </p>
                <p>
                  The Platform will record your Rent Review and you may purchase
                  the associated Recording, or Recordings from other Users, from
                  us for the amount and using the payment method set out on the
                  Platform. <strong></strong>
                </p>
                <p>
                  3 <strong>PAYMENT</strong>
                </p>
                <p>
                  (a) (<strong>Payment obligations</strong>) Unless otherwise
                  agreed in writing with the Reviewer, you must pay for all
                  services specified in an accepted Rent Review prior to the
                  Reviewer performing those services.
                </p>
                <p>
                  (b) (<strong>Card surcharges</strong>) Our Online Payment
                  Partner may charge credit card surcharges in the event that
                  payments are made using a credit, debit or charge card
                  (including Visa, MasterCard or American Express).
                </p>
                <p>
                  <a name="_heading=h.32hioqz"></a>4{" "}
                  <strong>CANCELLATIONS</strong>
                </p>
                <p>
                  (a) If a Reviewer cancels a Rent Review after it has been
                  agreed or fails to provide you with all the Calls you are
                  entitled to in accordance with clause 4 of Part A of this
                  agreement, you will be entitled to a refund of the fees you
                  have paid, less payment processing fees in relation to the
                  specific Rent Review. You may also be entitled to cancellation
                  under the dispute resolution process set out in clause 14.
                </p>
                <p>
                  (b) If you cancel a service specified in an agreed Rent
                  Review, whether the relevant Review Fee paid by you is
                  refundable will depend on the cancellation policy and refund
                  policy of the relevant Reviewer.
                </p>
                <p>
                  <a name="_heading=h.1hmsyys"></a>5{" "}
                  <strong>LINKED BUSINESSES</strong>
                </p>
                <p>You acknowledge and agree that:</p>
                <p>
                  (a) the Platform provides links and introductions to Reviewers
                  owned and operated by third parties that are not under the
                  control of Aussie Rent Reviews;
                </p>
                <p>
                  (b) the provision by Aussie Rent Reviews of introductions to
                  Reviewers does not imply any endorsement or recommendation by
                  Aussie Rent Reviews of any Reviewer or Recording;
                </p>
                <p>
                  (c) Aussie Rent Reviews does not examine, determine or warrant
                  the certification and/or licensing, competence, solvency or
                  information of any Reviewer who uses or is listed on the
                  Platform; and
                </p>
                <p>
                  <a name="_heading=h.41mghml"></a>
                  (d) any terms and conditions relating to a Rent Review or
                  quote provided via the Platform constitute a contract between
                  you and the Reviewer once agreed in accordance with clause 1
                  and do not involve Aussie Rent Reviews in any way.
                </p>
                <p>
                  <a name="_heading=h.2grqrue"></a>6{" "}
                  <strong>COMMUNICATION OUTSIDE THE PLATFORM</strong>
                </p>
                <p>
                  (a) You must not communicate with a Reviewer, or request or
                  entice a Reviewer to communicate with you, outside the
                  Platform (except in the course of accepting Reviewer services
                  that were agreed in a Rent Review).
                </p>
                <p>
                  (b) Aussie Rent Reviews, in its absolute discretion, may
                  cancel your Account and suspend you from using the Platform if
                  it finds or suspects that you have breached or are in breach
                  of this clause 6.
                </p>
              </Box>
            </Grid>
          </Container>
        </Grid>
      </Grid>

      <StickyFooter />
    </>
  );
};

export default MarketplaceTerms;
