import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { format, subHours } from "date-fns";
import {
  Box,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { PostNewsletter } from "../PostNewsLetter";
import { PostContent } from "../PostContent";
import Navbar from "../../../components/Navbar/Navbar";
import StickyFooter from "../../../components/Footer/Footer";

const usePost = () => {
  const [post, setPost] = useState({
    category: "NSW Rental Market",
    content1: `
    \n Are you in the process of moving out and searching for a new place to call home? We understand that finding a rental property that matches your needs and preferences can be overwhelming. If you're looking for information to guide you to choosing the perfect property, we've got you covered.
    
    \n ### Defining Your Basic Rental Requirements:

    \n ## Budget & Pricing
    \n When it comes to renting, budgeting and pricing are critical factors to consider. It's essential to have a clear understanding of your financial limitations before starting your search. Keep in mind that renting a property involves more than just the monthly rent; you'll also need to consider additional expenses such as utilities, parking, and any maintenance fees. To stay within your budget, make sure you have a realistic idea of what you can afford and stick to it.
    \n Budgeting correctly will also become the biggest constraint on your available choices and in a tight rental market, options have become even more limited. If you find yourself fortunate enough to have a couple of properties to choose from, then the following factors are not to be ignored.

    \n ## Location
    \n The location of your rental property is just as important as the property itself. It's essential to consider factors such as proximity to public transport, amenities, and local services when choosing a location. If you're new to the region, take some time to research different neighbourhoods and determine which ones align with your lifestyle and preferences. 
    \n Resources such as [Sydney Suburb Reviews](https://sydneysuburbreviews.com/) provide insights on individual suburb cultures and demographics. 
    \n [Homely](https://www.homely.com.au/) also provides user reviews by suburb. Just be careful to ignore biased reviews posted by Agents looking to sell properties. 
    \n Additionally, consider the commute time to your workplace or school, as a long commute can impact your quality of life. Keep in mind that while certain locations may be more expensive, they may also offer better access to services and amenities that could be worth the extra cost. 
    \n Ultimately, finding the right location for your rental property requires careful consideration and research to ensure it meets your needs and enhances your quality of life.

    \n ## Bedrooms, Bathroom, Parking
    \n When considering the rooms available for your moving party, it’s important to remember that all rooms are not made equal. 
    \n Ignoring the obvious factor of room sizing, you will likely also experience a difference in windows, window view, lighting, access to heatin, cooling, insulation, ventilation, wifi strength, power outlets and storage. Be wary that some properties will advertise storage spaces as a 2nd or 3rd bedroom. 
    \n Many people often miss checking the garage space when inspecting a property. Not knowing how accessible or secure it is until moving in.

    \n ## Flatmates
    \n Living with flatmates can be a great way to save money on rent and to build new friendships. It can also provide a sense of security when living in an unfamiliar city or neighbourhood. 
    \n The potential downside to consider is that you will sacrificing some level of privacy due to the sharing of common spaces, such as the kitchen, living room or bathroom.  
    \n Determining who your flatmates will be is an important decision to make. Whether it be with existing friends or family, or using a service like [Flatmates.com.au](https://flatmates.com.au) to find a new household to join, be sure to interview wisely to minimise any personality clashes and misunderstandings. 
    
    \n ## Furnished Option
    \n Choosing a furnished option can save you time and money on furniture, as well as make moving in and out easier. It can also be convenient for those who are only planning to stay for a short period of time. However, furnished options are generally more expensive than unfurnished ones, and you may not have control over the quality or style of the furniture provided.
    \n If you’re open to buying your own furniture but want to minimise the high price tags that come with new purchases, then online marketplaces such as Facebook Marketplace, Gumtree, and eBay offer a wide range of discounted second-hand items. Every now and again, you can find good quality second-hand designer furniture selling up to 90% cheaper than their initial retail price. 
    \n Just be sure to confirm the measurements before buying and inspect for any damage when picking it up. While rare in Australia, it's still worth checking for and taking precautions to avoid any unwanted infestations such as bed bugs.

    \n ## Utilities and Appliances
    \n The last basic requirement is by far the easiest to overlook. If you're used to having all your utilities run smoothly, it may not have crossed your mind that they may not exist or could break down easily in a rental property.
    \n These include but are not limited to

    \n - Power Supply: Ensure that there are sufficient power sockets to meet your needs.
    \n - Power Efficiency: Check the efficiency of the appliances in the space, as older appliances can result in much higher energy bills.
    \n - Water Supply: It's essential to have a reliable water supply.
    \n - Laundry: Will a washing machine and dryer be included and do they work well?
    \n - Dishwasher: Will a dishwasher be included and does it work well?
    \n - Air Conditioning: Consider the effectiveness of the air conditioning system in the space. Older air conditioning units may not function well and require frequent repairs. For apartments, central aircon units need replacing every 10-15 years but are usually left for much longer, while residents deal with the constant break downs and patch repair jobs. 
    \n - Lighting: Make sure that there is adequate lighting for your living and work spaces.
    \n - Internet Connection and Speed: Check the availability and reliability of NBN and consider the need for a wi-fi mesh system to ensure coverage throughout the space.
    \n It's crucial to identify your essential utility requirements before making a decision. We understand that it may be challenging to research and determine these factors beforehand. 
    \n If you're interested in learning about our recommended method for evaluating utilities and appliances before moving in, please skip ahead to the final section of this guide.

    \n ### Shortlisting the Best Properties:
    \n Having your basic requirements set will simplify the process of shortlisting potential properties. With a thousands of listings available every day, it is a burdensome task to sift through them all.
    \n Attempting to visit hundreds of properties in person can be a major time sink, particularly when most inspections are scheduled for Saturdays causing conflicts.
    \n When using popular property listing websites such as [realestate.com.au](http://realestate.com.au/) or [Domain](http://domain.com.au/), applying your basic requirements as filters, along with utilising the Map view, will greatly speed up your screening process.

    \n ## Decoding Property Images
    \n Images are a staple feature of a property listing. If images are few, or worse yet, no images provided, it's important to ask yourself if something is intentionally being hidden.
    \n Many pretty images however, doesn't necessarily mean they present a complete or accurate picture of the property either. 
    \n To avoid attending an unnecessary viewing, it would be diligent to look beyond the visual aesthetics of the image and try to envision the actual room dimensions and features. This can be achieved by seeking out existing floor plans online or looking for similar units in the same complex.
    \n This is especially important if you plan on purchasing furniture, as having accurate measurements can prevent costly mistakes. Take a few extra minutes to thoroughly study the images to potentially save yourself hours.

    \n ### Viewing/Inspection Checklist:
    \n There is a lot to do during an inspection and not much time to do it. To make things worse, other inspectors or a tardy real estate agent may also add to the list of distractions.
    \n That's why we've put together a comprehensive checklist to help you make the most of your inspection.
    \n Just enter your email below to receive a free PDF so you'll have it in your inbox to reference when you go for your viewings.
    
    `,
    content2: `
    \n If you're interested in our method to quickly find all the issues of a specific property without going through the entire checklist, feel free to skip ahead to the final section of this guide.

    \n ### Before You Sign Your Lease!

    \n ## Inspect the Real Estate Agent, Building Manager, Strata

    \n If you're active on Facebook Groups or Subreddits related to renting, you've likely seen an abundance of distrust towards Real Estate Agents and property managers. Horror stories range from refusing to fix simple appliances to aggressive rate increases or even illegal eviction of tenants in favor of charging higher rates to new tenants.
    \n However, it's important to remember that not every agent is bad. There are many good agents and property managers out there who are fair and genuinely want to help. Often, issues arise that are outside of the agent's control, such as an uncooperative landowner or an unresponsive building manager or strata.
    \n But then what can you do to find out information about your Agent or building?
    \n Google reviews for rental agencies and building managers can give you a general idea about their reputation, but it's hard to find your exact agent and these reviews can easily be faked. Asking for a condition report is another option, but it can be a slow process and may not cover detailed information about appliances or the building's features.
    \n We propose a better solution in the final section below.

    \n ### Aussie Rent Reviews: Unbiased Reviews From Verified Tenants
    \n Aussie Rent Reviews offers a convenient platform to anonymously connect you with a previous tenant of a specific property.
    \n We understand that it often is not viable to assess every single item on an inspection checklist, and let's be honest, you may not even care about all the little things that are working as expected. But what you do care about is if there is an issue and it is not easy to fix.
    \n Instead of spending hours inspecting every detail of a rental property, you could cut to the chase and get all the important information you need over a quick phone call.
    \n For a one time fee, Aussie Rent Reviews connects you to an unbiased source of truth who can assist in uncovering all the  potential deal breakers before you sign your lease. 
    \n With lease terms generally running for 12 month periods and accumulating up to $33,800 on average in annual rent payments, $100 is a small investment to avoid a high breakage fees or other unexpected issues down the road.
    \n [Search now](https://www.aussierentreviews.com.au/reviews/search/) and find a verified tenant today. 

    `,
    cover: "/jamie-davies-Hao52Fu9-F8-unsplash.jpg",
    publishedAt: 1680863119131,
    shortDescription:
      "Mastering the Art of Rental Property Selection: Expert Tips and Tricks to Avoid Common Pitfalls ",
    title:
      "Find Your Perfect Home: A Tenant's Guide to Choosing the Best Rental Property in NSW",
  });

  return post;
};

const Page = () => {
  const post = usePost();

  if (!post) {
    return null;
  }

  const publishedAt = format(post.publishedAt, "MMMM d, yyyy");

  return (
    <>
      <div>
        <Helmet>
          <title>
            Find Your Perfect Home: A Tenant's Guide to Choosing the Best Rental
            Property in NSW
          </title>
          <meta
            name="description"
            content="Discover the Ultimate Guide to Choosing Your Dream Rental Property in NSW. Learn the Ins and Outs of the Selection Process to Find Your Perfect Home Today"
          />
        </Helmet>
      </div>
      <Navbar />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, fontSize: 30, mt: 8 }}
            >
              {post.title}
            </Typography>
            <Typography
              color="text.secondary"
              variant="h2"
              sx={{ fontWeight: 400, fontSize: 20 }}
            >
              {post.shortDescription}
            </Typography>

            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <div>
                <Typography
                  color="text.secondary"
                  variant="h2"
                  sx={{ fontWeight: 400, fontSize: 16 }}
                >
                  {publishedAt}
                </Typography>
              </div>
            </Stack>
          </Stack>
          <Box
            sx={{
              backgroundImage: `url(${post.cover})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              borderRadius: 1,
              height: 380,
              mt: 3,
            }}
          />
          {post.content1 && (
            <Container sx={{ py: 3 }}>
              <PostContent content={post.content1} />
            </Container>
          )}
          <Container>
            <Box sx={{ mb: 1, pl:4 }}>
              <PostNewsletter />
            </Box>
          </Container>

          
          {post.content2 && (
            <Container sx={{ py: 3 }}>
              <PostContent content={post.content2} />
            </Container>
          )}



        </Container>
      </Box>

      <StickyFooter />
    </>
  );
};

export default Page;
