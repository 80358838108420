import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";
import imgPhone from "../../assets/images/phone-hand.jpg";
import './styles.css'


window.onbeforeunload = function () { window.scrollTo(0,0); }

const About = () => {



    return (
      <>
        <div>
          <Helmet>
            <title>About Us - Aussie Rent Reviews</title>
            <meta name="description" content="Learn more about Aussie Rent Reviews and how many people are now earning for giving a simple review"/>
          </Helmet>
        </div>

        <Navbar />

        <Grid container xxs={12}>
          <Container
            maxWidth={false}
            sx={{
              backgroundImage:
                "url(/katsia-jazwinska-RIb19_YSUX0-unsplash.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "30vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 11,
              }}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                display="flex"
              >
                <Container>
                  <Typography
                    variant="h2"
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontFamily: "Open Sans",
                    }}
                    sx={{
                      width: "20%",
                      fontSize: { xxs: "40px", sm: "50px" },
                      mt: 5,
                      mb: 10,
                      ml: { lg: 3 },
                    }}
                  >
                    Empowering Aussies with better living information
                  </Typography>
                </Container>
              </Grid>
            </Box>
          </Container>
        </Grid>

        <Grid container xxs={12} sx={{ mb: 1, minHeight: "52vh" }}>
          <Grid item xxs={12} sm={12}>
            <Container sx={{ mt: { xxs: -5, sm: -10 } }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xxs={12} md={8}>
                  <Grid container justifyContent="flex-start">
                    <Grid item xxs={12} md={6}>
                      <Box mt={5}>
                        <Card
                          sx={{ minWidth: 200, mt: 8 }}
                          style={{
                            backgroundColor: "#f9f9f9",
                            border: "none",
                            boxShadow: "none",
                          }}
                          raise={true}
                        >
                          <CardContent>
                            <AccessTimeIcon
                              style={{ color: "#fda94f" }}
                              fontSize="large"
                            />
                            <Typography variant="h3" sx={{ mt: 2 }}>
                              Time and time again...
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ mt: 2 }}
                              color="text.secondary"
                            >
                              Tenants have nasty surprises when moving in.
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      <Box sx={{ mt: { xxs: 0, sm: 0, md: 8 } }}>
                        <Card
                          sx={{ minWidth: 200 }}
                          style={{
                            backgroundColor: "#f9f9f9",
                            border: "none",
                            boxShadow: "none",
                          }}
                        >
                          <CardContent>
                            <NaturePeopleIcon
                              style={{ color: "#fda94f" }}
                              fontSize="large"
                            />
                            <Typography variant="h3" sx={{ mt: 2 }}>
                              We understand...
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ mt: 2 }}
                              color="text.secondary"
                            >
                              that there is only so much that can be learned
                              from a 15-30 minute inspection.
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      <Box>
                        <Card
                          sx={{ minWidth: 200 }}
                          style={{
                            backgroundColor: "#f9f9f9",
                            border: "none",
                            boxShadow: "none",
                          }}
                        >
                          <CardContent>
                            <QuestionAnswerIcon
                              style={{ color: "#fda94f" }}
                              fontSize="large"
                            />
                            <Typography variant="h3" sx={{ mt: 2 }}>
                              Our proposition for new tenants...
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ mt: 2 }}
                              color="text.secondary"
                            >
                              is to provide answers about living in that exact
                              address. Tenants can find out about what matters
                              to them most, before they move in.
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      <Box>
                        <Card
                          sx={{ minWidth: 200 }}
                          style={{
                            backgroundColor: "#f9f9f9",
                            border: "none",
                            boxShadow: "none",
                          }}
                        >
                          <CardContent>
                            <LocalPhoneIcon
                              style={{ color: "#fda94f" }}
                              fontSize="large"
                            />
                            <Typography variant="h3" sx={{ mt: 2 }}>
                              Before you sign your new lease...
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ mt: 2 }}
                              color="text.secondary"
                            >
                              have a quick phone conversation with a verified
                              ex-tenant of your new address. This small effort
                              could save you from an expensive and lengthy
                              commitment.
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xxs={12}
                  md={4}
                  lg={4}
                  sx={{ ml: "auto", mt: { xxs: 0, sm: 15 }, mb: 5 }}
                >
                  <Card
                    // raised={true}
                    styles={{ borderRadius: 20, boxShadow: "none" }}
                  >
                    <img src={imgPhone} className="imgPhone" />

                    <CardContent>
                      <Typography variant="h3" sx={{ mb: 2, mt: 0 }}>
                        Want to get paid $100 every call?
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        If you are a tenant who has recently or will soon be
                        moving out, we want to hear from you.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ mt: 1 }}
                      >
                        Earn $100 each time you answer a short phone call, with
                        no cap on your maximum earn.
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ mt: 1 }}
                      >
                        Sign up and add a review in less than 5 minutes.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        component={Link}
                        to="/add-review"
                        variant="contained"
                        size="small"
                        style={{
                          borderRadius: "2px",
                          backgroundColor: "#fda94f", //"#FF7F50",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                        }}
                        sx={{ color: "#ffffff" }}
                      >
                        Add Review
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
        <StickyFooter />
      </>
    );
};

export default About;
