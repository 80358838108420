import * as actionType from '../constants/actionTypes.mjs';


export default (state = { isLoading: true, userReviewerStatus: [] }, action) => {

    switch (action.type) {

        case actionType.FETCH_USER_REVIEWER_STATUS:
                return { ...state, userReviewerStatus: action.payload.data };
        default:
            return state;

    }
}

