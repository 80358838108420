
import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const AccessDenied = () => (
  <>
    <Box sx={{mt:50}}>
      <title>
        404 | Material Kit
      </title>
    </Box>
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%'
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            align="center"
            color="textPrimary"
            variant="h3"
          >
            Please log in to access this page
          </Typography>


            <Button component={Link} to="/auth"  
                style={{ backgroundColor: "#484848" }}
                sx={{ mt: 10,mb:80 }}
                variant="contained"
            >
              Log In
            </Button>

        </Box>
      </Container>
    </Box>
  </>
);

export default AccessDenied;