import React from "react";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

const WebsiteTerms = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Website Terms and Conditions - Aussie Rent Reviews</title>
        </Helmet>
      </div>
      <Navbar />

      <Grid container xxs={12} sx={{ minHeight: "70vh" }}>
        <Grid item xxs={12} sm={12}>
          <Container>
            <Grid container spacing={3} alignItems="center">
              <Box sx={{ ml: 4, mr: 1, mt: 6 }}>
                <Typography
                  variant="h2"
                  sx={{ mt: 10, ml: 0, mb: 5, fontWeight: 500, fontSize: 25 }}
                >
                  Website Terms
                </Typography>

                <Typography variant="body2" sx={{ mt: 2, ml: 0, mb: 5 }}>
                  By using this website you agree to be bound by the terms and
                  conditions set out on this webpage. If you do not accept these
                  terms and conditions, you must refrain from using this
                  website. This website is owned and operated by Aussie Rent
                  Reviews Pty Ltd ACN 664109022 referred to in these terms and
                  conditions as ‘we’, ‘us’, or ‘our’).
                </Typography>

                <Typography
                  variant="h2"
                  sx={{ mt: 3, ml: 0, mb: 2, fontWeight: 500, fontSize: 20 }}
                >
                  1. INTELLECTUAL PROPERTY
                </Typography>

                <Typography variant="body2" sx={{ mt: 3, ml: 0, mb: 5 }}>
                  Unless otherwise indicated, all materials used on this website
                  (including text, graphics, logos, icons, sound recordings and
                  software) are subject to copyright, trade mark or other
                  intellectual property rights that are owned or licensed by us.
                  You may only access and use these materials for non-commercial
                  or personal use, except to the extent permitted by law or
                  where you have received prior written approval from us.
                </Typography>

                <Typography
                  variant="h2"
                  sx={{ mt: 3, ml: 0, mb: 2, fontWeight: 500, fontSize: 20 }}
                >
                  2. LIABILITY
                </Typography>

                <Typography variant="body2" sx={{ mt: 3, ml: 0, mb: 5 }}>
                  We make no warranties or representations about this website or
                  any of its content and will not be responsible to you or any
                  third party for any direct or consequential loss suffered in
                  connection with the use of this website. To the maximum extent
                  permitted by law, we exclude any liability that may arise due
                  to your use of our website and/or the information or materials
                  contained on it. You agree to indemnify us for any loss or
                  liability arising out of your use of this site.
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ mt: 3, ml: 0, mb: 2, fontWeight: 500, fontSize: 20 }}
                >
                  3. EXTERNAL LINKS
                </Typography>

                <Typography variant="body2" sx={{ mt: 3, ml: 0, mb: 5 }}>
                  Our website may contain links to websites operated by third
                  parties. Those links are provided for convenience and may not
                  remain current or be maintained. Unless expressly stated
                  otherwise, we do not endorse and are not responsible for the
                  content on those linked websites and have no control over or
                  rights in those linked websites.
                </Typography>

                <Typography
                  variant="h2"
                  sx={{ mt: 3, ml: 0, mb: 2, fontWeight: 500, fontSize: 20 }}
                >
                  4. UPDATES
                </Typography>

                <Typography variant="body2" sx={{ mt: 3, ml: 0, mb: 5 }}>
                  We may change these terms and conditions at any time by
                  updating this webpage and your continued use of the website
                  following such update will represent an agreement by you to be
                  bound by the terms and conditions as amended.
                </Typography>

                <Typography
                  variant="h2"
                  sx={{ mt: 3, ml: 0, mb: 2, fontWeight: 500, fontSize: 20 }}
                >
                  5. PRIVACY
                </Typography>

                <Typography variant="body2" sx={{ mt: 3, ml: 0, mb: 1 }}>
                  These terms and conditions also include our Privacy Policy
                  that can be accessed by clicking the link on this page.
                </Typography>

                <Link href="/privacy-policy" variant="body2" >
                  Privacy Policy
                </Link>

                <Typography
                  variant="h2"
                  sx={{ mt: 5, ml: 0, mb: 2, fontWeight: 500, fontSize: 20 }}
                >
                  6. JURISDICTION
                </Typography>

                <Typography variant="body2" sx={{ mt: 3, ml: 0, mb: 10 }}>
                  Your use of the website and these terms and conditions are
                  governed by the laws of New South Wales, Australia.
                </Typography>
              </Box>
            </Grid>
          </Container>
        </Grid>
      </Grid>

      <StickyFooter />
    </>
  );
};

export default WebsiteTerms;
