import { START_LOADING, END_LOADING, FETCH_BY_CAN_URL, FETCH_BY_SEARCH, FETCH_REVIEW_SUGGESTIONS, CREATE, UPDATE, DELETE } from '../constants/actionTypes.mjs';
import * as api from '../api/index.js';



export const createReview = (review,verificationDocs) => async (dispatch) => {
  try {

    const { data } = await api.createReview(review);
    dispatch({ type: CREATE, payload: data });

    //tag review ID 
    var verifyDocsTagged = verificationDocs;
  
    const idUpload_file_type = verificationDocs[0].document.name.split('.')[1]
    const supportDoc1_type = verificationDocs[1].document.name.split('.')[1]
    const supportDoc2_type = verificationDocs[2].document.name.split('.')[1]

    const idUpload_new_name = String(data._id) +  '-photoID-'+ String(data.creator) + '_datetime_' +   Date.now().toString() + '.' + idUpload_file_type
    const supportDoc1_new_name = String(data._id) +  '-supportDoc1-'+ String(data.creator) + '_datetime_'  + Date.now().toString() + '.' + supportDoc1_type
    const supportDoc2_new_name = String(data._id) +  '-supportDoc2-'+ String(data.creator) + '_datetime_'  + Date.now().toString() + '.' + supportDoc2_type

    verifyDocsTagged[0].document = new File([verificationDocs[0].document], idUpload_new_name, {type: verificationDocs[0].document.type});
    verifyDocsTagged[1].document = new File([verificationDocs[1].document], supportDoc1_new_name, {type: verificationDocs[1].document.type});
    verifyDocsTagged[2].document = new File([verificationDocs[2].document], supportDoc2_new_name, {type: verificationDocs[2].document.type});

    await await api.verifyReview(verifyDocsTagged);
   

  } catch (error) {
    console.log(error);
  }
}



export const getReviewsBySearch = (q, page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data: { data, currentPage, numberOfPages, priorSearchTerm, mapData } } = await api.getReviewsBySearch(q, page);

    dispatch({ type: FETCH_BY_SEARCH, payload: { data, currentPage, numberOfPages, priorSearchTerm, mapData } });

    dispatch({ type: END_LOADING });

  } catch (error) {
    console.log(error);
  }
};


export const getReviewSearchSuggestions = (q) => async (dispatch) => {
  try {

    const { data: { data } } = await api.getReviewSearchSuggestions(q);
    dispatch({ type: FETCH_REVIEW_SUGGESTIONS, payload: { data } });

  } catch (error) {
    console.log(error);
  }
};

export const getReviewListingByCanaconicalUrl = (addressAlias) => async (dispatch) => {
  try {
    const { data } = await api.getReviewListingByCanaconicalUrl(addressAlias);
    dispatch({ type: FETCH_BY_CAN_URL, payload: data });

  } catch (error) {
    console.log(error);
  }
};


export const deleteReview = (id) => async (dispatch) => {
  try {

    await await api.deleteReview(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error);
  }
};


export const updateReview = (id, review) => async (dispatch) => {
  try {
    const { data } = await api.updateReview(id, review);

    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error);
  }
};


export const createCheckOut = (reviewData) => async (dispatch) => {

  try {
    const { data } = await api.createCheckOut(reviewData);

    return dispatch({ type: 'DEFAULT', payload: data });

  } catch (error) {
    console.log(error);
  }
};

