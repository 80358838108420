import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WidgetInput, Address } from "react-addressfinder";
import { createReview } from "../../actions/reviews";
import "./styles.css";
import useStyles from "./styles";

const Form = () => {

  const classes = useStyles();
  const navigate = useNavigate();

  const [reviewData, setReviewData] = useState({
    reviewAddress: "",
    firstName: "",
    lastName: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    mobileNumber: "",
    startDate:"",
    endDate:"",
    reviewNote:"",
    addressLine1:"",
    addressLine2:"",
    addressCity:"",
    addressState:"",
    addressCountry:"",
    addressPostcode:"",
  });

  const [verificationData, setVerificationData] = useState([]);

  const [formErrors, setFormErrors] = useState({});
  const [fileErrors, setFileErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [isManualAddress, setIsManualAddress] = useState(false);
  const [switchAttempt, setSwitchAttempt] = useState(false);

  const switchMode = () => {
    setSwitchAttempt(true)
    setReviewData({...reviewData,reviewAddress:"",addressLine1:"",addressLine2:"",addressCity:"",addressState:"",addressPostcode:""});
    setIsManualAddress((prevIsManualAddress) => !prevIsManualAddress);
  };

  
  useEffect(() => {
    setSwitchAttempt(false)
}, [switchAttempt]);


  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("profile"));

  const handleSubmit = async (e) => {
    e.preventDefault();

    //add date conversion features

    const monthNamesIndex = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
      "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const startMonthNumeric = monthNamesIndex.findIndex((item) => reviewData.startMonth === item) //Date index will show Jan as 0. This is consistent with the index of new Date initialised below
    const startDt = new Date(parseInt(reviewData.startYear),startMonthNumeric,10);
    const endMonthNumeric = monthNamesIndex.findIndex((item) => reviewData.endMonth === item) 
    const endDt = new Date(parseInt(reviewData.endYear),endMonthNumeric,10);
    
    setReviewData({ ...reviewData, startDate: startDt, endDate: endDt })

    //set validation checks
    setFormErrors(validateForm(reviewData));
    setFileErrors(validateFiles(verificationData));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && Object.keys(fileErrors).length === 0 && isSubmit) {
      dispatch(createReview(reviewData, verificationData));
      navigate("/reviews/add-review-message");
    } else {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const validateForm = (values) => {
    const errors = {};

    //address switch validation

    if (!isManualAddress) {

      if (!values.addressLine1) {
        errors.reviewAddress = "Address parameter is missing!";
      }


      if (!values.addressCity) {
        errors.reviewAddress = "Address parameter is missing!";
      }

      if (!values.addressState) {
        errors.reviewAddress = "Address parameter is missing!";
      }

      if (!values.addressPostcode) {
        errors.reviewAddress = "Address parameter is missing!";
      } else if (values.addressPostcode.length !== 4) {
          errors.reviewAddress = "This is not a valid postcode";
      }



    } else {

      if (!values.reviewAddress) {
        errors.reviewAddress = "Address is required!";
      }

    }


    if (!values.firstName) {
      errors.firstName = "Name is required!";
    }

    if (!values.lastName) {
      errors.lastName = "Last name is required!";
    }

    if (!values.mobileNumber) {
      errors.mobileNumber = "Mobile number is required!";
    } else if (values.mobileNumber.substring(0, 1) === "0") {
      if (values.mobileNumber.length !== 10) {
        errors.mobileNumber = "This is not a valid mobile number!";
      }
    } else if (values.mobileNumber.substring(0, 2) === "61") {
      if (values.mobileNumber.length !== 11) {
        errors.mobileNumber = "This is not a valid mobile number!";
      }
    } else if (values.mobileNumber.substring(0, 2) === "+61") {
      if (values.mobileNumber.length !== 12) {
        errors.mobileNumber = "This is not a valid mobile number!";
      }
    } else {
      errors.mobileNumber = "This is not a valid mobile number!";
    }

    if (!values.startMonth) {
      errors.startMonth = "Start month is required!";
    }
    if (!values.startYear) {
      errors.startYear = "Start year is required!";
    } else if (isNaN(parseInt(values.startYear)) || (values.startYear.length !==4)  ){
        errors.startYear = "This is not a valid year!";
    } 
  
    if (!values.endMonth) {
      errors.endMonth = "End month or best guess is required!";
    }

    if (!values.endYear) {
      errors.endYear = "End year or best guess is required!";
    } else if (isNaN(parseInt(values.endYear)) || (values.endYear.length !==4)  ){
        errors.endYear = "This is not a valid year!";
    }
    
    if (values.startMonth && values.startYear && values.endMonth && values.endYear  ) {
      if (values.endDate < values.startDate ) {
        errors.dateLogic = "End date cannot be before your start date";
      }
    }

    return errors;
  };

  

  const validateFiles = (values) => {
    const errors = {};

    if (Object.keys(values).length === 0) {

      // errors.missingFiles = "Verification and supporting documents are required!";
      errors.missingFiles = "Verification document is required!";

    } else {

      // if (!values[0] || !values[1] || !values[2] ) {
      if (!values[0] ) {
        errors.missingFiles = "Documents are still needed!";
      } else {

        if (
          values[0].document.type !== "image/jpeg" &&
          values[0].document.type !== "image/png" &&
          values[0].document.type !== "image/jpg" &&
          values[0].document.type !== "application/pdf"
        ) {
          errors.photoFileType = "Only PDF, JPEG, JPG and PNG formats are allowed!";
        } else {
          if (values[0].document.size > 10485760) {
            errors.photoFileSize = "Fize size must not exceed 10mbs";
          }
        }

        // if (
        //   values[1].document.type !== "image/jpeg" &&
        //   values[1].document.type !== "image/png" &&
        //   values[1].document.type !== "image/jpg" &&
        //   values[1].document.type !== "application/pdf"
        // ) {
        //   errors.supportDoc1FileType =
        //     "Only PDF, JPEG, JPG and PNG formats are allowed!";
        // } else {
        //   if (values[1].document.size > 10485760) {
        //     errors.supportDoc1FileTypeSize = "Fize size must not exceed 10mbs";
        //   }
        // }

        // if (
        //   values[2].document.type !== "image/jpeg" &&
        //   values[2].document.type !== "image/png" &&
        //   values[2].document.type !== "image/jpg" &&
        //   values[2].document.type !== "application/pdf"
        // ) {
        //   errors.supportDoc2FileType =
        //     "Only PDF, JPEG, JPG and PNG formats are allowed!";
        // } else {
        //   if (values[2].document.size > 10485760) {
        //     errors.supportDoc2FileTypeSize = "Fize size must not exceed 10mbs";
        //   }
        // }

      }
    }
      return errors;
    };



  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "left",
          mb: 5,
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xxs={1}></Grid>

            <Grid item xxs={10}>
              {/* <form autoComplete="off" enctype="multipart/form-data" noValidate onSubmit={handleSubmit}> */}
              <form
                action="/upload"
                method="post"
                autoComplete="off"
                enctype="multipart/form-data"
                noValidate
                onSubmit={handleSubmit}
              >
                <Box sx={{ mt: 20 }}>
                  <Typography
                    variant="h4"
                    style={{ fontSize: 24, fontWeight: 400 }}
                    gutterBottom
                  >
                    {" "}
                    Start earning today!{" "}
                  </Typography>
                  <Typography
                    variant="h4"
                    style={{ fontSize: 18, fontWeight: 300 }}
                    color="text.secondary"
                    sx={{ mt: 2 }}
                    gutterBottom
                  >
                    {" "}
                    Create a review in under 5 minutes{" "}
                  </Typography>
                </Box>


                <Box sx={{ mt: 7, mb: 2 }}>
                  <Typography variant="h5" gutterBottom>
                    Address you intend to review
                  </Typography>
                </Box>
                
                {isManualAddress && (
                  <>



                <WidgetInput
                  className="addr_finder"
                  addressFinderKey={
                    process.env.REACT_APP_ADDRESS_FINDER_PUBLIC_KEY
                  }
                  country={"AU"}
                  addressParams={{ gnaf: "1", au_paf: "0", post_box: "0" }}
                  inputClassName={"address_finder"}
                  id={"addr_finder"}
                  onSelected={(fullAddress, address) => {
                    setReviewData({ reviewAddress: fullAddress });
                  }}
                  onChange={(e) => {
                    {
                      console.log(e);
                    }
                    setReviewData({
                      ...reviewData,
                      reviewAddress: e.target.value,
                    });
                  }}
                ></WidgetInput>

                </>
                )}

                {!isManualAddress && (
                  <>
                    <Grid item xxs={12} >

                      <Box sx={{ mt: -1 }}>
                        <TextField
                          style={{ background: "white" }}
                          className={classes.root}
                          label="Address Line 1: Street Address"
                          variant="outlined"
                          color="secondary"
                          margin="normal"
                          value={reviewData.addressLine1}
                          onChange={(e) =>
                            setReviewData({
                              ...reviewData,
                              addressLine1: e.target.value,
                            })
                          }
                          InputProps={{
                            style: { fontSize: 16 },
                          }}
                          sx={{
                            width: { xxs: "100%"},
                          }}
                        />
                      </Box>

                      <Box sx={{ mt: -1 }}>
                        <TextField
                          style={{ background: "white" }}
                          className={classes.root}
                          label="Address Line 2: Apartment, suite, unit, building, floor, etc"
                          variant="outlined"
                          color="secondary"
                          margin="normal"
                          value={reviewData.addressLine2}
                          onChange={(e) =>
                            setReviewData({
                              ...reviewData,
                              addressLine2: e.target.value,
                            })
                          }
                          InputProps={{
                            style: { fontSize: 16 },
                          }}
                          sx={{
                            width: { xxs: "100%"},
                          }}
                        />
                      </Box>
                      <Box sx={{ mt: -1 }}>
                        <TextField
                          style={{ background: "white" }}
                          className={classes.root}
                          label="City or Suburb"
                          variant="outlined"
                          color="secondary"
                          margin="normal"
                          value={reviewData.addressCity}
                          onChange={(e) =>
                            setReviewData({
                              ...reviewData,
                              addressCity: e.target.value,
                            })
                          }
                          InputProps={{
                            style: { fontSize: 16 },
                          }}
                          sx={{
                            width: { xxs: "100%"},
                          }}
                        />
                      </Box>


                      <Box sx={{ mt: -1 }}>
                        <TextField
                          style={{ background: "white" }}
                          className={classes.root}
                          label="State"
                          variant="outlined"
                          color="secondary"
                          margin="normal"
                          value={reviewData.addressState}
                          onChange={(e) =>
                            setReviewData({
                              ...reviewData,
                              addressState: e.target.value,
                            })
                          }
                          select
                          InputProps={{
                            style: { fontSize: 16 },
                          }}
                          sx={{ fontSize: "16px", width: { xxs: "100%", sm: 250 } }}
                        >
                          <MenuItem value={"ACT"}>ACT</MenuItem>
                          <MenuItem value={"NSW"}>NSW</MenuItem>
                          <MenuItem value={"NT"}>NT</MenuItem>
                          <MenuItem value={"QLD"}>QLD</MenuItem>
                          <MenuItem value={"SA"}>SA</MenuItem>
                          <MenuItem value={"TAS"}>TAS</MenuItem>
                          <MenuItem value={"VIC"}>VIC</MenuItem>
                          <MenuItem value={"WA"}>WA</MenuItem>


                        </TextField>
                      </Box>



                      <Box sx={{ mt: -1 }}>
                        <TextField
                          style={{ background: "white" }}
                          className={classes.root}
                          label="Post Code"
                          variant="outlined"
                          color="secondary"
                          margin="normal"
                          value={reviewData.addressPostcode}
                          onChange={(e) =>
                            setReviewData({
                              ...reviewData,
                              addressPostcode: e.target.value,
                            })
                          }
                          InputProps={{
                            style: { fontSize: 16 },
                          }}
                          sx={{ fontSize: "16px", width: { xxs: "100%", sm: 250 } }}
                        />
                      </Box>
                      
                    </Grid>
                  </>
                )}

                <Typography variant="body2" color="red">
                  {formErrors.reviewAddress}
                </Typography>

                <Button
                  onClick={switchMode}
                  variant="body2"
                  style={{
                    fontSize: 12,
                    fontFamily: "Poppins",
                    fontWeight: "400",
                  }}
                >
                  {isManualAddress
                    ? "Or enter address manually" 
                    : "Or use auto address complete"}
                </Button>



                <Grid container>
                  <Grid item xxs={12} lg={6}>
                    <Box sx={{ mt: 6 }}>
                      <Typography variant="h5" gutterBottom>
                        First Name
                      </Typography>
                    </Box>
                    <Box sx={{ mt: -1 }}>
                      <TextField
                        style={{ background: "white" }}
                        className={classes.root}
                        label="As shown on your tenancy records"
                        variant="outlined"
                        color="secondary"
                        margin="normal"
                        value={reviewData.firstName}
                        onChange={(e) =>
                          setReviewData({
                            ...reviewData,
                            firstName: e.target.value,
                          })
                        }
                        InputProps={{
                          style: { fontSize: 16 },
                        }}
                        sx={{
                          width: { xxs: "100%", sm: 400 },
                        }}
                      />
                      <Typography variant="body2" color="red">
                        {formErrors.firstName}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xxs={12} lg={6}>
                    <Box sx={{ mt: 6 }}>
                      <Typography variant="h5" gutterBottom>
                        Last Name
                      </Typography>
                    </Box>
                    <Box sx={{ mt: -1 }}>
                      <TextField
                        style={{ background: "white" }}
                        label="As shown on your tenancy records"
                        className={classes.root}
                        variant="outlined"
                        color="secondary"
                        margin="normal"
                        value={reviewData.lastName}
                        onChange={(e) =>
                          setReviewData({
                            ...reviewData,
                            lastName: e.target.value,
                          })
                        }
                        InputProps={{
                          style: { fontSize: 16 },
                        }}
                        sx={{
                          width: { xxs: "100%", sm: 400 },
                        }}
                      />
                      <Typography variant="body2" color="red">
                        {formErrors.lastName}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" gutterBottom>
                    Mobile Number
                  </Typography>
                </Box>
                <Box sx={{ mt: -1 }}>
                  <TextField
                    style={{ background: "white" }}
                    label="Enter a valid phone number to receive calls"
                    variant="outlined"
                    color="secondary"
                    margin="normal"
                    className={classes.root}
                    value={reviewData.mobileNumber}
                    onChange={(e) =>
                      setReviewData({
                        ...reviewData,
                        mobileNumber: e.target.value,
                      })
                    }
                    InputProps={{
                      style: { fontSize: 16 },
                    }}
                    sx={{ width: { xxs: "100%", sm: 400 } }}
                  />
                  <Typography variant="body2" color="red">
                    {formErrors.mobileNumber}
                  </Typography>
                </Box>

                <Box sx={{ mt: 5, mb: 2 }}>
                  <Typography variant="h5" gutterBottom>
                    Started living there:
                  </Typography>
                </Box>

                <Grid container spacing={24}>
                  <Grid item xxs={9} sm={8} md={8} lg={3}>
                    <Typography variant="h5" gutterBottom>
                      Month
                    </Typography>
                    <Select
                      style={{ background: "white" }}
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={reviewData.startMonth}
                      label="Select Month"
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          startMonth: e.target.value,
                        })
                      }
                      sx={{ fontSize: "16px", width: { xxs: "100%", sm: 250 } }}
                    >
                      <MenuItem value={"JAN"}>January</MenuItem>
                      <MenuItem value={"FEB"}>February</MenuItem>
                      <MenuItem value={"MAR"}>March</MenuItem>
                      <MenuItem value={"APR"}>April</MenuItem>
                      <MenuItem value={"MAY"}>May</MenuItem>
                      <MenuItem value={"JUN"}>June</MenuItem>
                      <MenuItem value={"JUL"}>July</MenuItem>
                      <MenuItem value={"AUG"}>August</MenuItem>
                      <MenuItem value={"SEP"}>September</MenuItem>
                      <MenuItem value={"OCT"}>October</MenuItem>
                      <MenuItem value={"NOV"}>November</MenuItem>
                      <MenuItem value={"DEC"}>December</MenuItem>
                    </Select>
                    <Typography variant="body2" color="red">
                      {formErrors.startMonth}
                    </Typography>
                  </Grid>

                  <Grid item xxs={9} sm={8} md={8} lg={3}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ mt: { xxs: -22, sm: -22, md: -22, lg: 0 } }}
                      gutterBottom
                    >
                      Year
                    </Typography>

                    <TextField
                      style={{ background: "white" }}
                      // label="select year"
                      className={classes.root}
                      variant="outlined"
                      color="secondary"
                      margin="normal"
                      value={reviewData.startYear}
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          startYear: e.target.value,
                        })
                      }
                      InputProps={{
                        style: { fontSize: 16 },
                      }}
                      sx={{ width: { xxs: "100%", sm: 250 }, mt: 0 }}
                    />

                    {/* <Select
                      style={{ background: "white" }}
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={reviewData.startYear}
                      fullWidth
                      label="Select Year"
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          startYear: e.target.value,
                        })
                      }
                      sx={{ fontSize: "16px", width: { xs: "100%", sm: 250 } }}
                    >
                      <MenuItem value={2000}>2000</MenuItem>
                      <MenuItem value={2001}>2001</MenuItem>
                      <MenuItem value={2002}>2002</MenuItem>
                      <MenuItem value={2003}>2003</MenuItem>
                      <MenuItem value={2004}>2004</MenuItem>
                      <MenuItem value={2005}>2005</MenuItem>
                      <MenuItem value={2006}>2006</MenuItem>
                      <MenuItem value={2007}>2007</MenuItem>
                      <MenuItem value={2008}>2008</MenuItem>
                      <MenuItem value={2009}>2009</MenuItem>
                      <MenuItem value={2010}>2010</MenuItem>
                      <MenuItem value={2011}>2011</MenuItem>
                      <MenuItem value={2012}>2012</MenuItem>
                      <MenuItem value={2013}>2013</MenuItem>
                      <MenuItem value={2014}>2014</MenuItem>
                      <MenuItem value={2015}>2015</MenuItem>
                      <MenuItem value={2016}>2016</MenuItem>
                      <MenuItem value={2017}>2017</MenuItem>
                      <MenuItem value={2018}>2018</MenuItem>
                      <MenuItem value={2019}>2019</MenuItem>
                      <MenuItem value={2020}>2020</MenuItem>
                      <MenuItem value={2021}>2021</MenuItem>
                      <MenuItem value={2022}>2022</MenuItem>
                      <MenuItem value={2023}>2023</MenuItem>
                      <MenuItem value={2024}>2024</MenuItem>
                    </Select> */}

                    <Typography variant="body2" color="red">
                      {formErrors.startYear}
                    </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 5, mb: 3 }}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ mt: { xxs: -10, sm: -10, md: -10, lg: 0 } }}
                    gutterBottom
                  >
                    Stopped living there since or your expected future date
                  </Typography>
                </Box>

                <Grid container spacing={24}>
                  <Grid item xxs={9} sm={8} md={8} lg={3}>
                    <Typography variant="h5" component="h5" gutterBottom>
                      Month
                    </Typography>

                    <Select
                      style={{ background: "white" }}
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={reviewData.endMonth}
                      label="Select Month"
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          endMonth: e.target.value,
                        })
                      }
                      sx={{ width: { xxs: "100%", sm: 250 } }}
                    >
                      <MenuItem value={"JAN"}>January</MenuItem>
                      <MenuItem value={"FEB"}>February</MenuItem>
                      <MenuItem value={"MAR"}>March</MenuItem>
                      <MenuItem value={"APR"}>April</MenuItem>
                      <MenuItem value={"MAY"}>May</MenuItem>
                      <MenuItem value={"JUN"}>June</MenuItem>
                      <MenuItem value={"JUL"}>July</MenuItem>
                      <MenuItem value={"AUG"}>August</MenuItem>
                      <MenuItem value={"SEP"}>September</MenuItem>
                      <MenuItem value={"OCT"}>October</MenuItem>
                      <MenuItem value={"NOV"}>November</MenuItem>
                      <MenuItem value={"DEC"}>December</MenuItem>
                    </Select>
                    <Typography variant="body2" color="red">
                      {formErrors.endMonth}
                    </Typography>
                  </Grid>

                  <Grid item xxs={9} sm={8} md={8} lg={3}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ mt: { xxs: -22, sm: -22, md: -22, lg: 0 } }}
                      gutterBottom
                    >
                      Year
                    </Typography>
                    {/* <Select
                      style={{ background: "white" }}
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={reviewData.endYear}
                      label="Select Year"
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          endYear: e.target.value,
                        })
                      }
                      sx={{ fontSize: "16px", width: { xs: "100%", sm: 250 } }}
                    >
                      <MenuItem value={2000}>2000</MenuItem>
                      <MenuItem value={2001}>2001</MenuItem>
                      <MenuItem value={2002}>2002</MenuItem>
                      <MenuItem value={2003}>2003</MenuItem>
                      <MenuItem value={2004}>2004</MenuItem>
                      <MenuItem value={2005}>2005</MenuItem>
                      <MenuItem value={2006}>2006</MenuItem>
                      <MenuItem value={2007}>2007</MenuItem>
                      <MenuItem value={2008}>2008</MenuItem>
                      <MenuItem value={2009}>2009</MenuItem>
                      <MenuItem value={2010}>2010</MenuItem>
                      <MenuItem value={2011}>2011</MenuItem>
                      <MenuItem value={2012}>2012</MenuItem>
                      <MenuItem value={2013}>2013</MenuItem>
                      <MenuItem value={2014}>2014</MenuItem>
                      <MenuItem value={2015}>2015</MenuItem>
                      <MenuItem value={2016}>2016</MenuItem>
                      <MenuItem value={2017}>2017</MenuItem>
                      <MenuItem value={2018}>2018</MenuItem>
                      <MenuItem value={2019}>2019</MenuItem>
                      <MenuItem value={2020}>2020</MenuItem>
                      <MenuItem value={2021}>2021</MenuItem>
                      <MenuItem value={2022}>2022</MenuItem>
                      <MenuItem value={2023}>2023</MenuItem>
                      <MenuItem value={2024}>2024</MenuItem>
                    </Select> */}
                    <TextField
                      style={{ background: "white" }}
                      className={classes.root}
                      // label="select year"
                      variant="outlined"
                      color="secondary"
                      margin="normal"
                      value={reviewData.endYear}
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          endYear: e.target.value,
                        })
                      }
                      InputProps={{
                        style: { fontSize: 16 },
                      }}
                      sx={{ width: { xxs: "100%", sm: 250 }, mt: 0 }}
                    />

                    <Typography variant="body2" color="red">
                      {formErrors.endYear}
                      {formErrors.dateLogic}
                    </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 8, mb: 3 }}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ mt: { xxs: -10, sm: -10, md: -10, lg: 0 } }}
                    gutterBottom
                  >
                    Identification: Drivers License, Photo ID or Passport (PDF, PNG, JPG, JPEG
                    format)
                  </Typography>

                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {" "}
                    This is required to verify your tenancy only. 
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {" "}
                    We treat your personal information with utmost security and
                    privacy. Your personal data will never be shared or sold.{" "}
                  </Typography>
                </Box>

                
                <input
                  type="file"
                  name="document"
                  onChange={(e) => {
                    const arrData = [...verificationData];
                    arrData[0] = { document: e.target.files[0] };
                    setVerificationData(arrData);
                  }}
                />

                <Typography variant="body2" color="red">
                  {fileErrors.photoFileType}
                  {fileErrors.photoFileSize}
                </Typography>


                {/* <Box sx={{ mt: 8, mb: 3 }}>
                  <Typography variant="h5" component="h5" gutterBottom>
                    Document 2: Signed Tenancy Agreement (PDF, PNG, JPG, JPEG
                    format)
                  </Typography>
                </Box> */}

                {/* <input
                  type="file"
                  name="document"
                  onChange={(e) => {
                    const arrData = [...verificationData];
                    arrData[1] = { document: e.target.files[0] };
                    setVerificationData(arrData);
                  }}
                />

                <Typography variant="body2" color="red">
                  {fileErrors.supportDoc1FileType}
                  {fileErrors.supportDoc1FileTypeSize}
                </Typography>
                <Box sx={{ mt: 8, mb: 3 }}>
                  <Typography variant="h5" component="h5" gutterBottom>
                    Document 3: Rental ledger or bills related to address (PDF,
                    PNG, JPG, JPEG format)
                  </Typography>
                </Box>

                <input
                  type="file"
                  name="document"
                  onChange={(e) => {
                    const arrData = [...verificationData];
                    arrData[2] = { document: e.target.files[0] };
                    setVerificationData(arrData);
                  }}
                />
                <Typography variant="body2" color="red">
                  {fileErrors.supportDoc2FileType}
                  {fileErrors.supportDoc2FileTypeSize}
                </Typography>

                 */}

                <Typography variant="body2" color="red" sx={{ mt: 2 }}>
                  {fileErrors.missingFiles}
                </Typography>

                <Typography
                  variant="h5"
                  component="h5"
                  sx={{ mt: 8 }}
                  gutterBottom
                >
                  (Optional) Add notes about your living experience
                </Typography>

                <Typography variant="body2" sx={{ mt: 1 }}>
                  {" "}
                  As time passes, these notes will serve as a detailed reminder
                  for you.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {" "}
                  Notes will be shared with those who purchase your review,
                  allowing for a shorter, straight-to-the-point phone call.{" "}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  (Expect less than 20 mins needed per call)
                </Typography>

                <TextField
                  style={{ background: "white" }}
                  variant="outlined"
                  className={classes.root}
                  color="secondary"
                  margin="normal"
                  value={reviewData.reviewNote}
                  onChange={(e) =>
                    setReviewData({ ...reviewData, reviewNote: e.target.value })
                  }
                  InputProps={{
                    style: { fontSize: 16 },
                  }}
                  multiline
                  rows={12}
                  sx={{ width: { xxs: "100%", sm: 600 }, mt: 3 }}
                />

                <Box sx={{ mt: 3 }}>
                  <Button
                    className="Button Submit"
                    variant="contained"
                    style={{
                      borderRadius: "2px",
                      backgroundColor: "#fda94f", //"#FF7F50",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                    sx={{ color: "#ffffff" }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Form;
