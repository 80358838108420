import { useState } from 'react';
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { PostCard }  from "./PostCard";

const usePosts = () => {

  const [posts, setPosts] = useState([
    {
      category: "NSW Rental Market",
      cover: "/jamie-davies-Hao52Fu9-F8-unsplash.jpg",
      publishedAt: 1680863119131,
      shortDescription: "Mastering the Art of Rental Property Selection: Expert Tips and Tricks to Avoid Common Pitfalls ",
      title: "Find Your Perfect Home: A Tenant's Guide to Choosing the Best Rental Property in NSW",
      href: "test",
    },
  ]);

  return posts

}

const Page = () => {


  const posts = usePosts();

  return (
    <>
      <div>
        <Helmet>
          <title>
            Aussie Rent Reviews: Blog Posts
            
          </title>
        </Helmet>
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={1}>
            <Typography variant="h3" sx={{fontSize:30}}>
              Recent Articles
            </Typography>

          </Stack>


          <Typography
            color="text.secondary"
            sx={{ mt: 4 }}
            variant="body1"
          >
            Discover the latest news, tips and research insights from Aussie Rent Reviews.
          </Typography>

          <Divider sx={{ my: 4 }} />
          <Grid
            container
            spacing={4}
          >
            {posts.map((post) => (
              <Grid
                key={post.title}
                xs={12}
                md={6}
              >
                <PostCard
                  category={post.category}
                  cover={post.cover}
                  publishedAt={post.publishedAt}
                  shortDescription={post.shortDescription}
                  title={post.title}
                  sx={{ height: '100%' }}
                />
              </Grid>
            ))}

          </Grid>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={1}
            sx={{
              mt: 4,
              mb: 8
            }}
          >

          </Stack>

        </Container>
      </Box>
    </>
  );
};


export default Page;
