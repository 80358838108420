import { AUTH, FETCH_BY_ID, FETCH_REVIEWS_BY_ID, FETCH_BY_USER_ID, MESSAGE, CREATE_BOOKMARK, DELETE_BOOKMARK,FETCH_USER_REVIEWER_STATUS, FETCH_ORDERS_BY_BUYERID, FETCH_ORDERS_BY_REVIEWERID, UPDATE_ORDER } from '../constants/actionTypes.mjs';
import * as api from '../api/index.js';

export const signin = (formData, router) => async (dispatch) => {
  try {

    await api.signIn(formData).then((res)=>{
      
      const data  = res.data;
      dispatch({ type: AUTH, data });
      router('/');

    });

  } catch (error) {
    
    dispatch({ type: MESSAGE, payload: error.response.data.message});
  }

};

export const signup = (formData, router) => async (dispatch) => {
  try {
    // const { data } = await api.signUp(formData);
    // dispatch({ type: AUTH, data });
    // router('/');

    await api.signUp(formData).then((res)=>{
  
      const data  = res.data;
      dispatch({ type: AUTH, data });
      // router('/user/activation');

    });

  } catch (error) {
    dispatch({ type: MESSAGE, payload: error.response.data.message});
  }
};

export const requestActivationLink = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.requestActivationLink(formData);

    dispatch({type: 'DEFAULT', data });

    router('/');
  } catch (error) {
    console.log(error);
  }
};


export const forgotpasswordemail = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.forgotpasswordEmail(formData);

    dispatch({type: 'DEFAULT', data });

    router('/');
  } catch (error) {
    console.log(error);
  }
};

export const postresetpassword = (id,token,formData, router) => async (dispatch) => {
  try {
    
    await api.postResetPassword(id,token,formData).then((res)=>{
      dispatch({ type: MESSAGE, payload: res.data.message});
    });


  } catch (error) {
    console.log(error)
    dispatch({ type: MESSAGE, payload: error.response.data.message});
  }
};

export const postknownresetpassword = (id,formData) => async (dispatch) => {

  try {
    await api.postKnownResetPassword(id,formData).then((res)=>{
      dispatch({ type: MESSAGE, payload: res.data.message});
    });

  } catch (error) {
    console.log(error)
    dispatch({ type: MESSAGE, payload: error.response.data.message});
  }
};


export const contactusform = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.contactusForm(formData);

    dispatch({ type: 'DEFAULT', data });

    router('/');
  } catch (error) {
    console.log(error);
  }
};



export const inspectionSubcriberForm = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.inspectionSubcriberForm(formData);

    console.log(formData)

    dispatch({ type: 'DEFAULT', data });

    router('/');
  } catch (error) {
    console.log(error);
  }
};


export const fetchReviewsByUser = (id) => async (dispatch) => {

  try {
 
      const { data: { data } }  = await api.fetchReviewsByUser(id);

      dispatch({ type: FETCH_REVIEWS_BY_ID, payload: {data} });


  } catch (error) {
      console.log(error.message);
  }
}

export const fetchOrdersByReviewer = (id) => async (dispatch) => {

  try {
 
      const { data: { data } }  = await api.fetchOrdersByReviewer(id);

      dispatch({ type: FETCH_ORDERS_BY_REVIEWERID, payload: {data} });


  } catch (error) {
      console.log(error.message);
  }
}

export const fetchOrdersByBuyer = (id) => async (dispatch) => {

  try {
 
      const { data: { data } }  = await api.fetchOrdersByBuyer(id);
      
      dispatch({ type: FETCH_ORDERS_BY_BUYERID, payload: {data} });


  } catch (error) {
      console.log(error.message);
  }
}




export const fetchUserBookmarks = (id) => async (dispatch) => {

  try {
 
    const { data: { data } }  = await api.fetchUserBookmarks(id);

      dispatch({ type: FETCH_BY_USER_ID, payload: {data} });

  } catch (error) {
      console.log(error.message);
  }

}


export const createUserBookmark = (bookmarkData) => async (dispatch) => {
  try {
    
    const  data  = await api.createUserBookmark(bookmarkData);
    dispatch({ type: CREATE_BOOKMARK, payload: data });

  } catch (error) {
    console.log(error);
  }
}


export const deleteUserBookmark = (userId,reviewId) => async (dispatch) => {
  try {

    await await api.deleteUserBookmark(userId,reviewId);

    dispatch({ type: DELETE_BOOKMARK, payload: reviewId });
  } catch (error) {
    console.log(error);
  }
};

export const fetchReviewsByUserBookmarks = (id) => async (dispatch) => {

  try {
 
      const { data: { data } }  = await api.fetchReviewsByUserBookmarks(id);
      
      dispatch({ type: FETCH_BY_ID, payload: {data} });


  } catch (error) {
      console.log(error.message);
  }
}



export const createStripeOnboarding = () => async (dispatch) => {

  try {
 
      const {data} = await api.createStripeOnboarding();

      return dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
      console.log(error.message);
  }
}
export const createStripeOnboardingRefresh = () => async (dispatch) => {

  try {
 
      const {data} = await api.createStripeOnboardingRefresh();

      return dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
      console.log(error.message);
  }
}

export const createStripeOnboardingConfirm = () => async (dispatch) => {

  try {
 
      const {data} = await api.createStripeOnboardingConfirm();

      return dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
      console.log(error.message);
  }
}

export const fetchStripeDashboard = () => async (dispatch) => {

  try {
 
      const {data} = await api.fetchStripeDashboard();

      return dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
      console.log(error.message);
  }
}


export const fetchUserReviewerStatus = (id) => async (dispatch) => {

  try {
 
      const { data: { data } }  = await api.fetchUserReviewerStatus(id);
      
      dispatch({ type: FETCH_USER_REVIEWER_STATUS, payload: {data} });


  } catch (error) {
      console.log(error.message);
  }
}

export const updateBuyOrder = (id, order) => async (dispatch) => {
  try {
    const { data } = await api.updateBuyOrder(id, order);

    dispatch({ type: UPDATE_ORDER, payload: data });
  } catch (error) {
    console.log(error);
  }
};



