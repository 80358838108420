import axios from 'axios';

let BASE_URL;
if (process.env.NODE_ENV === 'development') { 
  BASE_URL = 'http://localhost:5000'
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = process.env.BASE_URL
};

const API = axios.create({ baseURL: BASE_URL });

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
  
    return req;
  });

export const createReview = (newReview) => API.post('/reviews', newReview);
export const verifyReview = (verificationData ) => API.post(`/reviews/upload`, verificationData, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});


export const updateReview = (id, updatedReview) => API.patch(`/reviews/${id}`, updatedReview);
export const deleteReview = (id) => API.delete(`/reviews/${id}`);
export const createCheckOut = (reviewData) => API.post('/reviews/create-checkout-session/',reviewData);

export const createStripeOnboarding = () => API.get('/user/api/stripe-onboard');
export const createStripeOnboardingRefresh = () => API.get('/user/api/stripe-onboard-refresh');
export const createStripeOnboardingConfirm = () => API.get('/user/api/stripe-onboard-confirm');
export const fetchStripeDashboard = () => API.get('/user/api/stripe-dashboard');

export const fetchUserReviewerStatus = () => API.get('/user/api/reviewer-status');


// export const createConferenceCall = (orderId) => API.post('/call/conference',orderId);
export const createConferenceCall = (conferenceData) => API.post('/user/conference',conferenceData);
export const updateBuyOrder = (id, updatedOrder) => API.patch(`/user/orders/${id}`, updatedOrder);


export const getReviewsBySearch = (q,page) => API.get(`/reviews/api/search?q=${q.searchTrim}&page=${page}`);
export const getReviewSearchSuggestions = (q) => API.get(`/reviews/suggest?q=${q.searchTrim}`);

export const getReviewListingByCanaconicalUrl = (addressAlias) => API.get(`/reviews/api/address/${addressAlias}`);


// export const signIn = (formData) => API.post('/user/signin', formData).catch(err => {
//   console.log(err);
//   alert("Login credentials are incorrect");
// });


export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);

export const requestActivationLink = (formData) => API.post('/user/activate-account', formData);

export const forgotpasswordEmail = (formData) => API.post('/user/forgot-password', formData);
export const postResetPassword = (id, token, formData) => API.post(`/user/reset-password/${id}/${token}`, formData);
export const postKnownResetPassword = (id,formData) => API.post(`/user/reset-auth/${id}`, formData);

export const contactusForm = (formData) => API.post('/user/contact-us/', formData);
export const inspectionSubcriberForm = (formData) => API.post('/user/inspection-subscription/', formData);

export const fetchOrdersByBuyer = (id) => API.get(`/user/orders/buyer/${id}`);
export const fetchOrdersByReviewer = (id) => API.get(`/user/orders/reviewer/${id}`);

export const fetchReviewsByUser = (id) => API.get(`/user/my-account/${id}`);
export const fetchUserBookmarks = (id) => API.get(`/user/bookmarks/${id}`);
export const createUserBookmark = (newBookmark) => API.post('/user/bookmarks', newBookmark);
export const deleteUserBookmark = (userId,reviewId) => API.delete(`/user/bookmarks/${userId}/${reviewId}`);
export const fetchReviewsByUserBookmarks = (id) => API.get(`/user/saved-reviews/${id}`);




