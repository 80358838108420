import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Stack } from "@mui/material";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import decode from "jwt-decode";

import * as actionType from "../../constants/actionTypes.mjs";
import useStyles from "./styles";
import { getReviewsBySearch, getReviewSearchSuggestions } from "../../actions/reviews";

const drawerWidth = 240;

function Navbar(props) {
  const classes = useStyles();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    navigate("/auth");

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  // ----- Drawer/toggle section -----
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ mt: 2, mb: 2 }}>
        <a href="/">
          <img src="/logoimgc.png" alt="logo" className={classes.logo} />
        </a>
      </Box>
      <Divider />
      <List>
        {user?.result ? (
          <Button
            component={Link}
            to="/add-review"
            variant="contained"
            style={{
              borderRadius: "2px",
              backgroundColor: "#fda94f",//"#FF7F50",
              fontFamily: "Poppins",
              fontWeight: "600",
            }}
            sx={{ color: "#ffffff", mb:1 }}
          >
            Add Review
          </Button>
        ) : (
          <Button
            component={Link}
            to="/auth"
            variant="contained"
            style={{
              borderRadius: "2px",
              backgroundColor: "#fda94f",//"#FF7F50",
              fontFamily: "Poppins",
              fontWeight: "600",
            }}
            sx={{ color: "#ffffff", mb:1 }}
          >
            Add Review
          </Button>
        )}

        
      
        {user?.result ? (
          <>
            <ListItemButton component={Link} to="/user/saved-reviews" sx={{ textAlign: "center" }} > <ListItemText primary="Bookmarks" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500", }} /> </ListItemButton>
            <ListItemButton component={Link} to="/user/dashboard" sx={{ textAlign: "center" }}> <ListItemText primary="Dashboard" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500", }} /> </ListItemButton>
            <ListItemButton component={Link} to="/user/my-account" sx={{ textAlign: "center" }} > <ListItemText primary="Account" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500", }} /> </ListItemButton>
            <ListItemButton component={Link} to="/faq" sx={{ textAlign: "center" }} > <ListItemText primary="FAQ" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500", }} /> </ListItemButton>
            <ListItemButton component={Link} to="/" onClick={logout} sx={{ textAlign: "center" }}  > <ListItemText primary="Log Out" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500", }} /> </ListItemButton>
          </>
        ) : (
          <>
          <ListItemButton component={Link} to="/about" sx={{ textAlign: "center" }} > <ListItemText primary="About" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500", }} /> </ListItemButton>
          <ListItemButton component={Link} to="/faq" sx={{ textAlign: "center" }}> <ListItemText primary="FAQ" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500", }} /> </ListItemButton>
          <ListItemButton component={Link} to="/pricing" sx={{ textAlign: "center" }} > <ListItemText primary="Pricing" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500" }} /> </ListItemButton>
          <ListItemButton component={Link} to="/contact-us" sx={{ textAlign: "center" }} > <ListItemText primary="Contact Us" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500", }} /> </ListItemButton>
          <ListItemButton component={Link} to="/auth" sx={{ textAlign: "center" }}  > <ListItemText primary="Log In" primaryTypographyProps={{ fontFamily: "Poppins", fontWeight: "500", }} /> </ListItemButton>
          </>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  //----- search functionality -----

  const [search, setSearch] = useState("");
  const [isSuggestClick, setIsSuggestClick] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [suggestions, setSuggestions] = useState([]);


  const searchReview = () => {
    setIsSuggestClick(true)
    const searchTrim = search.trim()
    dispatch(getReviewsBySearch({ searchTrim },1));
    navigate(`/reviews/search/${searchTrim}`);

  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchReview();
    }
  };


  const { reviewSuggestions: { reviewSuggestions }, } = useSelector((state) => state);

  useEffect(() => {
    setShowBox(true)
    const searchTrim = search.trim()
    if (searchTrim==''){
      setSuggestions([])
    } else {
      dispatch(getReviewSearchSuggestions({ searchTrim }));
      setSuggestions(reviewSuggestions)
    }
  }, [search]);


  //force close auto suggest box
  useEffect(() => {
    setIsSuggestClick(false)
    const searchTrim = search.trim()
    if (searchTrim!==''){
      dispatch(getReviewSearchSuggestions({ searchTrim }));
    }
    setSuggestions([])
  }, [isSuggestClick]);


  const handleSuggestionClick = (suggestTerm) => {
    setIsSuggestClick(true)
    let suggestTermFormatted = suggestTerm.replace('/','%2F')
    navigate(`/reviews/search/${suggestTermFormatted}`)
  };

  const handleClickAway = () => {
    setShowBox(false)
  };
  
  const handleClickOnto = () => {
    setShowBox(true)
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        style={{ backgroundColor: "#f9f9f9", boxShadow: "none" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Stack direction="row" alignItems="right">
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              // Set display on toggle menu
              sx={{ mr: 2, display: { lg: "none" }, color: "darkgrey" }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{
                flexGrow: 1,
                display: { xxs: "none", xs: "none", sm: "none", md: "block" },
              }}
            >
              <a href="/">
                <img src="/logoimgc.png" alt="logo" className={classes.logo} />
              </a>
            </Box>
          </Stack>

          <ClickAwayListener onClickAway={handleClickAway}>

            <Box sx={{ height: 5, marginTop: "-35px" }} >
              <TextField
                
                placeholder="Search address or street or suburb…"
                onKeyDown={handleKeyPress}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  className: classes.textFieldStyle,
                  disableUnderline: true,
                  style: { fontSize: 16 },
                  
                }}
                className={classes.textField}
                // style={{fontSize:14}}
                variant="outlined"
                size="small"
                onClick={handleClickOnto}
              />

            <Box
              sx={{ 

                display: {xxs: 'none',xs:'inline-block'}
              }}
              >
                <Button
                  className={classes.searchButton}
                  onClick={searchReview}
                  style={{
                    borderRadius: "1px",
                    backgroundColor: "#fda94f",
                    maxWidth: "40px",
                    minWidth: "0px",
                    // width:'5px'
                  }}
                  sx={{ 
                    height: {xxs:39, xs:39, sm:39},
                    marginLeft: 0 ,
    
                  }}
                  variant="contained"
                >
                  <SearchIcon sx={{ fontSize: 24 }} />
                </Button>
              </Box>
              
                {showBox? (
                    <Box sx={{ width: "92%", pt: 0.5}} >
                      <Card  >
                        {suggestions.map((item) => {
                          return (

                              <Box  display="flex" onClick={() => handleSuggestionClick(item.reviewAddress)} sx={{mt:0.5,mb:0.5, p: "-15px", alignItems: "center", cursor: 'pointer', bgcolor: '#FFFFFF' , '&:hover': { background: "#fafafa", } }}  >
                                <Typography variant="body2" sx={{ ml: 3, pt: 1, fontSize: 14, fontWeight: 300, color: "#000000"}} > {item.reviewAddress} </Typography>
                              </Box>

                          );
                        })}
                      </Card>
                    </Box>
                )
                : <></>}
        

            </Box>
          </ClickAwayListener>
          {/* display controls for RHS nav menu */}
          <Box
            sx={{
              display: { lg: "block", md: "none", xs: "none", xxs: "none" },
            }}
          >
            {user?.result ? (
              <>

                <Button
                  component={Link}
                  to="/user/saved-reviews"
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  BOOKMARKS
                </Button>

                <Button
                  component={Link}
                  to="/user/dashboard"
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  Dashboard
                </Button>


                <Button
                  component={Link}
                  to="/user/my-account"
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  Account
                </Button>

                <Button
                  component={Link}
                  to="/faq"
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  FAQ
                </Button>

                <Button
                  component={Link}
                  to="/"
                  onClick={logout}
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  Log Out
                </Button>
                <Button
                  component={Link}
                  to="/add-review"
                  variant="contained"
                  style={{
                    borderRadius: "2px",
                    backgroundColor: "#fda94f", //"#FF7F50",
                    fontFamily: "Open Sans",
                    fontWeight: "800",
                  }}
                  sx={{ color: "#ffffff" }}
                >
                  Add Review
                </Button>
              </>
            ) : (
              <>
                <Button
                  component={Link}
                  to="/about"
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  About
                </Button>
                <Button
                  component={Link}
                  to="/faq"
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  FAQ
                </Button>
                <Button
                  component={Link}
                  to="/pricing"
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  Pricing
                </Button>
                <Button
                  component={Link}
                  to="/contact-us"
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  Contact Us
                </Button>
                <Button
                  component={Link}
                  to="/auth"
                  sx={{ color: "#696969" }}
                  style={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  Log In
                </Button>
                <Button
                  component={Link}
                  to="/auth"
                  variant="contained"
                  style={{
                    borderRadius: "2px",
                    backgroundColor: "#fda94f", //"#FF7F50",
                    fontFamily: "Open Sans",
                    fontWeight: "800",
                  }}
                  sx={{ color: "#ffffff" }}
                >
                  Add Review
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            //secondary display controls for Toggle icon
            display: { xxs: "block", xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            backgroundColor:"#f9f9f9" 
          }}
          style={{zIndex:2000}}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   */
  window: PropTypes.func,
};

export default Navbar;
