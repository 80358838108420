import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import CheckIcon from '@mui/icons-material/Check';
import HouseIcon from '@mui/icons-material/House';
import PhoneIcon from '@mui/icons-material/Phone';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Forward5Icon from '@mui/icons-material/Forward5';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Card from "@mui/material/Card";

import { getReviewsBySearch, getReviewSearchSuggestions } from "../../actions/reviews";
import NavbarHomePg from "../../components/Navbar/NavbarHomePg";
import StickyFooter from "../../components/Footer/Footer";

import "./banner.css";
import "../../index.css";


const Home = () => {

  const [search, setSearch] = useState("");
  const [isSuggestClick, setIsSuggestClick] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const navigate = useNavigate();

  const searchReview = () => {
    setIsSuggestClick(true)
    const searchTrim = search.trim()

    dispatch(getReviewsBySearch({ searchTrim },1));
    navigate(`/reviews/search/${searchTrim}`);

  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchReview();
    }
  };


  const { reviewSuggestions: { reviewSuggestions }, } = useSelector((state) => state);

  //auto suggestions
  useEffect(() => {
    setShowBox(true)
    const searchTrim = search.trim()

    if (searchTrim==''){
      setSuggestions([])
    } else {
      dispatch(getReviewSearchSuggestions({ searchTrim }));
      setSuggestions(reviewSuggestions)
    }

  }, [search]);

  //force close auto suggest box
  useEffect(() => {
    setIsSuggestClick(false)
    const searchTrim = search.trim()

    if (searchTrim!==''){
      dispatch(getReviewSearchSuggestions({ searchTrim }));
    }

    setSuggestions([])
  }, [isSuggestClick]);

  const handleSuggestionClick = (suggestTerm) => {
    setIsSuggestClick(true)
    let suggestTermFormatted = suggestTerm.replace('/','%2F')
    navigate(`/reviews/search/${suggestTermFormatted}`)

  };

  const handleClickAway = () => {
    setShowBox(false)
  };
  
  const handleClickOnto = () => {
    setShowBox(true)
  };

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  return (

  <>
    <div>
      <Helmet>
        <title>Aussie Rent Reviews</title>
        <meta name="description" content="Phone a tenant before you rent or buy your next property. Earn money for providing a simple review."/>
      </Helmet>
    </div>
    
    <div className="home-page">
      <NavbarHomePg />

      <div
        className="banner-area jarallax"
        style={{
          backgroundImage: "url(/background_image.jpg)",
        }}
      >
        <div className="container">
          <div className="banner-inner-wrap">
            <div className="row">
              <div className="col-12">
                <div className="banner-inner">
                  {/* <h1 className="title">SYDNEY RENT REVIEWS </h1> */}

                  <Typography variant="h1" color="black" opacity={0.8} mt={1} mb={3} sx={{ ml:-10,mt:{xxs:15,lg:30}}} >
                      AUSSIE RENT REVIEWS
                  </Typography>

                  <Typography variant="h5" color="text.secondary" opacity={0.8} mt={1} mb={3} sx={{fontWeight:400, fontFamily: 'Poppins', ml:-10}} >
                     Phone a tenant before you rent or buy your next property
                  </Typography>

                </div>
              </div>

              <div className="col-12">
                <div className="banner-search-wrap">
                  <ul className="nav nav-tabs rld-banner-tab">
                    <li className="nav-item">
                      <a className="nav-link active">Find Address</a>
                    </li>
                    <li className="nav-item">
                      <a href="#earn-as-a-tenant">
                        Earn as a Tenant
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="search">


                      <div className="rld-main-search">
                        <div className="row">
                          <div className="col-lg-10 col-md-8 col-sm-8 " >

            
                          
                          <ClickAwayListener onClickAway={handleClickAway}>
                              <Box onClick={handleClickOnto}>
                                <div className="rld-single-input left-icon" >
                                  <input
                                    onKeyDown={handleKeyPress}
                                    type="search"
                                    id="form1"
                                    className="form-control rounded"
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                    value={search}
                                    placeholder="Search address or street or suburb…"
                                    onChange={handleChange}
                                  />
                                </div>

                                {(showBox && (suggestions.length>0))? (
               
                                  <Box sx={{ width: "92%", mt: -2, ml:0.1, pb:0}} >
                                    <Card>
                                      {suggestions.map((item) => {
                                        return (

                                            <Box display="flex" onClick={() => handleSuggestionClick(item.reviewAddress)} sx={{mt:0.5,mb:0.5, p: "-15px", alignItems: "center", cursor: 'pointer', bgcolor: '#FFFFFF' , '&:hover': { background: "#fafafa", } }} >
                                              <Typography variant="body2" sx={{ ml: 3, pt: 1, fontSize: 14, fontWeight: 300, color: "#000000"}} > {item.reviewAddress} </Typography>
                                            </Box>

                                        );
                                      })}
                                    </Card>
                                  </Box>
                                

                              )
                              : <></>}

                            </Box>
                      </ClickAwayListener>
                              
                         
                          </div>

                       
                          <div className="col-lg-2 col-md-4 col-sm-4 ">
                            <button
                              onClick={searchReview}
                              type="button"
                              className="btn1 btn-yellow"
                            >
                              SEARCH NOW
                            </button>
                          </div>
                        </div>
                      </div>
  

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="why-choose-us-area bg-white pd-top-90 pd-bottom-60">
        <div className="container">
          <div className="section-title text-center">
              <Typography variant="h2" opacity={0.8} mt={1} mb={3} sx={{fontWeight:500, fontSize:30, mt:{xxs:10,xs:0}}} >
                FIND YOUR EXACT ADDRESS
              </Typography>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6 ">
              <div className="single-intro text-lg-left text-center">
                <div className="thumb">
         
                  <FamilyRestroomIcon style={{ color: '#fda94f' }} fontSize="large" sx={{mt:3,mb:2}}/>
           
                </div>
                <div className="details">

                  <Typography variant="h2" opacity={0.8} mt={1} mb={3} sx={{fontWeight:500}} >
                    Legitimate tenants
                  </Typography>
                  <Typography variant="body1" opacity={0.8} mt={1} mb={3} sx={{fontWeight:400}} >
                    All tenants are verified to have rented in their displayed address. 
                  </Typography>
  
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6 ">
              <div className="single-intro text-lg-left text-center offset-xl-1">
                <div className="thumb">
                  <HouseIcon style={{ color: '#fda94f' }} fontSize="large" sx={{mt:3,mb:2}}/>
                </div>
                <div className="details">

                  <Typography variant="h2" opacity={0.8} mt={1} mb={3} sx={{fontWeight:500}} >
                    Pay-per-Address
                  </Typography>
                  <Typography variant="body1" opacity={0.8} mt={1} mb={3} sx={{fontWeight:400}} >
                    No extra costs. Simply pay for each address that you are interested in.
                  </Typography>


                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6 ">
              <div className="single-intro text-lg-left text-center offset-xl-1">
                <div className="thumb">
                <CheckIcon style={{ color: '#fda94f' }} fontSize="large" sx={{mt:3,mb:2}}/>
                </div>
                <div className="details">

                  <Typography variant="h2" opacity={0.8} mt={1} mb={3} sx={{fontWeight:500}} >
                    Minimise your risk
                  </Typography>
                  <Typography variant="body1" opacity={0.8} mt={1} mb={3} sx={{fontWeight:400}} >
                    Avoid another rental horror story. Get information straight from an unbiased source. 
                  </Typography>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="earn-as-a-tenant"
        className="why-choose-us-area bg-gray pd-top-90 pd-bottom-60"
      >
        <div className="container">
          <div className="section-title text-center">
          <Typography variant="h2" opacity={0.8} mt={1} mb={3} sx={{fontWeight:500, fontSize:30}} >
                EARN AS A TENANT
              </Typography>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6 ">
              <div className="single-intro text-lg-left text-center">
                <div className="thumb">
                <PhoneIcon style={{ color: '#fda94f' }} fontSize="large"sx={{mt:3,mb:2}}/>
                </div>
                <div className="details">
                  <Typography variant="h2" opacity={0.8} mt={1} mb={3} sx={{fontWeight:500}} >
                  Earn $50 per call
                  </Typography>
                  <Typography variant="body1" opacity={0.8} mt={1} mb={3} sx={{fontWeight:400}} >
                      List your address and get paid each time you receive a short phone call.
                       You can be paid multiple times for the same review. 
                  </Typography>

                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6 ">
              <div className="single-intro text-lg-left text-center offset-xl-1">
                <div className="thumb">
                <VisibilityOffIcon style={{ color: '#fda94f' }} fontSize="large" sx={{mt:3,mb:2}}/>
                </div>
                <div className="details">


                  <Typography variant="h2" opacity={0.8} mt={1} mb={3} sx={{fontWeight:500}} >
                  Stay Anonymous
                  </Typography>
                  <Typography variant="body1" opacity={0.8} mt={1} mb={3} sx={{fontWeight:400}} >
                    Only your review address and approximate tenancy period will be listed.
                  </Typography>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6 ">
              <div className="single-intro text-lg-left text-center offset-xl-1">
                <div className="thumb">
                  <Forward5Icon style={{ color: '#fda94f' }} fontSize="large" sx={{mt:3,mb:2}}/>
                </div>
                <div className="details">

                  <Typography variant="h2" opacity={0.8} mt={1} mb={3} sx={{fontWeight:500}} >
                  Register in 5 mins
                  </Typography>
                  <Typography variant="body1" opacity={0.8} mt={1} mb={3} sx={{fontWeight:400}} >
                  Register and list your details to start earning!
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickyFooter />
    </div>
  </>

  );
};

export default Home;
