import React from "react";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Navbar from "../../components/Navbar/Navbar";

const ActivateAccount = () => {
  return (
    <>
      <Navbar />

      <Grid container xxs={12}>
        <Container
          maxWidth={false}
          sx={{
            backgroundImage: "url(/katsia-jazwinska-RIb19_YSUX0-unsplash.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "23vh",
          }}
        >
          <Box
            justifyContent="center"
            textAlign="center"
            alignItems="center"
            display="flex"
            minHeight="24vh"
            
          >
            <Typography
              variant="h1"
              style={{
                color: "white",
                fontFamily: "poppins",
                fontWeight: "500",

              }}
              sx= {{mt:{xxs:7},fontSize: {xxs:"32px" ,sm:"47px"},  }}
            >
              Thank you for signing up!
            </Typography>
          </Box>
        </Container>
      </Grid>

      <Grid container xxs={12}>
        <Grid item xxs={12} sm={12}>
          <Container>
            <Grid
              container
              spacing={3}
              alignItems="center"
              textAlign="center"
              display="flex"
              justifyContent="center"
            >
              <Box sx={{ ml: 3, mr:2}}>
                <Typography variant="body2" sx={{ mt: 10}} >
                    We have sent an activation link to your email. 
                </Typography>          
                    
                <Typography variant="body2" sx={{ mt: 3 }}>
                    Be sure to check your junk folder!
                </Typography>
              </Box>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default ActivateAccount;
