export default [


  {
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },


  // {
  //   featureType: "poi.attraction",
  //   stylers: [{ visibility: "off" }],
  // },



  {
    featureType: "poi.business",
    stylers: [{ visibility: "off" }],
  },

  // {
  //   featureType: "poi.government",
  //   stylers: [{ visibility: "off" }],
  // },
  {
    featureType: "poi.medical",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.place_of_worship",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.school",
    stylers: [{ visibility: "off" }],
  },

    {
    featureType: "poi.park",
    elementType:"labels",
    stylers: [{ visibility: "off" }],
  },

  {
    featureType: "poi.sports_complex",
    stylers: [{ visibility: "off" }],
  },


  {
    featureType: "road.highway",
    elementType: "geometry",
    // stylers: [{ color: "#746855" }],
    stylers: [{ color: "#D3D3D3" }],
  },



//--------------------



];
