import React from 'react';
import { BrowserRouter, Routes,Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material/';

import Home from './pages/Home/Home';

import Blog from './pages/Blog/Blog';
import PostFindYourPerfectHome from './pages/Blog/Blogs/find-your-perfect-home';

import EditForm from './pages/EditForm/EditForm';
import About from './pages/About/About';
import Results from './pages/Results/Results';
import ReviewListing from './pages/ReviewListing/ReviewListing';
import ReviewNotAvailable from './pages/ReviewListing/ReviewNotAvailable';

import Signup from './pages/Auth/Auth';
import ActivateAccount from './pages/ActivateAccount/ActivateAccount';
import ActivationSuccess from './pages/ActivateAccount/ActivationSuccess';
import ReActivateEmail from './pages/ActivateAccount/ReActivateEmail';
import AddReview from './pages/AddReview/AddReview';
import ConfirmDeleteReview from './pages/ConfirmDeleteReview/ConfirmDeleteReview';

import MyAccount from './pages/MyAccount/MyAccount';
import StripeOnboardRefresh from './pages/MyAccount/StripeOnboardRefresh';
import StripeOnboardConfirm from './pages/MyAccount/StripeOnboardConfirm';

import MyDashboard from './pages/MyDashboard/MyDashboard';

import MySavedReviews from './pages/MySavedReviews/MySavedReviews';

import Pricing from './pages/Pricing/Pricing';
import FAQ from './pages/FAQ/FAQ';
import ContactUs from './pages/ContactUs/ContactUs';
import WebsiteTerms from './pages/Policy/WebsiteTerms';
import PrivacyPolicy from './pages/Policy/PrivacyPolicy';
import MarketplaceTerms from './pages/Policy/MarketplaceTerms';


import ForgotPassword from './pages/ForgotPassword/ForgotPasswordEmail';
import ResetPassword from './pages/ForgotPassword/ResetPassword';
import PasswordResetSuccess from './pages/ForgotPassword/PasswordResetSuccess';
import ResetKnownPassword from './pages/ResetKnownPassword/ResetKnownPassword';
import OrderConfirmation from './pages/OrderConfirmation/OrderConfirmation';
import NewReviewSubmission from './pages/Form/NewReviewSubmission';
import EditBuyOrderForm from './pages/EditBuyOrderForm/EditBuyOrderForm';


const theme = createTheme({

    breakpoints: {
        values: {
          xxs: 0, // small phone
          xxs1:200,
          xs: 300, // phone
          xs1: 330, //partial breakpoints relevant for review cards and navbar
          xs2: 360, 
          xs3: 390,
          xs4: 420, 
          xs5: 450, 
          xs6: 480, 
          xs7: 510, 
          xs8: 540, 
          xs9: 570, 
          sm: 600, // tablets
          md: 900, // small laptop
          lg: 1200, // desktop
          xl: 1536, // large screens
          mobile1:425,
          mobile2:600
        }
      },

      zIndex: {
        appBar: 1251,
        // modal: 1250,
      },
      
    typography: {

        h1: {
            fontFamily: 'Comfortaa',
            textTransform: 'none',
            fontSize:60,
            fontWeight:'800', 
            },

        h2: {
            fontFamily:  "Poppins", 
            textTransform: 'none',
            fontSize: 24,
            fontWeight:'600',
            },
        h3: {
            fontFamily:   'Poppins', 
            textTransform: 'none',
            fontSize: 20,
            fontWeight: 500,
        },
        h4: {
            fontFamily:  "Poppins", 
            textTransform: 'none',
            fontSize: 20,
            fontWeight: 300, 
        },
        h5: {
            fontFamily:  "Poppins", 
            textTransform: 'none',
            fontSize: 18,
            fontWeight: 500,
            
        },
        h6: {
            fontFamily:  "Poppins", 
            textTransform: 'none',
            fontSize: 18,
            fontWeight: 300,  
            
        },

        h7: {
            fontFamily:  "Poppins", 
            textTransform: 'none',
            fontSize: 16,
            fontWeight: 500,  
        },



        body1: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1.6,
          },

        
          body2: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 300,
            lineHeight: 1.2,
          },
        
    },
    palette: {
        background: {
            default: '#f9f9f9',
        },
    },
      
  });

const App = () => {

  
    return (
        <ThemeProvider theme={theme}>   
         <CssBaseline/>
            <BrowserRouter>
                <Routes>

                    <Route path="/" exact element={<Home />} /> 

                    <Route path="/blog" exact element={<Blog />} />
                    <Route path="/blog/find-your-perfect-home" exact element={<PostFindYourPerfectHome />} />

                    <Route path="/reviews/search/" exact element={<Results />} />
                    <Route path="/reviews/search/:searchQuery" exact element={<Results />} />

                    <Route path="/reviews/address/:addressAlias" exact element={<ReviewListing />} />
                    <Route path="/reviews/address/not-available" exact element={<ReviewNotAvailable />} />

                    <Route path="/about" exact element={<About />} />
                    <Route path="/pricing" exact element={<Pricing />} />
                    <Route path="/faq" exact element={<FAQ  />} />
                    <Route path="/contact-us" exact element={<ContactUs  />} />
                    <Route path="/website-terms" exact element={<WebsiteTerms  />} />
                    <Route path="/privacy-policy" exact element={<PrivacyPolicy  />} />
                    <Route path="/marketplace-terms" exact element={<MarketplaceTerms  />} />


                    <Route path="/auth" exact element={<Signup />} />
                    <Route path="/user/activation" exact element={<ActivateAccount  />} />
                    <Route path="/user/activation-success" exact element={<ActivationSuccess  />} />
                    
                    <Route path="/user/activate" exact element={<ReActivateEmail  />} />

                    <Route path="/add-review" exact element={<AddReview  />} />
                    <Route path="/edit" exact element={<EditForm  />} />
                    <Route path="/reviews/confirm-delete" exact element={<ConfirmDeleteReview  />} />

                    <Route path="/user/orders/edit" exact element={<EditBuyOrderForm  />} />



                    <Route path="/reviews/add-review-message" exact element={<NewReviewSubmission  />} />
                    <Route path="/reviews/order-confirmation" exact element={<OrderConfirmation  />} />

                    <Route path="/user/my-account" exact element={<MyAccount  />} />
                    <Route path="/user/stripe-onboard" exact element={<StripeOnboardRefresh  />} />
                    <Route path="/user/stripe-onboarded" exact element={<StripeOnboardConfirm />} />

                    <Route path="/user/dashboard" exact element={<MyDashboard  />} />

                    <Route path="/user/saved-reviews" exact element={<MySavedReviews  />} />

                    <Route path="/user/forgot-password" exact element={<ForgotPassword  />} />
                    <Route path="/user/reset-password/:id/:token" exact element={<ResetPassword  />} />
                    <Route path="/user/reset-password-success" exact element={<PasswordResetSuccess  />} />

                    <Route path="/user/reset-auth/:id" exact element={<ResetKnownPassword  />} />

                </Routes>

            </BrowserRouter>
        </ThemeProvider>

    );
  };
  
  export default App;
  