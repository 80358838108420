import { combineReducers } from 'redux';
import reviews from './reviews';
import auth from './auth';
import bookmarks from './bookmarks.mjs';
import userReviews from './userReviews.mjs';
import reviewSuggestions from './reviewSuggestions.mjs';
import userReviewerStatus from './userReviewerStatus.mjs';
import orders from './orders.mjs';

export const reducers = combineReducers({ reviews, auth, bookmarks, userReviews, reviewSuggestions, userReviewerStatus,orders });

