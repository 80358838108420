import * as actionType from '../constants/actionTypes.mjs';


export default (state = { isLoading: true, userReviews: [] }, action) => {

    switch (action.type) {

        case actionType.FETCH_REVIEWS_BY_ID:
                return { ...state, reviews: action.payload.data };

        default:
            return state;

    }
}

