export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_BY_CAN_URL = 'FETCH_BY_CAN_URL';
export const FETCH_REVIEW_SUGGESTIONS = 'FETCH_REVIEW_SUGGESTIONS';
export const FETCH_USER_REVIEWER_STATUS = 'FETCH_USER_REVIEWER_STATUS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const FETCH_BY_REVIEW_ID = 'FETCH_BY_REVIEW_ID';
export const FETCH_REVIEWS_BY_ID = 'FETCH_REVIEWS_BY_ID';
export const FETCH_ORDERS_BY_REVIEWERID = 'FETCH_ORDERS_BY_REVIEWERID';
export const FETCH_ORDERS_BY_BUYERID = 'FETCH_ORDERS_BY_BUYERID';

export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_ID = 'FETCH_BY_ID';
export const FETCH_BY_USER_ID = 'FETCH_BY_USER_ID';
export const CREATE_BOOKMARK = 'CREATE_BOOKMARK';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';

export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';

export const UPDATE_USER = 'UPDATE_USER';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const MESSAGE = 'MESSAGE';

