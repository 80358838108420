import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";



const Pricing = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

  const purchaseDescriptors = [
    "Initiate a 20 minute phone call",
    "Speak to a verified ex-tenant of a specific address",
    "Open discussion, ask any question",
    "Immediate and flexible call scheduling",
    "Detailed example questions provided",
    "All caller details remain private",
    "Refund offered if call requests are unanswered",
  ];

  const earnDescriptors = [
    "Conduct your Review via a 20 min phone call",
    "Earn each time your Review is purchased and completed by phone call",
    "Earn multiple times from a single Review listing",
    "Flexible scheduling",
    "All call participant details remain private",
    "No effort is needed from you until your Review is purchased",
    "Add your listing in a couple of minutes",
  ];

  function generate(element) {
    return [0, 1, 2].map((value) =>
      React.cloneElement(element, {
        key: value,
      })
    );
  }

  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <>
        <div>
          <Helmet>
            <title>Pricing - Aussie Rent Reviews</title>
            <meta name="description" content="Current pricing and earn rates for reviews on the Aussie Rent Reviews platform."/>
          </Helmet>
        </div>
      <Navbar />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: { xxs: 20 },
          mb: { xxs: 20 },
        }}
      >
        <Box>
          <Typography
            variant="h2"
            sx={{ fontSize: "20px", mb: 3, color: "#800000", fontFamily:'Open Sans', fontWeight:'700' }}
          >
            Review Pricing
          </Typography>
        </Box>
        <Box maxWidth={700}>
          <Typography
            variant="h2"
            sx={{ fontSize: "37px", fontFamily:'Open Sans', fontWeight:'700' }}
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            One standard price
          </Typography>
        </Box>
        <Box maxWidth={600} mt={3}>
          <Typography
            variant="h2"
            sx={{ fontSize: "18px", fontFamily:'Open Sans', fontWeight:'700'}}
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            Connecting you to your desired address
          </Typography>
        </Box>
      </Box>

      <Grid style={{ background: "white" }} minHeight={"65vh"} pb={10}>
        <Container maxWidth="xl">
          <Grid item xxs={12}>
            <Grid container spacing={5} alignItems="flex-end">
              <Grid
                item
                xxs={12}
                md={6}
                direction="column"
                sx={{
                  alignItems: { xxs: "center", md: "end" },
                  justifyContent: { xxs: "center", md: "end" },
                }}
                textAlign="center"
                display="flex"
              >
                <Card style={{ maxWidth: 450 }}>
                  <CardHeader
                    title="Access a Review"
                    subheader="Pay-per-address"
                    titleTypographyProps={{
                      align: "center",
                      variant: "h3",
                      fontSize: 22,
                      marginTop: 2,
                      marginBottom: 1,
                      fontFamily:'Open Sans', fontWeight:'700',
                      color:"#ffffff"
                    }}
                    subheaderTypographyProps={{
                      align: "center",
                      variant: "body1",
                      fontSize: 16,
                      marginBottom: 1,
                      fontFamily:'Open Sans', fontWeight:'700',
                      color:"#000000"
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[400]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
               
                        variant="h4"
                        color="#009150"
                        sx={{ mt: 3, mb: 2, fontFamily:'Open Sans', fontWeight:'700', }}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                      >
                        $100 per address
                      </Typography>
                    </Box>

                    <Box
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="end"
                    >
                      {purchaseDescriptors.map((line) => (
                        <List dense={dense}>
                          <ListItem sx={{ mt: -2 }}>
                            <ListItemAvatar  >
                              <Avatar style={{ backgroundColor:'#009150', height:27,width:27}} >
                                <CheckBoxRoundedIcon sx={{fontSize:15}}/>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              style={{ fontWeight: 200 }}
                              primary={line}
                            />
                          </ListItem>
                        </List>
                      ))}
                    </Box>

                    <Typography
                      sx={{
                        mt: 5,
                        mr: 2,
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      *Terms and conditions apply
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={Link}
                      to="/"
                      fullWidth
                      variant="contained"
                      sx={{ mt: -2, fontWeight: "600" }}
                      style={{
                        borderRadius: "2px",
                        backgroundColor: "#009150", //"#FF7F50",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                      }}
                    >
                      Search
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid
                item
                // key={tier.title}
                xxs={12}
                md={6}
                direction="column"
                sx={{
                  alignItems: { xxs: "center", md: "start" },
                  justifyContent: { xxs: "center", md: "start" },
                }}
                textAlign="center"
                display="flex"
              >
                <Card style={{ maxWidth: 450 }}>
                  <CardHeader
                    title="Add your own Review"
                    subheader="Unlimited-earn-potential (Tenants only)"
                    
                    titleTypographyProps={{
                      align: "center",
                      variant: "h3",
                      fontSize: 22,
                      marginTop: 2,
                      marginBottom: 1,
                      fontFamily:'Open Sans', fontWeight:'700',
                      color:"#ffffff"
                    }}
                    subheaderTypographyProps={{
                      align: "center",
                      variant: "body2",
                      fontSize: 16,
                      marginBottom: 1,
                      fontFamily:'Open Sans', fontWeight:'700',
                      color:"#000000"
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[400]
                          : theme.palette.grey[700],
                    }}

                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        variant="h4"
                        color="#004953"
                        sx={{ mt: 3, mb: 1, fontFamily:'Open Sans', fontWeight:'700', }}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                      >
                        $100 earned per call
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="#004953"
                        sx={{  mb: 1, fontFamily:'Open Sans', fontWeight:'700', }}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                      >
                        (10% platform fee)
                      </Typography>
                    </Box>
                    <Box
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="end"
                    >
                      {earnDescriptors.map((line) => (
                        <List dense={dense}>
                          <ListItem sx={{ mt: -2 }}>
                            <ListItemAvatar>

                                <Avatar style={{ backgroundColor:'#004953', height:27,width:27}} >
                                <CheckBoxRoundedIcon sx={{fontSize:15}}/>

                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              style={{ fontWeight: 200 }}
                              primary={line}
                            />
                          </ListItem>
                        </List>
                      ))}
                    </Box>
                    <Typography
                      sx={{
                        mt: 5,
                        mr: 2,
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      *Terms and conditions apply
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {user?.result ? (
                      <Button
                        component={Link}
                        to="/add-review"
                        fullWidth
                        variant="contained"
                        sx={{ mt: -2, fontWeight: "600" }}
                        style={{
                          borderRadius: "2px",
                          backgroundColor: "#004953", //"#FF7F50",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                        }}
                      >
                        Register
                      </Button>
                    ) : (
                      <Button
                        component={Link}
                        to="/auth"
                        fullWidth
                        variant="contained"
                        sx={{ mt: -2, fontWeight: "600" }}
                        style={{
                          borderRadius: "2px",
                          backgroundColor: "#004953", //"#FF7F50",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                        }}
                      >
                        Register
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ mt: -5 }}
      />
      <StickyFooter />
    </>
  );
};

export default Pricing;
