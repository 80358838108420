import * as React from "react";
import { Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export default function StickyFooter() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "10vh",
      }}
    >
      <CssBaseline />

      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
          <Grid item xxs={12}>
            <Grid container justifyContent="flex-start">
            <Grid item xxs={3} sx={{mt:-1,mb:-1}}>
            <Box >
                  <Button
                    component={Link}
                    to="/about"
                    sx={{ color: "#000000" }}
                    style={{
                      fontWeight: "500",
                      textDecoration: "none",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    About
                  </Button>
                </Box>
                <Box >
                  <Button
                    component={Link}
                    to="/contact-us"
                    sx={{ color: "#000000" }}
                    style={{
                      fontWeight: "500",
                      textDecoration: "none",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    Contact Us
                  </Button>
                </Box>

              </Grid>

              <Grid item xxs={3} sx={{mt:-1,mb:-1}}>
                <Box >
                  <Button
                    component={Link}
                    to="/faq"
                    sx={{ color: "#000000" }}
                    style={{
                      fontWeight: "500",
                      textDecoration: "none",
                      fontSize: "12px",
                    }}
                  >
                    FAQ
                  </Button>
                </Box>
                <Box >
                  <Button
                    component={Link}
                    to="/pricing"
                    sx={{ color: "#000000" }}
                    style={{
                      fontWeight: "500",
                      textDecoration: "none",
                      fontSize: "12px",
                    }}
                  >
                    Pricing
                  </Button>
                </Box>

              </Grid>

              <Grid item xxs={3} sx={{mt:-1,mb:-1}}>
              <Box >
                  <Button
                    component={Link}
                    to="/auth"
                    sx={{ color: "#000000" }}
                    style={{
                      fontWeight: "500",
                      textDecoration: "none",
                      fontSize: "12px",
                    }}
                  >
                    Log In / Sign Up
                  </Button>
                </Box>
                <Box >
                  <Button
                    component={Link}
                    to="/marketplace-terms"
                    sx={{ color: "#000000" }}
                    style={{
                      fontWeight: "500",
                      textDecoration: "none",
                      fontSize: "12px",
                    }}
                  >
                    Marketplace T&C 
                  </Button>
                </Box>
              </Grid>
              <Grid item xxs={3} sx={{mt:-1,mb:-1}}>
              <Box >
                  <Button
                    component={Link}
                    to="/privacy-policy"
                    sx={{ color: "#000000" }}
                    style={{
                      fontWeight: "500",
                      textDecoration: "none",
                      fontSize: "12px",
                    }}
                  >
                    Privacy Policy
                  </Button>
                </Box>
                <Box >
                  <Button
                    component={Link}
                    to="/website-terms"
                    sx={{ color: "#000000" }}
                    style={{
                      fontWeight: "500",
                      textDecoration: "none",
                      fontSize: "12px",
                    }}
                  >
                    Website Terms
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
