import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";

import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";
import { forgotpasswordemail } from "../../actions/users";
import "./styles.css";

const ForgotPassword = () => {
  const [useremailData, setuseremailData] = useState({ email: "" });
  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(forgotpasswordemail(useremailData));
    setIsSubmit(true);
  };

  return (
    <>
      <Navbar />

      <Box
        component="main"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "left",
        }}
      >
        <Container>
          <Grid container spacing={3} mb={70}>
            <Grid item xxs={1}></Grid>

            <Grid item xxs={10}>
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box sx={{ mt: 60, mb: 2, ml:0.5 }}>
                  <Typography
                    variant="h2"
                    component="h2"
                    style={{ fontFamily: "Poppins", fontWeight: 500 }}
                    gutterBottom
                  >
                    Forgot Password
                  </Typography>
                </Box>

                <TextField
                  style={{ background: "white" }}
                  sx={{
                    "& .MuiFormLabel-root ": {
                      "font-size": "16px !important",
                    },
                    width: { xxs: "100%", sm: "100%", md: "70%" },
                  }}
                  InputProps={{
                    style: { fontSize: 16 },
                  }}
                  label="Enter your email address"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={useremailData.email}
                  onChange={(e) =>
                    setuseremailData({
                      ...useremailData,
                      email: e.target.value,
                    })
                  }
                />

                <Box sx={{ mt: 1, mb: 0 }}>
                  <Button
                    className="Button Submit"
                    variant="contained"
                    style={{ backgroundColor: "#484848" }}
                    size="medium"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </form>

              <Grid
              >
                {isSubmit ? (
                  <Box sx={{ mt: 5 }}>
                    <Typography
                      variant="body2"
                      color="#00000"
                    >
                      If this email exists in our database, a password reset
                      link will be sent!
                    </Typography>
                  </Box>
                ) : (
                  <Box></Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <StickyFooter />
    </>
  );
};

export default ForgotPassword;
