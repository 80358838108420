import React, { useState, useEffect, Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';


import { Stack } from '@mui/material';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import decode from 'jwt-decode';

import * as actionType from '../../constants/actionTypes.mjs';
import useStyles from './styles';

const drawerWidth = 240;

function NavbarHomePg(props) {

  const classes = useStyles()

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    navigate('/auth');

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);


  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center'  }}>


      <Box sx={{ mt: 2, mb: 2 }}>
        <a href="">
          <img src="/logoimgc.png" alt="logo" className={classes.logo} />
        </a>
      </Box>

      <Divider />
      <List>

      {user?.result ? (
                <Button component={Link} to="/add-review" variant="contained"
                    style={{
                              borderRadius: "2px",
                              backgroundColor: "#fda94f",//"#FF7F50",
                                fontFamily: 'Poppins', 
                                fontWeight:'Bold' 
                              }}
                  sx={{ color: "#ffffff", mb:1 }}
                >
                    Add Review
                </Button>

            ) : (
              <Button component={Link} to="/auth" variant="contained"
                  style={{
                            borderRadius: "2px",
                            backgroundColor: "#fda94f",//"#FF7F50",
                              fontFamily: 'Poppins', 
                              fontWeight:'Bold' 
                            }}
                sx={{ color: "#ffffff", mb:1 }}
              >
                  Add Review
              </Button>
 
            )}
      
        {user?.result ? (
          <>
            <ListItemButton component={Link} to="/user/saved-reviews" sx={{ textAlign: 'center' }}> <ListItemText primary="Bookmarks" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
            <ListItemButton component={Link} to="/user/dashboard" sx={{ textAlign: 'center' }}> <ListItemText primary="Dashboard" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
            <ListItemButton component={Link} to="/user/my-account" sx={{ textAlign: 'center' }}> <ListItemText primary="Account" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
            <ListItemButton component={Link} to="/faq" sx={{ textAlign: 'center' }}> <ListItemText primary="FAQ" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
            <ListItemButton component={Link} to="/" onClick={logout} sx={{ textAlign: 'center' }}> <ListItemText primary="Log Out" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
          </>
        ) : (
          <>
          <ListItemButton component={Link} to="/about" sx={{ textAlign: 'center' }}> <ListItemText primary="About" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
          <ListItemButton component={Link} to="/faq" sx={{ textAlign: 'center' }}> <ListItemText primary="FAQ" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
          <ListItemButton component={Link} to="/pricing" sx={{ textAlign: 'center' }}> <ListItemText primary="Pricing" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
          <ListItemButton component={Link} to="/contact-us" sx={{ textAlign: 'center' }}> <ListItemText primary="Contact Us" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
          <ListItemButton component={Link} to="/auth" sx={{ textAlign: 'center' }}> <ListItemText primary="Log In" primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: '500', }} /></ListItemButton>
          </>
        )}

      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;




  return (
    <Box sx={{ display: 'flex' }}>

      <AppBar component="nav" style={{ background: 'transparent', boxShadow: 'none', zIndex:1 }}>

        <Toolbar sx={{ justifyContent: "space-between" }}>

          <Stack direction="row" alignItems="right">

            <IconButton

              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'block', md:'none' }, color: 'darkgrey' }}
            >
              <MenuIcon />
            </IconButton>



            <Box sx={{ flexGrow: 1, display: { xxs: 'block' } ,mt:1 }}>
              <a href="/">
                <img src="/logoimgc.png" alt="logo" className={classes.logo} />
              </a>
            </Box>


          </Stack>



          <Box sx={{ display: { xxs: 'none', xs:'none', sm: 'none', md: 'block', lg: 'block' } }}>

    


            {user?.result ? (
              <>
                <Button component={Link} to="/user/saved-reviews" sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> Bookmarks</Button>
                <Button component={Link} to="/user/dashboard" sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> Dashboard</Button>
                <Button component={Link} to="/user/my-account" sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> Account</Button>
                <Button component={Link} to="/faq" sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> FAQ</Button>
                <Button component={Link} to="/" onClick={logout} sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> Log Out</Button>
                <Button component={Link} to="/add-review" variant="contained"
                  style={{
                    borderRadius: "2px",
                    backgroundColor: "#fda94f",//"#FF7F50",
                    fontFamily: 'Poppins',
                    fontWeight: 'bold'
                  }}
                  sx={{ color: "#ffffff" }}
                >
                  {" "}
                  Add Review
                </Button>

              </>
            ) : (
              <>
                <Button component={Link} to="/about" sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> About</Button>
                <Button component={Link} to="/faq" sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> FAQ</Button>
                <Button component={Link} to="/pricing" sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> Pricing</Button>
                <Button component={Link} to="/contact-us" sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> Contact Us</Button>

                <Button component={Link} to="/auth" sx={{ color: '#696969' }} style={{ fontFamily: 'Poppins', fontWeight: '500' }}> Log In</Button>
                
                <Button component={Link} to="/auth" variant="contained"
                  style={{
                    borderRadius: "2px",
                    backgroundColor: "#fda94f",//"#FF7F50",
                    fontFamily: 'Poppins',
                    fontWeight: 'bold'
                  }}
                  sx={{ color: "#ffffff" }}
                  
                >
                  {" "}
                  Add Review
                </Button>
              </>
            )}

          </Box>

        </Toolbar>
      </AppBar>

      <Box component="nav" >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xxs: 'block', xs:'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor:"#f9f9f9"  },
            
          }}
        >
          {drawer}
        </Drawer>
      </Box>

    </Box>
  );
}

NavbarHomePg.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default NavbarHomePg;
