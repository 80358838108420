import * as actionType from '../constants/actionTypes.mjs';

const bookmarkReducer = (state = { isLoading: true, bookmarks: [] }, action) => {

    switch (action.type) {

        case actionType.CREATE_BOOKMARK:
            return { ...state, bookmarks: [...state.bookmarks, action.payload] };

        case actionType.DELETE_BOOKMARK:
            return { ...state, bookmarks: state.bookmarks.filter((bookmark) => bookmark._id !== action.payload) };

        case actionType.FETCH_BY_USER_ID:
                return { ...state, bookmarks: action.payload.data };
        default:
            return state; 
    }

};

export default bookmarkReducer

