import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSelector,useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { postresetpassword } from "../../actions/users";
import "./styles.css";

import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

const ResetPassword = () => {
    const [userpasswordData, setuserpasswordData] = useState({
        password: "",
        password2: "",
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('');

    const dispatch = useDispatch();
    const location = useLocation();
    const locationPath = location.pathname;
    const id = locationPath.split("/")[3];
    const token = locationPath.split("/")[4];

    const message = useSelector((state) => state.auth.message);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(userpasswordData));
        setIsSubmit(true);
    };

    useEffect(() => {
        if (message==='Password has been reset, please log in!') {
            setuserpasswordData({ password: '', password2: ''})
            setErrorMessage('')
            setConfirmMessage(message)
        } else {
            setErrorMessage(message)
            setConfirmMessage('')
        }
        
      }, [message]);

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            dispatch(
                postresetpassword(id, token, { ...userpasswordData })
            );
        }
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};

        if (!values.password) {
            errors.password = "Password is required!";
        }

        if (!values.password2) {
            errors.password2 = "Password 2 is required!";
        }

        if (values.password && values.password2) {
            if (values.password.length < 6 || values.password.length > 18) {
                errors.password2 = "Password needs to be between 6 to 18 characters!";
            }
            if (values.password !== values.password2) {
                errors.password2 = "Passwords do not match!";
            }
        }

        return errors;
    };

    const [showPass, setShowPass] = useState(false);
    const [showPass2, setShowPass2] = useState(false);

    return (
        <>
            <Navbar />
            <Box
                component="main"
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "left",
                }}
            >
                <Container >
                    <Grid container spacing={3}>
                        <Grid item xxs={1}></Grid>

                        <Grid item xxs={10} sm={8} md={6}>
                            <form
                                autoComplete="off"
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <Box sx={{ mt: 20, mb: 5 }}>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        style={{ fontFamily: "Poppins", fontWeight: 500 }}
                                    >
                                        Reset your password
                                    </Typography>
                                </Box>

                                <TextField
                                    id="password_1"
                                    style={{ background: "white" }}
                                    sx={{
                                        mb: 1,
                                        "& .MuiFormLabel-root ": {
                                            "font-size": "16px !important",
                                        },
                                    }}
                                    label="Password"
                                    type={showPass ? "text" : "password"}
                                    name="pass"
                                    fullWidth
                                    InputProps={{
                                        style: { fontSize: 16 },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPass(!showPass)}>
                                                    {showPass ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={userpasswordData.password}
                                    onChange={(e) =>
                                        setuserpasswordData({
                                            ...userpasswordData,
                                            password: e.target.value,
                                        })
                                    }
                                />
                                <Typography variant="body2"  color="red">
                                    {formErrors.password}
                                </Typography>

                                <TextField
                                    id="password_2"
                                    style={{ background: "white" }}
                                    sx={{
                                        mt:2,
                                        "& .MuiFormLabel-root ": {
                                            "font-size": "16px !important",
                                        },
                                    }}
                                    label="Re-enter Password"
                                    type={showPass2 ? "text" : "password"}
                                    name="pass"
                                    fullWidth
                                    InputProps={{
                                        style: { fontSize: 16 },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPass2(!showPass2)}>
                                                    {showPass2 ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={userpasswordData.password2}
                                    onChange={(e) =>
                                        setuserpasswordData({
                                            ...userpasswordData,
                                            password2: e.target.value,
                                        })
                                    }
                                />
                                <Typography variant="body2"  color="red" mt={2} >
                                    {formErrors.password2}
                                    {errorMessage} 
                                </Typography>
                                <Typography variant="body2" color="black" mt={2} >
                                    {confirmMessage} 
                                </Typography>


                                <Box sx={{ mt: 3, mb: 3 }}>
                                    <Button
                                        className="Button Submit"
                                        variant="contained"
                                        style={{ backgroundColor: "#484848" }}
                                        size="large"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                    
                                </Box>
                            </form>
                            
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <StickyFooter />
        </>
    );
};

export default ResetPassword;
