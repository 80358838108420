import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({

  paper: {
    padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100px',
  },


  markerContainer: {
    position: 'absolute', transform: 'translate(-50%, -50%)', zIndex: 1, '&:hover': { zIndex: 2 },
  },




  addressText:{
    minWidth:200,
  },

  tenancyPeriodText:{
    minWidth:150,
  },

  propertyDetailsText:{
    minWidth:150,

  },

  
  starIcon:{
      
    position: 'relative', 
    zIndex:5,
    minWidth:5,
    minHeight:5,
    backgroundColor: "white",
    borderRadius: '16px', 
    

  },

  [theme.breakpoints.up("xxs")]: {

    mapContainer: {
      height: '87vh', width: '100%', position:'absolute',flexDirection: 'column',overflow: 'hidden',
    },


    clusterMobileContainer:{
      
      position: 'relative', 
      zIndex:2,
      minWidth:10
      

    },

    cardMobileContainer:{
      position: 'relative', 
      zIndex:2,
      minWidth:10,
      maxHeight:120,
    },

    imgCarouselMaps:{
      width: '100%', 
      height:120,

    },



  },


  [theme.breakpoints.up("xs")]: {


    imgCarouselMaps:{
      width: '100%', 
      height:130,

    },

    cardMobileContainer:{
      maxHeight:130
    },

  },
  [theme.breakpoints.up("xs4")]: {

    mapContainer: {
      height: '87vh', width: '100%', position:'absolute',flexDirection: 'column',overflow: 'hidden',
    },

    imgCarouselMaps:{
      width: '100%', 
      height:160,

    },
    cardMobileContainer:{
      maxHeight:160
    },

  },

  [theme.breakpoints.up("xs6")]: {

    mapContainer: {
      height: '87vh', width: '100%', position:'absolute',flexDirection: 'column',overflow: 'hidden',
    },

    imgCarouselMaps:{
      width: '100%', 
      height:200,

    },
    cardMobileContainer:{
      maxHeight:200
    },


  },


  

  [theme.breakpoints.up("sm")]: {

    clusterContainer:{
      position: 'fixed', 
      transform: 'translate(-50%, -50%)', zIndex: 15, '&:hover': { zIndex: 16 },
      width:'550px',
      height:'350px',
   
    },
    cardContainer: {
      position: 'fixed', 
      transform: 'translate(-50%, -50%)', zIndex: 15, '&:hover': { zIndex: 16 },
      width:'500px',
      height:'200px'
  
    },

    mapContainer: {
      height: '100vh', width: '100%', position:'absolute',flexDirection: 'column',overflow: 'hidden',
    },

  },


  [theme.breakpoints.up("md")]: {

    mapContainer: {
      height: '100vh', width: '100%', position:'absolute',flexDirection: 'column',
    },

  },


  [theme.breakpoints.up("lg")]: {

    mapContainer: {
      height: '87vh', width: '100%', position:'absolute',flexDirection: 'column',
    },

  },



  reviewIcon: {
    zIndex: 2,
  },


  clusterCount: {
    zIndex: 30,
  },

  pointer: {
    cursor: 'pointer',
  },
}));
