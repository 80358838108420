import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Navbar from "../../components/Navbar/Navbar";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import StickyFooter from "../../components/Footer/Footer";
import { fetchUserReviewerStatus,createStripeOnboarding,fetchStripeDashboard  } from "../../actions/users";

const MyAccount = (props) => {

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("profile"));
  const userID = user?.result?._id;

  useEffect(() => {
    dispatch(fetchUserReviewerStatus(userID));
  }, [userID]);

  const {userReviewerStatus} = useSelector((state) => state.userReviewerStatus);

  const scrollContainer = {
    maxHeight: "700px",
  };


  const handleStripeSubmit =  (e) => {
    e.preventDefault();
    dispatch(createStripeOnboarding())
    .then((res) => {
      window.location = res.payload.url;
    })
    .catch((e) => {
      console.error(e.error);
    });
  };

  const handleStripeDashboard =  (e) => {
    e.preventDefault();
    dispatch(fetchStripeDashboard())
    .then((res) => {
      window.location = res.payload.url;
    })
    .catch((e) => {
      console.error(e.error);
    });
  };
  

  return (
    <>
      {user?.result ? (
        <>
          <div>
          <Helmet>
            <title>My Account - Aussie Rent Reviews</title>
            <meta name="description" content="Manage your account settings for the Aussie Rent Reviews platform."/>
          </Helmet>
        </div>
          <Navbar />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
            }}
          >
            <Container maxWidth="xl" sx={{ mt: 12, mb: 7 }}>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12} xxs={12}>
                  <form autoComplete="off" noValidate {...props}>
                    <Card>
                      <CardHeader
                        title="Account Details"
                        sx={{ mb: -1, mt: 1, ml: 2}}
                      />
                      <Typography
                        variant="body1"
                        sx={{ ml: 4,mt:0.5 }}
                        color="text.secondary"
                      >
                        Not visible to the public
                      </Typography>

                      <Divider sx={{ mt: 2, mb: 1 }} />

                      <CardContent>
                        <Grid container spacing={3}>

                          <Grid item xxs={12}>
                            <TextField
                              fullWidth
                              label="Email Address"
                              value={user?.result?.email}
                              variant="outlined"
                              InputProps={{
                                style: { fontSize: 16 },
                              }}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "grey",
                                },
                                "& .MuiFormLabel-root ": {
                                  "font-size": "16px !important",
                                },
                                ml: 2,
                                pr: 4,
                              }}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider sx={{ mt: 1, mb: 2 }} />

                      <Typography
                        variant="body1"
                        sx={{ pl: 4 }}
                        color="text.secondary"

                        style={{ display: "flex" }}
                      >
                        Edit your phone number for each review in your dashboard.
                      </Typography>

                      <Divider sx={{ mt: 2, mb: 1 }} />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          p: 2,
                        }}
                      >
                        <Button
                          style={{ backgroundColor: "#484848 " }}
                          variant="contained"
                          component={Link}
                          to={`/user/reset-auth/${userID}`}
                        >
                          Change Password
                        </Button>
                      </Box>
                    </Card>
                  </form>

                  {userReviewerStatus.isReviewer? (
                    <>
                      {!userReviewerStatus.stripeOnboardingComplete? (
                        <Box mt={2}>
                          <form            
                            autoComplete="off"
                            onSubmit={handleStripeSubmit}
                            noValidate 
                          >
                            <Card>
                              <CardHeader
                                title="Create Stripe Account"
                                sx={{ mb: -1, mt: 1.5, ml: 2}}
                              />

                              <Divider sx={{ mt: 1, mb: 3 }} />
                              <Typography
                                variant="body1"
                                sx={{ ml: 4,mt:1.5, pr:2 }}
                                color="text.secondary"
                              >
                                Please create a Stripe account to enable payments and withdraws.
                              </Typography>
                              
                              <Typography
                                variant="body1"
                                sx={{ ml: 4,mt:1.5, pr:2 }}
                                color="text.secondary"
                              >
                                Creating an account is quick and easy (2 mins).
                              
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ ml: 4,mt:1.5, pr:2 }}
                                color="text.secondary"
                              >
                                If an answer has been pre-filled, you do not need to change it.
                              </Typography>
                              <Divider sx={{ mt: 3, mb: 0 }} />

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  p: 2,
                                }}
                              >
                                <Button
                                  style={{ backgroundColor: "#484848 " }}
                                  variant="contained"
                                  type="submit" 
                                >
                                  Create Account
                                </Button>
                              </Box>
                            </Card>
                          </form>
                        </Box>

                      ) : (
                        <Box mt={2}>
                          <form            
                            autoComplete="off"
                            onSubmit={handleStripeDashboard}
                            noValidate 
                          >
                            <Card>
                              <CardHeader
                                title="Stripe Payments Dashboard"
                                sx={{ mb: -2, mt: 1, ml: 2}}
                              />


                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  p: 2,
                                }}
                              >
                                <Button
                                  style={{ backgroundColor: "#484848 " }}
                                  variant="contained"
                                  type="submit" 
                                >
                                  View Payments
                                </Button>
                              </Box>
                            </Card>
                          </form>
                        </Box>
                      )}
                    </>
                  ) : (<></>)}

                </Grid>
              </Grid>
            </Container>
          </Box>

          <Container
            disableGutters
            maxWidth="sm"
            component="main"
            sx={{ pb: 5 }}
          />
          <StickyFooter />
        </>
      ) : (
        <>
        <Navbar />
            <AccessDenied/>
        <StickyFooter />
        </>
      )}
    </>
  );
};
export default MyAccount;
