import { Navigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Navbar from '../../components/Navbar/Navbar';
import Form from '../Form/Form';
import StickyFooter from "../../components/Footer/Footer";

const AddReview = () => {
    const user = JSON.parse(localStorage.getItem("profile"));

    return (

        <div className="add-review-switch">
            {user?.result ? (
                <>
                      <div>
                        <Helmet>
                        <title>Add Review - Aussie Rent Reviews</title>
                        <meta name="description" content="Fill in the form to start earning! Adding a Rent Review is super easy."/>
                        </Helmet>
                    </div>
                <Navbar/>
                    <Form/>
                <StickyFooter/>
                </>
            ) : (
                <Navigate to='/auth'  />
            )}
        </div>
    )

};

export default AddReview;