import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import Navbar from "../../components/Navbar/Navbar";
import { updateReview } from "../../actions/reviews";
import useStyles from "./styles";

const EditForm = () => {

const navigate = useNavigate(); 
        
  const classes = useStyles();

  const location = useLocation();
  const reviewId = location.state.reviewId;

  const review = useSelector((state) => (reviewId ? state.userReviews.reviews.find((message) => message._id === reviewId) : null));

  const [reviewData, setReviewData] = useState({ reviewAddress: review.reviewAddress, mobileNumber: review.mobileNumber, endDate: review.endDate, endMonth: review.endMonth, endYear: review.endYear, reviewNote: review.reviewNote, });

  
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);


  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    //add date conversion features
    const monthNamesIndex = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC", ];

    const endMonthNumeric = monthNamesIndex.findIndex(
      (item) => reviewData.endMonth === item
    );
    const endDt = new Date(parseInt(reviewData.endYear), endMonthNumeric, 10);

    setReviewData({ ...reviewData, endDate: endDt });
    setFormErrors(validate(reviewData));
    setIsSubmit(true);
  };



  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(updateReview(reviewId, { ...reviewData }));
      navigate("/user/dashboard");
    } else {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const validate = (values) => {
    const errors = {};

    if (!values.mobileNumber) {
      errors.mobileNumber = "Mobile number is required!";
    } else if (values.mobileNumber.substring(0, 1) === "0") {
      if (values.mobileNumber.length !== 10) {
        errors.mobileNumber = "This is not a valid mobile number!";
      }
    } else if (values.mobileNumber.substring(0, 2) === "61") {
      if (values.mobileNumber.length !== 11) {
        errors.mobileNumber = "This is not a valid mobile number!";
      }
    } else if (values.mobileNumber.substring(0, 2) === "+61") {
      if (values.mobileNumber.length !== 12) {
        errors.mobileNumber = "This is not a valid mobile number!";
      }
    } else {
      errors.mobileNumber = "This is not a valid mobile number!";
    }
    if (!values.endMonth) {
      errors.endMonth = "End month or best guess is required!";
    }
    if (!values.endYear) {
      errors.endYear = "End year or best guess is required!";
    } else {
      if (values.endDate <= values.startDate) {
        errors.dateLogic = "End date cannot be before your start date";
      }
    }

    if (!values.endYear) {
      errors.endYear = "End year or best guess is required!";
    } else if (isNaN(parseInt(values.endYear)) || values.endYear.length !== 4) {
      errors.endYear = "This is not a valid year!";
    } else if (values.endDate <= values.startDate) {
      errors.dateLogic = "End date cannot be before your start date";
    }

    return errors;
  };

  return (
    <>
      <Navbar />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "left",
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xxs={1}></Grid>

            <Grid item xxs={10}>
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box sx={{ mt: 20 }}>
                  <Typography
                    variant="h4" 
                    style={{ fontSize: 24, fontWeight:400 }}
                                        gutterBottom
                                      >
                    Edit your Review details
                  </Typography>
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="h5"
                    color="light-grey"
                    gutterBottom
                  >
                    {reviewData.reviewAddress}
                  </Typography>
                </Box>

                <Box sx={{ mt: 5, mb: 2 }}>
                  <Box sx={{ mb: -1 }}>
                    <Typography variant="h5" gutterBottom>
                      Mobile Number
                    </Typography>
                  </Box>

                  <TextField
                    style={{ background: "white" }}
                    label="Enter a valid phone number to receive calls"
                    className={classes.root}
                    variant="outlined"
                    color="secondary"
                    margin="normal"
                    fullWidth
                    value={reviewData.mobileNumber}
                    onChange={(e) =>
                      setReviewData({
                        ...reviewData,
                        mobileNumber: e.target.value,
                      })
                    }
                    InputProps={{
                      style: { fontSize: 16 },
                    }}
                    sx={{
                      width: { xxs: "100%", sm: 400 },
                    }}
                  />
                  <Typography variant="body2" color="red" >
                    {formErrors.mobileNumber}
                  </Typography>
                </Box>

                <Box sx={{ mt: 3, mb: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Stopped living there since or your expected future date
                  </Typography>
                </Box>

                <Grid container spacing={24}>
                  <Grid item xxs={9} sm={8} md={8} lg={3}>
                    <Typography variant="h5" gutterBottom>
                      Month
                    </Typography>

                    <Select
                      style={{ background: "white" }}
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={reviewData.endMonth}
                      fullWidth
                      label="Select Month"
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          endMonth: e.target.value,
                        })
                      }
                      sx={{ fontSize: "16px", width: { xxs: "100%", sm: 250 } }}
                    >
                      <MenuItem value={"JAN"}>January</MenuItem>
                      <MenuItem value={"FEB"}>February</MenuItem>
                      <MenuItem value={"MAR"}>March</MenuItem>
                      <MenuItem value={"APR"}>April</MenuItem>
                      <MenuItem value={"MAY"}>May</MenuItem>
                      <MenuItem value={"JUN"}>June</MenuItem>
                      <MenuItem value={"JUL"}>July</MenuItem>
                      <MenuItem value={"AUG"}>August</MenuItem>
                      <MenuItem value={"SEP"}>September</MenuItem>
                      <MenuItem value={"OCT"}>October</MenuItem>
                      <MenuItem value={"NOV"}>November</MenuItem>
                      <MenuItem value={"DEC"}>December</MenuItem>
                    </Select>

                    <Typography variant="body2" color="red" >
                      {formErrors.endMonth}
                    </Typography>
                  </Grid>

                  <Grid item xxs={9} sm={8} md={8} lg={3}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ mt: { xxs: -22, sm: -22, md: -22, lg: 0 } }}
                      gutterBottom
                    >
                      Year
                    </Typography>

                    <TextField
                      style={{ background: "white" }}
                      // label="select year"
                      variant="outlined"
                      color="secondary"
                      margin="normal"
                      className={classes.root}
                      value={reviewData.endYear}
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          endYear: e.target.value,
                        })
                      }
                      InputProps={{
                        style: { fontSize: 16 },
                      }}
                      sx={{
                        width: { xxs: "100%", sm: 250 },
                        mt: 0,
                      }}
                    />
                    <Typography variant="body2" color="red" >
                      {formErrors.endYear}
                    </Typography>
                  </Grid>

                  <Grid item xxs={12}>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ mt: -20 }}
                      gutterBottom
                    >
                      (Optional) Add notes about your living experience
                    </Typography>
                    <Typography variant="body2" sx={{  mt: 1 }}>
                      {" "}
                      As time passes, these notes will serve as a detailed
                      reminder for you
                    </Typography>
                    <Typography variant="body2" sx={{  mt: 1 }}>
                      {" "}
                      Notes will be shared with those who purchase your review,
                      allowing for a shorter, straight-to-the-point phone call{" "}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      (Expect less than 20 mins needed per call)
                    </Typography>
                    <TextField
                      style={{ background: "white" }}
                      variant="outlined"
                      color="secondary"
                      margin="normal"
                      className={classes.root}
                      value={reviewData.reviewNote}
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          reviewNote: e.target.value,
                        })
                      }
                      InputProps={{
                        style: { fontSize: 16 },
                      }}
                      multiline
                      rows={12}
                      sx={{
                        width: { xxs: "100%", sm: 600 },
                        mt: 3,
                      }}
                    />

                    <Box sx={{ mt: { xxs: 12, lg: 2 } }}>
                      <Button
                        className="Button Submit"
                        variant="contained"
                        style={{ backgroundColor: "#484848 " }}
                        size="large"
                        type="submit"
                        sx={{ mt: { xxs: -20, sm: -20, md: -20, lg: 0 } }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default EditForm;
