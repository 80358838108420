import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

const FAQ = () => {
  //hash nav for refund policy
  const [expandedRefundPolicy, setExpandedRefundPolicy] = useState(false);
  let isHashLinkRefundPolicy = false;

  const scrollWithOffsetRefundPolicy = (el) => {
    isHashLinkRefundPolicy = true;
    handleChangeRefundPolicy();
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const handleChangeRefundPolicy = () => {
    if (isHashLinkRefundPolicy) {
      setExpandedRefundPolicy(true);
      isHashLinkRefundPolicy = false;
    } else {
      if (expandedRefundPolicy) {
        setExpandedRefundPolicy(false);
      } else {
        setExpandedRefundPolicy(true);
      }
    }
  };

  //hash nav for call window
  const [expandedCallWindow, setExpandedCallWindow] = useState(false);
  let isHashLinkCallWindow = false;

  const scrollWithOffsetCallWindow = (el) => {
    isHashLinkCallWindow = true;
    handleChangeCallWindow();
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const handleChangeCallWindow = () => {
    if (isHashLinkCallWindow) {
      setExpandedCallWindow(true);
      isHashLinkCallWindow = false;
    } else {
      if (expandedCallWindow) {
        setExpandedCallWindow(false);
      } else {
        setExpandedCallWindow(true);
      }
    }
  };



  //hash nav for Reasonable Attempts
  const [expandedReasonableAttempts, setExpandedReasonableAttempts] =
    useState(false);
  let isHashLinkReasonableAttempts = false;

  const scrollWithOffsetReasonableAttempts = (el) => {
    isHashLinkReasonableAttempts = true;
    handleChangeReasonableAttempts();
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const handleChangeReasonableAttempts = () => {
    if (isHashLinkReasonableAttempts) {
      setExpandedReasonableAttempts(true);
      isHashLinkReasonableAttempts = false;
    } else {
      if (expandedReasonableAttempts) {
        setExpandedReasonableAttempts(false);
      } else {
        setExpandedReasonableAttempts(true);
      }
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Frequently Asked Questions - Aussie Rent Reviews</title>
          <meta
            name="description"
            content="Please refer to our documentation on how to maximise the benefits of our Rent Reviews."
          />
        </Helmet>
      </div>

      <Navbar />

      <Grid container xxs={12}>
        <Container
          maxWidth={false}
          sx={{
            backgroundImage: "url(/katsia-jazwinska-RIb19_YSUX0-unsplash.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "30vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 11,
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
              display="flex"
            >

              <Container>
                <Typography
                  variant="h2"
                  style={{
                    color: "white",
                    fontWeight: "600",
                    fontFamily: "Open Sans",
                  }}
                  sx={{
                    width: "20%",
                    fontSize: { xxs: "40px", sm: "50px" },
                    mt: 0,
                    mb: 0,
                    ml: { lg: 3 },
                  }}
                >
                  Frequently Asked Questions
                </Typography>
              </Container>
            </Grid>
          </Box>
        </Container>
      </Grid>

      <Grid container xxs={12} sx={{ minHeight: "70vh" }}>
        <Grid item xxs={12} sm={12}>
          <Container>
            <Grid container spacing={3} alignItems="center">
              <Box sx={{ ml: 4, mr: 1, mt: 8 }}>
                <Typography variant="h5" sx={{ mt: 1, ml: 0 }}>
                  General
                </Typography>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">What is a Rent Review?</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2">
                      A Rent Review is a personalised evaluation of a property
                      given by someone who has previously rented and lived
                      there.
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 2 }}>
                      It provides an in-depth look at the unique aspects of
                      living at an address long-term, which cannot be discovered
                      from the open home inspections you attend.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      Rent Reviews are conducted in an interview format through a
                      20 minute private conference call. The Buyer of a Rent Review calls
                      the Reviewer and can ask questions about things that
                      matter most.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                      Information from a prior-tenant is a gold mine that has
                      never been accessible until now.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">Who is this for?</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2">
                      Aussie Rent Reviews acts as a marketplace for Rent
                      Reviews.
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 3 }}>
                      A) Buyers of Reviews (Buyers)
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, ml: 2 }}>
                      Potential tenants or property buyers who desire more
                      nuianced information before committing to a property lease
                      or property purchase.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 3 }}>
                      B) Reviewers
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, ml: 2, mb: 2 }}>
                      Tenants who have moved out or are soon to move out and
                      would like to earn money for sharing their wisdom about
                      their living experience in the specified address.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      Is this a free service?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2">
                      You may search all Rent Review listings for free but
                      access is a paid service.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                      Our experience has taught us that Reviewers need to be
                      incentivised to provide information that is genuinely
                      helpful and of high quality. This is why a paid model is
                      necessary.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  id="call-window"
                  expanded={expandedCallWindow}
                  onChange={handleChangeCallWindow}
                  sx={{ mt: 2, pl: 1, pr: 1 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      What is the call window?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Any attempt to call a Reviewer must be made by 5pm of the subsequent two (2) days after the date of purchase.
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Example 1:
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      A Rent Review purchased on a Tuesday irrespective of the time, will expire at 5pm on the following Thursday.
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Example 2:
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      A Rent Review purchased on a Saturday irrespective of the time, will expire at 5pm on the following Monday.
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Weekends are treated the same as weekdays.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      How does Aussie Rent Reviews treat my personal
                      information?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2">
                      With utmost care, respect and security.
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 2 }}>
                      When you sign up, buy, or list a Rent Review, the
                      information you provide such as your name, email, and
                      phone number will remain confidential and not be shared
                      with other users. This data will never be provided to
                      third parties for sale.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      Additional personal information will be required if you’re
                      creating a Rent Review. This is for our verification
                      process to validate the legitimacy of your tenancy. This
                      will also never be shared amongst users or sold to third
                      parties.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      While engaging in a Rent Review (Conference Call), you
                      have full discretion over what information you disclose.
                      We strongly discourage sharing any personal or sensitive
                      information during a Rent Review. Only discuss what is
                      relevant to living in the property.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                      For more information see our
                    </Typography>
                    <Link href="/privacy-policy" variant="body2">
                      Privacy Policy
                    </Link>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      Can the public see my personal details?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      When you list a Rent Review, other users can view the
                      address and approximate dates of your tenancy. All other
                      details are anonymised including your name and phone
                      number.
                    </Typography>
                    <Link href="/reviews/search/" variant="body2">
                      View listings
                    </Link>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      A Rent Review (Call Conference) does not reveal any
                      additional information about either party apart from what
                      you choose to say during the call.
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                      Phone numbers of both parties are kept private during the
                      conference. All other personal details are kept
                      confidential for both the Buyer and the Reviewer.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      Where can I get more information?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                      You may check our legal documentation for more information
                    </Typography>
                    <Box sx={{mb:1}}>
                      <Link href="/marketplace-terms" variant="body2">
                        Marketplace Terms & Conditions
                      </Link>
                    </Box>
                    <Box sx={{mb:1}}>
                      <Link href="/privacy-policy" variant="body2">
                        Privacy Policy
                      </Link>
                    </Box>
                    <Box sx={{mb:2}}>
                      <Link href="/website-terms" variant="body2">
                        Website Terms & Conditions
                      </Link>
                    </Box>
                    <Typography variant="body2" sx={{ mb: 2 }}>
    
                      {"Or contact us at "}
                      <a href = "mailto:enquiries@aussierentreviews.com.au">
                          enquiries@aussierentreviews.com.au
                        </a> 
                
                    </Typography>

                  </AccordionDetails>
                </Accordion>

                <Typography variant="h5" sx={{ mt: 4, ml: 0 }}>
                  Buying a Rent Review
                </Typography>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      How do I buy or access a Rent Review?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Simply search and find an address that is relevant to you.
                      If the exact address you are looking for does not exist,
                      you may benefit from accessing a neigbouring address in
                      the same building or street.
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 2 }}>
                      Once you have found a review that interests you, create an
                      account and purchase through Stripe, our payment
                      processing partner.
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                      Your purchased Rent Reviews will appear in your dashboard
                      under the “Orders (Buyer)” section.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      When can I schedule a call?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      You may immediately call the Reviewer after you purchase
                      your Rent Review.
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Our platform provides a flexible call system such that if
                      the Reviewer is unable to pick up your call, you have
                      another 4 call credits to attempt at your own pace and
                      discretion.
                    </Typography>
                    <List
                      sx={{
                        listStyleType: "disc",
                        pl: 4,
                        mt: -2,
                        mb: 1,
                        "& .MuiListItem-root": {
                          display: "list-item",
                        },
                      }}
                    >
                      <ListItem>
                        <Typography variant="body2">
                          Available call hours are 9am - 5pm, 7 days a week.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          Minimum 30 minutes required between each call attempt.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          {"Call Credits will expire at the end of the "}

                          <HashLink
                            smooth
                            to="/faq/#call-window"
                            scroll={(el) => scrollWithOffsetCallWindow(el)}
                          >
                            {"Call Window"}
                          </HashLink>
                        </Typography>
                      </ListItem>
                    </List>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      If you are unable to reach the Reviewer after 5 call
                      attempts, you will be eligible for a refund.
                    </Typography>

                    <HashLink
                      smooth
                      to="/faq/#refund-policy"
                      scroll={(el) => scrollWithOffsetRefundPolicy(el)}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          color: "#1976d2",
                          textDecoration: "underline",
                        }}
                      >
                        See Refund Policy
                      </Typography>
                    </HashLink>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      How do I begin a call for my Rent Review?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <List
                      sx={{
                        listStyleType: "disc",
                        pl: 4,
                        mt: -2,
                        mb: 1,
                        "& .MuiListItem-root": {
                          display: "list-item",
                        },
                      }}
                    >
                      <ListItem>
                        <Typography variant="body2">
                          View your order in your dashboard under the “Orders
                          (Buyer)” section.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          Click “Start Call” to attempt a call anytime during
                          9am - 5pm, 7 days a week. You have 5 call attempts
                          (credits).
                        </Typography>
                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">
                          When starting a call, you will immediately receive a
                          conference call request from Aussie Rent Reviews to
                          your given mobile number.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          You will be prompted to hold while we attempt to
                          connect with the Reviewer.
                        </Typography>
                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">
                          When the call is answered by the Reviewer, the Rent
                          Review begins with a duration of 20 minutes.
                        </Typography>
                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">
                          If all your call credits are expensed before expiry
                          without an answer from the Reviewer, you are entitled
                          to a refund.
                        </Typography>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  id="refund-policy"
                  expanded={expandedRefundPolicy}
                  onChange={handleChangeRefundPolicy}
                  sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      What is the refund policy?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      If a Buyer of a Rent Review cannot access their review due
                      to the unavailability of the Reviewer, then they are
                      entitled to a refund less any payment processing fees.
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Each purchase allows 5 call attempts (call credits) to be
                      utilised within the Call Window.
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {
                        "If the Call Credit balance is depleted without succesfully connecting through to a Conference Call, and depletion occurs within the "
                      }

                      <HashLink
                        smooth
                        to="/faq/#call-window"
                        scroll={(el) => scrollWithOffsetCallWindow(el)}
                      >
                        Call Window
                      </HashLink>

                      {", then the Buyer is entitled to this refund."}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2, mt: 2 }}>
                      We do not issue refunds relating to any inaccuracies
                      provided during a Rent Review or Conference Call.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      For more information about our refund policy:  
                    </Typography>

                    <Box sx={{ mb: 1}}>
                      <Link href="/marketplace-terms" variant="body2">
                        Marketplace Terms & Conditions
                      </Link>
                    </Box>

                    <HashLink
                      smooth
                      to="/faq/#reasonable-attempts"
                      scroll={(el) => scrollWithOffsetReasonableAttempts(el)}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 4,
                   
                          color: "#1976d2",
                          textDecoration: "underline",
                        }}
                      >
                        Call & Conferencing Guidelines
                      </Typography>
                    </HashLink>


                  </AccordionDetails>
                </Accordion>
         


                <Typography variant="h5" sx={{ mt: 4, ml: 0 }}>
                  Listing a Rent Review
                </Typography>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      Is this real money that I can earn?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Yes, this is a marketplace for Rent Reviews.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      All transactions are denominated in $AUD and handled
                      securely by our payment processing partner, Stripe.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Your earnings can be withdrawn from Stripe to your
                      denonimated bank account.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      How much can I expect to earn?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{mb:2}}>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Total earn rates will vary from property to property.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Reviewing in demand rental properties can earn
                      over AUD$250 including platform fees.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      *Earn rates and platform fees per Rent Review are subject
                      to change
                    </Typography>
                    <Link href="/pricing" variant="body2">
                      See current rates
                    </Link>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      Am I eligible to list a Rent Review?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      We accept Rent Reviews from legitimate tenants.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      If you can prove that you were on the tenancy agreement
                      for an address, then you are eligible to give a review for
                      that address.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      How do I list my Rent Review?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <List
                      sx={{
                        listStyleType: "disc",
                        pl: 4,
                        mt: -2,
                        mb: 1,
                        "& .MuiListItem-root": {
                          display: "list-item",
                        },
                      }}
                    >
                      <ListItem>
                        <Typography variant="body2" >
                          Create an account.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2" >
                          Click the "ADD REVIEW" button in the navigation menu.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2" >
                          Fill out the address and verification details for your
                          Rent Review. Expect around 5 minutes for this process.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2" >
                          We will verify your details in 1 - 3 days.
                        </Typography>
                      </ListItem>                  <ListItem>
                        <Typography variant="body2" >
                          Once approved, create
                          an account with Stripe to allow the receipt of payments.
                        </Typography>
                      </ListItem>

                      <ListItem>
                        <Typography variant="body2" >
                          Nothing else is required from you until someone
                          purchases your Rent Review.
                        </Typography>
                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">
                          That's it. Simple.
                        </Typography>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      Someone bought my Rent Review, what next?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      You will be notified by email when someone purchases your
                      Rent Review.
                    </Typography>

                     <Typography variant="body2" sx={{ mb: 2 }}>
                      {"Generally expect a call request on the same day, between 9am-5pm. The Buyer can attempt calls until the end of the "}
                      <HashLink
                        smooth
                        to="/faq/#call-window"
                        scroll={(el) => scrollWithOffsetCallWindow(el)}
                      >
                        Call Window
                      </HashLink>

                      {"."}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      The call request will come through an Aussie Rent Reviews
                      number, prompting you to start the Rent Review - Conference Call.
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      If you have 20 minutes to spare, stay on the phone to
                      complete the Rent Review and to release your
                      payment.
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      In the event that you are busy, simply hang up before the
                      Rent Review Conference begins or decline the initial call request from Aussie
                      Rent Reviews.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      You may also action a return call in your dashboard if you
                      have missed a call request.
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {"The Buyer will have a total of 5 call attempts within the "}
                      <HashLink
                        smooth
                        to="/faq/#call-window"
                        scroll={(el) => scrollWithOffsetCallWindow(el)}
                      >
                        Call Window
                      </HashLink>
                      {". Your Rent Review must be completed in this time or the Buyer will be entitled to a refund of their initial payment."}
 
                    </Typography>

                    <HashLink
                      smooth
                      to="/faq/#refund-policy"
                      scroll={(el) => scrollWithOffsetRefundPolicy(el)}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 3,
                          color: "#1976d2",
                          textDecoration: "underline",
                        }}
                      >
                        See Refund Policy
                      </Typography>
                    </HashLink>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      There is no penalty for missing or ignoring call requests, thus allowing you the freedom to conduct your Rent Reviews at any time and at any frequency.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7">
                      How do I conduct and complete a Rent Review?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Upon receiving a call request relating to your Rent Review
                      listing,
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Follow the call prompts and the Rent Review conference
                      will begin shortly after, involving yourself and the Buyer
                      of your Rent Review.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Be helpful and honest in answering the Buyer's questions
                      about your living experience in the disclosed address.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Do not share any personal or sensitive information about
                      yourself or share anything unrelated to your renting or
                      living experience.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      The Conference Call automatically ends in 20 minutes or
                      if the Buyer hangs up earlier.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Upon conference completion, your Rent Review order will be
                      marked as complete and payment to your Stripe account will be released.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      View and manage your orders in your dashboard.
                    </Typography>

                    <HashLink
                      smooth
                      to="/faq/#reasonable-attempts"
                      scroll={(el) => scrollWithOffsetReasonableAttempts(el)}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 4,
                   
                          color: "#1976d2",
                          textDecoration: "underline",
                        }}
                      >
                        See Call & Conferencing Guidelines
                      </Typography>
                    </HashLink>


                  </AccordionDetails>
                </Accordion>


                <Typography variant="h5" sx={{ mt: 4, ml: 0 }}>
                  Call & Conferencing Policies
                </Typography>

                <Accordion
                  id="reasonable-attempts"
                  expanded={expandedReasonableAttempts}
                  onChange={handleChangeReasonableAttempts}
                  sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7" id="test">
                      Call Connection Guidelines 
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                  <List
                      sx={{
                        listStyleType: "disc",
                        pl: 4,
                        mt: -2,
                        mb: 1,
                        "& .MuiListItem-root": {
                          display: "list-item",
                        },
                      }}
                    >
                      <ListItem>
                        <Typography variant="body2">
                          All call and conference requests are hosted by our platform, Aussie Rent Reviews.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          Aussie Rent Reviews will initiate a call to both parties and provide the necessary verbal prompts and instructions to connect or reject a Conference Call.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          There is no obligation to answer a call request from Aussie Rent Reviews if the time does not suit. 
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          All Conference Calls will be recorded.
                        </Typography>
                      </ListItem>


                      <ListItem>
                        <Typography variant="body2">
                          Your call and conferencing actions can be managed via the user dashboard.
                        </Typography>
                      </ListItem>

                    </List>
                    <Box sx={{mb:1}}>
                      <Link href="/marketplace-terms" variant="body2">
                        See Marketplace Terms & Conditions
                      </Link>
                    </Box>
                    <Box sx={{mb:2}}>
                      <Link href="/privacy-policy" variant="body2">
                        See Privacy Policy
                      </Link>
                    </Box>
                  </AccordionDetails>
                </Accordion>


                <Accordion
                  id="reasonable-attempts"
                  expanded={expandedReasonableAttempts}
                  onChange={handleChangeReasonableAttempts}
                  sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7" id="test">
                      Participant Behaviour
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                  <List
                      sx={{
                        listStyleType: "disc",
                        pl: 4,
                        mt: -2,
                        mb: 1,
                        "& .MuiListItem-root": {
                          display: "list-item",
                        },
                      }}
                    >
                      <ListItem>
                        <Typography variant="body2">
                          Users must never ask for or share personal or private information in a Conference Call. 
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          All information shared in a Rent Review must remain confidential.
                        </Typography>
                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">
                          The call system must never be used for any purpose other than in a genuine attempt to complete a Rent Review.
                        </Typography>
                      </ListItem>

                      <ListItem>
                        <Typography variant="body2">
                          Users must be respectful towards each other in their conversation. No abuse will be tolerated.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          Breach of these policies will impact any refund eligiblity and may warrant cancellation of your account.
                        </Typography>
                      </ListItem>
                    </List>
                      
                    <Box sx={{mb:1}}>
                      <Link href="/marketplace-terms" variant="body2">
                        See Marketplace Terms & Conditions
                      </Link>
                    </Box>
                    <Box sx={{mb:2}}>
                      <Link href="/privacy-policy" variant="body2">
                        See Privacy Policy
                      </Link>
                    </Box>

                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2, pl: 1, pr: 1, mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h7" id="test">
                      Technical Issues - Disconnections
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Unforeseen conference disconnections will not impact call credits or the remaining time balance left for a Rent Review. 
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      The call can immediately be re-attempted assuming the time is still within 9am - 5pm, and the Call Window has not yet expired.
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Continued disconnections may force a suspension of the order. 
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {"For additional assistance, please "} 
                        <a href = "mailto:enquiries@aussierentreviews.com.au">
                          email our support team
                        </a> 
                       { "."}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
    
                      {"Or contact us at "}
                      <a href = "mailto:enquiries@aussierentreviews.com.au">
                          enquiries@aussierentreviews.com.au
                        </a> 
              
                    </Typography>


                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    
      <StickyFooter />
    </>
  );
};

export default FAQ;
