import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography
} from '@mui/material';
import { Link } from "react-router-dom";

export const PostCard = (props) => {
  const {
    category,
    cover,
    publishedAt,
    readTime,
    shortDescription,
    title,
    ...other
  } = props;

  const formattedPublishedAt = format(publishedAt, 'MMM d, yyyy');

  return (
    <Card {...other}>
      <CardMedia
        component={Link}
        to="/blog/find-your-perfect-home"
        image={cover}
        sx={{ height: 280 }}
      />
      <CardContent>
        <Box sx={{ mb: 2 }}>
          <Chip label={category} />
        </Box>
        <Typography
          color="text.primary"
          component={Link}
          
          to="/blog/find-your-perfect-home"
          variant="h5"
        >
          {title}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{
            height: 48,
            mt: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2
          }}
          variant="body1"
        >
          {shortDescription}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          flexWrap="wrap"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <Typography variant="subtitle2">
              {formattedPublishedAt}
            </Typography>
          </Stack>

        </Stack>
      </CardContent>
    </Card>
  );
};

PostCard.propTypes = {
  category: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  publishedAt: PropTypes.number.isRequired,
  shortDescription: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
