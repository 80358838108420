import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Divider from "@mui/material/Divider";
import { useSelector,useDispatch } from "react-redux";
import { postknownresetpassword } from "../../actions/users";
import "./styles.css";

import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

const ResetKnownPassword = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  const userID = user?.result?._id;

  const [userpasswordData, setuserpasswordData] = useState({
    oldpassword: "",
    password: "",
    password2: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');

  const dispatch = useDispatch();

  const message = useSelector((state) => state.auth.message);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(userpasswordData));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (message==='Your password has been reset') {
        setuserpasswordData({ password: '', password2: '',oldpassword: "",})
        setErrorMessage('')
        setConfirmMessage(message)
    } else {
        setErrorMessage(message)
        setConfirmMessage('')
    }
  }, [message]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(
        postknownresetpassword(userID, { ...userpasswordData })
      );
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};

    // add old password error section
    if (!values.password) {
      errors.password = "Password is required!";
    }

    if (!values.password2) {
      errors.password2 = "Password 2 is required!";
    }

    if (values.password && values.password2) {
      if (values.password.length < 6 || values.password.length > 18) {
        errors.password2 = "Password needs to be between 6 to 18 characters!";
      }
      if (values.password !== values.password2) {
        errors.password2 = "Passwords do not match!";
      }
    }
    return errors;
  };

  const [showPass, setShowPass] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  return (
    <>
      <Navbar />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "left",
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xxs={1}></Grid>

            <Grid item xxs={10} sm={8} md={6}>
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box sx={{ mt: 30, mb: 5 }}>
                  <Typography
                    variant="h2"
                    component="h2"
                    style={{ fontFamily: "Poppins", fontWeight: 500 }}
                  >
                    Reset your password
                  </Typography>
                </Box>

                <TextField
                  id="password_old"
                  style={{ background: "white" }}
                  sx={{
                    mb: 1,
                    "& .MuiFormLabel-root ": {
                      "font-size": "16px !important",
                    },
                  }}
                  label="Old Password"
                  type={showPass ? "text" : "password"}
                  name="pass"
                  fullWidth
                  InputProps={{
                    style: { fontSize: 16 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPass(!showPass)}>
                          {showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={userpasswordData.oldpassword}
                  onChange={(e) =>
                    setuserpasswordData({
                      ...userpasswordData,
                      oldpassword: e.target.value,
                    })
                  }
                />
                <Typography variant="body2" color="red" sx={{ mb: 1 }} >
                  {formErrors.oldpassword}
                  {errorMessage}
                </Typography>

                <Divider />

                <TextField
                  id="password_1"
                  style={{ background: "white" }}
                  sx={{
                    mt: 2,
                    "& .MuiFormLabel-root ": {
                      "font-size": "16px !important",
                    },
                  }}
                  label="New Password"
                  type={showPass1 ? "text" : "password"}
                  name="pass"
                  fullWidth
                  InputProps={{
                    style: { fontSize: 16 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPass1(!showPass1)}>
                          {showPass1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={userpasswordData.password}
                  onChange={(e) =>
                    setuserpasswordData({
                      ...userpasswordData,
                      password: e.target.value,
                    })
                  }
                />
                <Typography variant="body2" color="red">
                  {formErrors.password}
                </Typography>

                <TextField
                  id="password_2"
                  style={{ background: "white" }}
                  sx={{
                    mt: 2,
                    "& .MuiFormLabel-root ": {
                      "font-size": "16px !important",
                    },
                  }}
                  label="Re-enter New Password"
                  type={showPass2 ? "text" : "password"}
                  name="pass"
                  fullWidth
                  InputProps={{
                    style: { fontSize: 16 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPass2(!showPass2)}>
                          {showPass2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={userpasswordData.password2}
                  onChange={(e) =>
                    setuserpasswordData({
                      ...userpasswordData,
                      password2: e.target.value,
                    })
                  }
                />
                <Typography variant="body2" color="red" >
                  {formErrors.password2}
                </Typography>

                <Typography variant="body2" color="red" sx={{ mt: 1 }} >
                  {errorMessage}
                </Typography>
                <Typography variant="body2" color="black" sx={{ mt: 1 }} >
                  {confirmMessage}
                </Typography>

                <Box sx={{ mt: 3, mb: 50 }}>
                  <Button
                    className="Button Submit"
                    variant="contained"
                    style={{ backgroundColor: "#484848" }}
                    size="large"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <StickyFooter />
    </>
  );
};

export default ResetKnownPassword;
