import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Carousel from "react-material-ui-carousel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircleIcon from "@mui/icons-material/Circle";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import PerfectScrollbar from "react-perfect-scrollbar";



import {
  fetchUserBookmarks,
  createUserBookmark,
  deleteUserBookmark,
} from "../../actions/users";

import mapStyles from "./mapStyles";
import useStyles from "./resultStyles.js";
import imgproperty from "../../assets/images/no-property-photo-available.png";

const Map = ({ places, filteredPlaces, setChildClicked, childClicked, bounds, setBounds, }) => {



  const user = JSON.parse(localStorage.getItem("profile"));
  const userID = user?.result?._id;

  const {
    bookmarks: { bookmarks },
  } = useSelector((state) => state);

  const mobileView = useMediaQuery("(max-width:600px)");

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mapRef = useRef();
  const [zoom, setZoom] = useState(14);

  const Marker = ({ children }) => children;

  let defaultCoordinates;

  if (filteredPlaces) {
    if (filteredPlaces.length > 0) {
      defaultCoordinates = {
        lat: filteredPlaces[0].reviewAddressCoordinate.lat,
        lng: filteredPlaces[0].reviewAddressCoordinate.lng,
      };
    } else {
      defaultCoordinates = { lat: -33.8688, lng: 151.2093 };
    }
  }

  const points = places.map((review) => ({
    type: "Feature",
    properties: {
      cluster: false,
      reviewId: review._id,
      reviewLeaf: review,
    },
    geometry: {
      type: "Point",
      coordinates: [
        review.reviewAddressCoordinate.lng,
        review.reviewAddressCoordinate.lat,
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 5, maxZoom: 30 },
  });

    //cluster organisers for mobile view

    const [resultIsCluster, setResultIsCluster] = useState(null);
    const [mobileCluster, setMobileCluster] = useState(null);
  
    const handleCircleIconClick = async (isCluster, cluster) => {
      // e.stopPropagation();
      // e.preventDefault();
      setResultIsCluster(isCluster);

      if (isCluster) {
        setMobileCluster(supercluster.getLeaves(cluster.id))
      } else {
        setMobileCluster(cluster);
      }
      ;
 
  
    };



  //--- user bookmarks
  const [isAnyBookMarkChnged, setIsAnyBookMarkChnged] = useState(false);
  const [bookmarkShapeData, setBookmarkShapeData] = useState([]);

  useEffect(() => {
    //load up independant shape index to avoid re-rendering
    if (bookmarkShapeData.length == 0 && !isAnyBookMarkChnged) {
      setBookmarkShapeData(bookmarks);
    }
  }, [bookmarks]);

  const handleBookmarkDelete = async (e, revId) => {
    e.stopPropagation();

    let arr = bookmarkShapeData;
    let deletePosIndx = null;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].reviewId === revId) {
        deletePosIndx = i;
        arr.splice(deletePosIndx, 1);
        break;
      }
    }
    setBookmarkShapeData(arr);

    dispatch(deleteUserBookmark(userID, revId));
    setIsAnyBookMarkChnged(true);
  };

  const handleBookmarkSubmit = async (e, revId) => {
    e.stopPropagation();

    let arr = bookmarkShapeData;

    arr.push({ userId: userID, reviewId: revId });

    setBookmarkShapeData(arr);

    dispatch(createUserBookmark({ userId: userID, reviewId: revId }));
    setIsAnyBookMarkChnged(true);
  };

  const handleBookmarkSubmitNonLoggedIn = async (e) => {
    e.stopPropagation();
    navigate("/auth");
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchUserBookmarks(userID));
    }
  }, []);

  //scroll propagation handlers
  const handleScrollClick = async (e) => {
    e.stopPropagation();
  };

  const handleTouch = async (e) => {
    e.stopPropagation();
  };



  return (
    <div className={classes.mapContainer}>
      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_API_PUBLIC_KEY,
        }}
        defaultCenter={defaultCoordinates}
        center={defaultCoordinates}
        defaultZoom={15}
        margin={[50, 50, 50, 50]}
        yesIWantToUseGoogleMapApiInternals
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          styles: mapStyles,
          disableDoubleClickZoom: true,
          gestureHandling: "greedy",
          clickableIcons: false,
        }}

        onChildClick={(child) => ( setChildClicked(child) ) }

        onClick={(e) => setChildClicked(null) }
        // onClick={(event) => handleMapCloseClick(event)}
        
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);

          if (
            JSON.stringify([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]) === JSON.stringify([0, 0, 0, 0])
          ) {
            //default australia bounds
            setBounds([
              113.338953078, -43.6345972634, 153.569469029, -10.6681857235,
            ]);
          } else {
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;

          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            const reviewCluster = supercluster.getLeaves(cluster.id);

            return (
              <Marker
                className={classes.markerContainer}
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                style={{ zIndex: 1 }}
              >
                <>
                  <Box 
                    style={{cursor: "pointer",}}
                    onClick={() => handleCircleIconClick(true, cluster)}
                    >
                    <CircleIcon
                      style={{
                        color: "#F28C28",
                        stroke: "white",
                        strokeWidth: 1.5,
                        fontSize: 25,
                        zIndex: 10,
                        position: "relative",
                        
                      }}
                    />

                    <Box
                      sx={{
                        backgroundColor: "black",
                        mt: -1.2,
                        ml: 1.6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: 16,
                        width: 14,
                      }}
                      style={{
                        zIndex: 11,
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 8.5,
                          color: "white",
                          fontWeight: 600,
                        }}
                      >
                        {reviewCluster.length}
                      </Typography>
                    </Box>

                  </Box>

                  {`cluster-${cluster.id}` === childClicked ? (
                    <>
                      {!mobileView ? (
                        <>
                          <Card
                            className={classes.clusterContainer}
                            sx={{ mt: -27, borderRadius: "16px" }}
                            onClick={handleScrollClick}
                          >
                            <CardContent>
                              <Typography variant={"h5"} fontSize={18}>
                                {reviewCluster.length} found at this location
                              </Typography>
                            </CardContent>

                            <PerfectScrollbar
                              style={{ maxHeight: 290 }}
                              option={{
                                wheelPropagation: false,
                              }}
                              onTouchMove={handleTouch}
                              onTouchStart={handleTouch}
                              onTouchEnd={handleTouch}
                            >
                              {reviewCluster.map((revLeaf) => (
                                <Card
                                  sx={{
                                    mt: 0,
                                    pl: 1,
                                    pr: 2,
                                    pb: 0.5,
                                    cursor: "pointer",
                                    // borderRadius: '16px',
                                  }}
                                  onClick={() =>
                                    window.open(
                                      `/reviews/address/${revLeaf.properties.reviewLeaf.addressAlias}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <Grid container>
                                    <Grid item xxs={6}>
                                      {revLeaf.properties.reviewLeaf
                                        .propertyDetails.photos[0] ? (
                                        <>
                                          <Carousel
                                            autoPlay={false}
                                            cycleNavigation={true}
                                            indicators={false}
                                            height={200}
                                          >
                                            {revLeaf.properties.reviewLeaf.propertyDetails.photos.map(
                                              (img, i) => (
                                                <img
                                                  key={i}
                                                  src={img}
                                                  // className="imgCarouselMaps"
                                                  className={
                                                    classes.imgCarouselMaps
                                                  }
                                                />
                                              )
                                            )}
                                          </Carousel>
                                        </>
                                      ) : (
                                        <img
                                          src={imgproperty}
                                          className={classes.imgCarouselMaps}
                                        />
                                      )}
                                    </Grid>

                                    <Grid item xxs={6}>
                                      <Grid container>
                                        <Grid item xxs={9}>
                                          <CardContent
                                            sx={{ flex: "1 0 auto" }}
                                          >
                                            <Typography
                                              className={classes.addressText}
                                              variant="h5"
                                              sx={{
                                                fontSize: { xxs: 14, xs: 16 },
                                                fontWeight: 500,
                                              }}
                                            >
                                              {
                                                revLeaf.properties.reviewLeaf
                                                  .reviewAddress
                                              }
                                            </Typography>
                                          </CardContent>
                                        </Grid>

                                        <Grid
                                          item
                                          xxs={3}
                                          container
                                          direction="column"
                                          alignItems="end"
                                          justify="end"
                                          sx={{ pt: 1.5, pr: 2 }}
                                        >
                                          {isAnyBookMarkChnged ? (
                                            Object.keys(bookmarkShapeData).some(
                                              function (k) {
                                                return (
                                                  bookmarkShapeData[k]
                                                    .reviewId ===
                                                  revLeaf.properties.reviewLeaf
                                                    ._id
                                                );
                                              }
                                            ) ? (
                                              <IconButton
                                                onClick={(e) =>
                                                  handleBookmarkDelete(
                                                    e,
                                                    revLeaf.properties
                                                      .reviewLeaf._id
                                                  )
                                                }
                                              >
                                                <StarRoundedIcon
                                                  sx={{
                                                    fontSize: 30,
                                                    color: "#FDDA0D",
                                                  }}
                                                />
                                              </IconButton>
                                            ) : (
                                              <>
                                                {user?.result ? (
                                                  <IconButton
                                                    onClick={(e) =>
                                                      handleBookmarkSubmit(
                                                        e,
                                                        revLeaf.properties
                                                          .reviewLeaf._id
                                                      )
                                                    }
                                                    sx={{
                                                      "& :hover": {
                                                        color: "#FDDA0D",
                                                      },
                                                    }}
                                                  >
                                                    <StarBorderRoundedIcon
                                                      sx={{
                                                        fontSize: 30,
                                                        color: "#D0D0D0",
                                                      }}
                                                    />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton
                                                    onClick={(e) =>
                                                      handleBookmarkSubmitNonLoggedIn(
                                                        e
                                                      )
                                                    }
                                                  >
                                                    <StarBorderRoundedIcon
                                                      sx={{
                                                        fontSize: 30,
                                                        color: "#D0D0D0",
                                                      }}
                                                    />
                                                  </IconButton>
                                                )}
                                              </>
                                            )
                                          ) : Object.keys(bookmarks).some(
                                              function (k) {
                                                return (
                                                  bookmarks[k].reviewId ===
                                                  revLeaf.properties.reviewLeaf
                                                    ._id
                                                );
                                              }
                                            ) ? (
                                            <IconButton
                                              onClick={(e) =>
                                                handleBookmarkDelete(
                                                  e,
                                                  revLeaf.properties.reviewLeaf
                                                    ._id
                                                )
                                              }
                                            >
                                              <StarRoundedIcon
                                                sx={{
                                                  fontSize: 30,
                                                  color: "#FDDA0D",
                                                }}
                                              />
                                            </IconButton>
                                          ) : (
                                            <>
                                              {user?.result ? (
                                                <IconButton
                                                  onClick={(e) =>
                                                    handleBookmarkSubmit(
                                                      e,
                                                      revLeaf.properties
                                                        .reviewLeaf._id
                                                    )
                                                  }
                                                  sx={{
                                                    "& :hover": {
                                                      color: "#FDDA0D",
                                                    },
                                                  }}
                                                >
                                                  <StarBorderRoundedIcon
                                                    sx={{
                                                      fontSize: 30,
                                                      color: "#D0D0D0",
                                                    }}
                                                  />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  onClick={(e) =>
                                                    handleBookmarkSubmitNonLoggedIn(
                                                      e
                                                    )
                                                  }
                                                >
                                                  <StarBorderRoundedIcon
                                                    sx={{
                                                      fontSize: 30,
                                                      color: "#D0D0D0",
                                                    }}
                                                  />
                                                </IconButton>
                                              )}
                                            </>
                                          )}
                                        </Grid>

                                        <Grid item xxs={12}>
                                          <CardContent
                                            sx={{ flex: "1 0 auto" }}
                                          >
                                            <Typography
                                              variant="body1"
                                              sx={{
                                                fontSize: { xxs: 13, xs: 15 },
                                                fontWeight: 400,
                                                mt: -3,
                                                pb: 2,
                                              }}
                                            >
                                              {
                                                revLeaf.properties.reviewLeaf
                                                  .startMonth
                                              }{" "}
                                              {
                                                revLeaf.properties.reviewLeaf
                                                  .startYear
                                              }{" "}
                                              -{" "}
                                              {
                                                revLeaf.properties.reviewLeaf
                                                  .endMonth
                                              }{" "}
                                              {
                                                revLeaf.properties.reviewLeaf
                                                  .endYear
                                              }{" "}
                                            </Typography>
                                          </CardContent>
                                        </Grid>

                                        <Grid item xxs={12}>
                                          <CardContent
                                            sx={{
                                              flex: "1 0 auto",
                                              fontSize: { xxs: 12, xs: 15 },
                                              mt: 0,
                                            }}
                                          >
                                            {revLeaf.properties.reviewLeaf
                                              .propertyDetails.bedrooms ? (
                                              <>
                                                <BedOutlinedIcon
                                                  sx={{
                                                    color: "#606060",
                                                    fontSize: {
                                                      xxs: 16,
                                                      xs: "large",
                                                    },
                                                  }}
                                                />{" "}
                                                {
                                                  revLeaf.properties.reviewLeaf
                                                    .propertyDetails.bedrooms
                                                }{" "}
                                              </>
                                            ) : (
                                              <> </>
                                            )}

                                            {revLeaf.properties.reviewLeaf
                                              .propertyDetails.bathrooms ? (
                                              <>
                                                <BathtubOutlinedIcon
                                                  sx={{
                                                    color: "#606060",
                                                    fontSize: {
                                                      xxs: 14,
                                                      xs: 16,
                                                    },
                                                    mb: 0.3,
                                                  }}
                                                />{" "}
                                                {
                                                  revLeaf.properties.reviewLeaf
                                                    .propertyDetails.bathrooms
                                                }{" "}
                                              </>
                                            ) : (
                                              <> </>
                                            )}

                                            {revLeaf.properties.reviewLeaf
                                              .propertyDetails.carSpaces ? (
                                              <>
                                                <DirectionsCarFilledOutlinedIcon
                                                  sx={{
                                                    color: "#606060",
                                                    fontSize: {
                                                      xxs: 15,
                                                      xs: 17,
                                                    },
                                                    mb: 0.3,
                                                  }}
                                                />{" "}
                                                {
                                                  revLeaf.properties.reviewLeaf
                                                    .propertyDetails.carSpaces
                                                }{" "}
                                              </>
                                            ) : (
                                              <> </>
                                            )}
                                          </CardContent>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Card>
                              ))}
                            </PerfectScrollbar>
                          </Card>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </Marker>
            );
          } else {
            return (
              <Marker
                key={`review-${cluster.properties.reviewId}`}
                lat={latitude}
                lng={longitude}
              >
                <>
                  <Box 
                  style={{cursor: "pointer",}}
                  onClick={() => handleCircleIconClick(false, cluster)}
                  >
                    <CircleIcon
                      className={classes.reviewIcon}
                      style={{
                        color: "#F28C28",
                        stroke: "white",
                        strokeWidth: 1.5,
                        fontSize: 25,
                        
                      }}
                    />
                  </Box>


                  {`review-${cluster.properties.reviewId}` === childClicked ? (
                    <>
                      {!mobileView ? (
                        <Card
                          className={classes.cardContainer}
                          sx={{
                            cursor: "pointer",
                            mt: -17,
                            borderRadius: "16px",
                          }}
                          onClick={() =>
                            window.open(
                              `/reviews/address/${cluster.properties.reviewLeaf.addressAlias}`,
                              "_blank"
                            )
                          }
                        >
                          <Grid container>
                            <Grid item xxs={6}>
                              {cluster.properties.reviewLeaf.propertyDetails
                                .photos[0] ? (
                                <>
                                  <Carousel
                                    autoPlay={false}
                                    cycleNavigation={true}
                                    height={200}
                                    indicators={false}
                                  >
                                    {cluster.properties.reviewLeaf.propertyDetails.photos.map(
                                      (img, i) => (
                                        <img
                                          key={i}
                                          src={img}
                                          className={classes.imgCarouselMaps}
                                        />
                                      )
                                    )}
                                  </Carousel>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={imgproperty}
                                    className={classes.imgCarouselMaps}
                                  />
                                </>
                              )}
                            </Grid>

                            <Grid item xxs={6}>
                              <Grid container>
                                <Grid item xxs={9}>
                                  <CardContent sx={{ flex: "1 0 auto" }}>
                                    <Typography
                                      className={classes.addressText}
                                      variant="h5"
                                      sx={{
                                        fontSize: { xxs: 14, xs: 16 },
                                        fontWeight: 500,
                                        mt: 1,
                                      }}
                                    >
                                      {
                                        cluster.properties.reviewLeaf
                                          .reviewAddress
                                      }
                                    </Typography>
                                  </CardContent>
                                </Grid>

                                <Grid
                                  item
                                  xxs={3}
                                  container
                                  direction="column"
                                  alignItems="end"
                                  justify="end"
                                  sx={{ pt: 1.5, pr: 2 }}
                                >
                                  {isAnyBookMarkChnged ? (
                                    Object.keys(bookmarkShapeData).some(
                                      function (k) {
                                        return (
                                          bookmarkShapeData[k].reviewId ===
                                          cluster.properties.reviewLeaf._id
                                        );
                                      }
                                    ) ? (
                                      <IconButton
                                        onClick={(e) =>
                                          handleBookmarkDelete(
                                            e,
                                            cluster.properties.reviewLeaf._id
                                          )
                                        }
                                      >
                                        <StarRoundedIcon
                                          sx={{
                                            fontSize: 30,
                                            color: "#FDDA0D",
                                          }}
                                        />
                                      </IconButton>
                                    ) : (
                                      <>
                                        {user?.result ? (
                                          <IconButton
                                            onClick={(e) =>
                                              handleBookmarkSubmit(
                                                e,
                                                cluster.properties.reviewLeaf
                                                  ._id
                                              )
                                            }
                                            sx={{
                                              "& :hover": {
                                                color: "#FDDA0D",
                                              },
                                            }}
                                          >
                                            <StarBorderRoundedIcon
                                              sx={{
                                                fontSize: 30,
                                                color: "#D0D0D0",
                                              }}
                                            />
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            onClick={(e) =>
                                              handleBookmarkSubmitNonLoggedIn(e)
                                            }
                                          >
                                            <StarBorderRoundedIcon
                                              sx={{
                                                fontSize: 30,
                                                color: "#D0D0D0",
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </>
                                    )
                                  ) : Object.keys(bookmarks).some(function (k) {
                                      return (
                                        bookmarks[k].reviewId ===
                                        cluster.properties.reviewLeaf._id
                                      );
                                    }) ? (
                                    <IconButton
                                      onClick={(e) =>
                                        handleBookmarkDelete(
                                          e,
                                          cluster.properties.reviewLeaf._id
                                        )
                                      }
                                    >
                                      <StarRoundedIcon
                                        sx={{
                                          fontSize: 30,
                                          color: "#FDDA0D",
                                        }}
                                      />
                                    </IconButton>
                                  ) : (
                                    <>
                                      {user?.result ? (
                                        <IconButton
                                          onClick={(e) =>
                                            handleBookmarkSubmit(
                                              e,
                                              cluster.properties.reviewLeaf._id
                                            )
                                          }
                                          sx={{
                                            "& :hover": {
                                              color: "#FDDA0D",
                                            },
                                          }}
                                        >
                                          <StarBorderRoundedIcon
                                            sx={{
                                              fontSize: 30,
                                              color: "#D0D0D0",
                                            }}
                                          />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          onClick={(e) =>
                                            handleBookmarkSubmitNonLoggedIn(e)
                                          }
                                        >
                                          <StarBorderRoundedIcon
                                            sx={{
                                              fontSize: 30,
                                              color: "#D0D0D0",
                                            }}
                                          />
                                        </IconButton>
                                      )}
                                    </>
                                  )}
                                </Grid>

                                <Grid item xxs={12}>
                                  <CardContent sx={{ flex: "1 0 auto" }}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: { xxs: 13, xs: 15 },
                                        fontWeight: 400,
                                        mt: -4,
                                        pb: 2,
                                      }}
                                    >
                                      {cluster.properties.reviewLeaf.startMonth}{" "}
                                      {cluster.properties.reviewLeaf.startYear}{" "}
                                      - {cluster.properties.reviewLeaf.endMonth}{" "}
                                      {cluster.properties.reviewLeaf.endYear}{" "}
                                    </Typography>
                                  </CardContent>
                                </Grid>

                                <Grid item xxs={12}>
                                  <CardContent
                                    sx={{
                                      flex: "1 0 auto",
                                      fontSize: 15,
                                      mt: 0,
                                    }}
                                  >
                                    {cluster.properties.reviewLeaf
                                      .propertyDetails.bedrooms ? (
                                      <>
                                        <BedOutlinedIcon
                                          sx={{
                                            color: "#606060",
                                            fontSize: {
                                              xxs: 16,
                                              xs: "large",
                                            },
                                          }}
                                        />{" "}
                                        {
                                          cluster.properties.reviewLeaf
                                            .propertyDetails.bedrooms
                                        }{" "}
                                      </>
                                    ) : (
                                      <> </>
                                    )}

                                    {cluster.properties.reviewLeaf
                                      .propertyDetails.bathrooms ? (
                                      <>
                                        <BathtubOutlinedIcon
                                          sx={{
                                            color: "#606060",
                                            fontSize: { xxs: 14, xs: 16 },
                                            mb: 0.3,
                                          }}
                                        />{" "}
                                        {
                                          cluster.properties.reviewLeaf
                                            .propertyDetails.bathrooms
                                        }{" "}
                                      </>
                                    ) : (
                                      <> </>
                                    )}

                                    {cluster.properties.reviewLeaf
                                      .propertyDetails.carSpaces ? (
                                      <>
                                        <DirectionsCarFilledOutlinedIcon
                                          sx={{
                                            color: "#606060",
                                            fontSize: { xxs: 15, xs: 17 },
                                            mb: 0.3,
                                          }}
                                        />{" "}
                                        {
                                          cluster.properties.reviewLeaf
                                            .propertyDetails.carSpaces
                                        }{" "}
                                      </>
                                    ) : (
                                      <> </>
                                    )}
                                  </CardContent>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </Marker>
            );
          }
        })}
      </GoogleMapReact>

      {/* mobile bottom view settings */}

      <Box position="absolute" bottom={0} zIndex={1}>

        {childClicked && mobileView ? (
          <>
            {resultIsCluster && mobileCluster ? (
              
              <Card
                className={classes.clusterMobileContainer}

                sx={{ 
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={handleScrollClick}
              >
                <CardContent>
                  <Typography variant={"h5"} fontSize={18}>
                    {mobileCluster.length} found at this location
                  </Typography>
                </CardContent>

                <PerfectScrollbar
                  style={{ maxHeight: 250 }}
                  option={{
                    wheelPropagation: false,
                  }}
                  onTouchMove={handleTouch}
                  onTouchStart={handleTouch}
                  onTouchEnd={handleTouch}
                >
                  {mobileCluster.map((revLeaf) => (
                    <Card
                      className={classes.cardMobileContainer}
                      sx={{
                        pt: 0.5,
                        pl: 1,
                        pr: 2,
                        pb: 0.5,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open(
                          `/reviews/address/${revLeaf.properties.reviewLeaf.addressAlias}`,
                          "_blank"
                        )
                      }
                    >
                      <Grid container>
                        <Grid item xxs={6}>
                          {revLeaf.properties.reviewLeaf.propertyDetails
                            .photos[0] ? (
                            <>
      
                              <Carousel
                                autoPlay={false}
                                cycleNavigation={true}
                                indicators={false}
                                // height={200}
                                sx={{height:{xxs:120,xs:130,xs4:160,xs6:200}}}
                              >
                                {revLeaf.properties.reviewLeaf.propertyDetails.photos.map(
                                  (img, i) => (
                                    <img
                                      key={i}
                                      src={img}
                                      // className="imgCarouselMaps"
                                      className={classes.imgCarouselMaps}
                                    />
                                  )
                                )}
                              </Carousel>
                    
                            </>
                          ) : (
                            <img src={imgproperty} className={classes.imgCarouselMaps} />
                          )}
                        </Grid>

                        <Grid item xxs={6}>
                        
                          <Grid container>     

                            <Grid item xxs={9}>
                              <CardContent sx={{ flex: "1 0 auto" }}>
                                <Typography
                                  className={classes.addressText}
                                  variant="h5"
                                  sx={{
                                    fontSize: { xxs: 11, xs: 13, xs4:15 },
                                    fontWeight: 500,
                                    mt:{xxs:-1,xs:-1,xs6:0.5},
                                  }}
                                >
                                  {revLeaf.properties.reviewLeaf.reviewAddress}
                                </Typography>
                              </CardContent>
                            </Grid>


                            <Grid
                              item
                              xxs={3}
                              container
                              direction="column"
                              alignItems="end"
                              justify="end"
                              sx={{ pt: {xxs:0,xs:0,xs4:0,xs6:1}, mr: 0 , pl:2}}
                              
                              
                            >
                              <Box className={classes.starIcon} >
                              {isAnyBookMarkChnged ? (
                                Object.keys(bookmarkShapeData).some(function (k) {
                                  return (
                                    bookmarkShapeData[k].reviewId ===
                                    revLeaf.properties.reviewLeaf._id
                                  );
                                }) ? (
                                  <IconButton
                                    onClick={(e) =>
                                      handleBookmarkDelete(
                                        e,
                                        revLeaf.properties.reviewLeaf._id
                                      )
                                    }
                                  >
                                    <StarRoundedIcon
                                      sx={{
                                        fontSize: {xxs:25,xs:30},
                                        color: "#FDDA0D",
                                      }}
                                    />
                                  </IconButton>
                                ) : (
                                  <>
                                    {user?.result ? (
                                      <IconButton
                                        onClick={(e) =>
                                          handleBookmarkSubmit(
                                            e,
                                            revLeaf.properties.reviewLeaf._id
                                          )
                                        }
                                        sx={{
                                          "& :hover": {
                                            color: "#FDDA0D",
                                          },
                                        }}
                                      >
                                        <StarBorderRoundedIcon
                                          sx={{
                                            fontSize: {xxs:25,xs:30},
                                            color: "#D0D0D0",
                                          }}
                                        />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={(e) =>
                                          handleBookmarkSubmitNonLoggedIn(e)
                                        }
                                      >
                                        <StarBorderRoundedIcon
                                          sx={{
                                            fontSize: {xxs:25,xs:30},
                                            color: "#D0D0D0",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </>
                                )
                              ) : Object.keys(bookmarks).some(function (k) {
                                  return (
                                    bookmarks[k].reviewId ===
                                    revLeaf.properties.reviewLeaf._id
                                  );
                                }) ? (
                                <IconButton
                                  onClick={(e) =>
                                    handleBookmarkDelete(
                                      e,
                                      revLeaf.properties.reviewLeaf._id
                                    )
                                  }
                                >
                                  <StarRoundedIcon
                                    sx={{
                                      fontSize: {xxs:25,xs:30},
                                      color: "#FDDA0D",
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <>
                                  {user?.result ? (
                                    <IconButton
                                      onClick={(e) =>
                                        handleBookmarkSubmit(
                                          e,
                                          revLeaf.properties.reviewLeaf._id
                                        )
                                      }
                                      sx={{
                                        "& :hover": {
                                          color: "#FDDA0D",
                                        },
                                      }}
                                    >
                                      <StarBorderRoundedIcon
                                        sx={{
                                          fontSize: {xxs:25,xs:30},
                                          color: "#D0D0D0",
                                        }}
                                      />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={(e) =>
                                        handleBookmarkSubmitNonLoggedIn(e)
                                      }
                                    >
                                      <StarBorderRoundedIcon
                                        sx={{
                                          fontSize: {xxs:25,xs:30},
                                          color: "#D0D0D0",
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                </>
                              )}
                              </Box>
                            </Grid>

                            <Grid item xxs={12}>
                              <CardContent sx={{ flex: "1 0 auto" }}>
                                <Typography
                                  className={classes.tenancyPeriodText}
                                  variant="body1"
                                  sx={{
                                    fontSize: { xxs: 11, xs: 13, xs4:15  },
                                    fontWeight: 400,
                                    mt: -4,
                                    // pb: 4,
                                  }}
                                >
                                  {revLeaf.properties.reviewLeaf.startMonth}{" "}
                                  {revLeaf.properties.reviewLeaf.startYear} -{" "}
                                  {revLeaf.properties.reviewLeaf.endMonth}{" "}
                                  {revLeaf.properties.reviewLeaf.endYear}{" "}
                                </Typography>
                              </CardContent>
                            </Grid>

                            <Grid item xxs={12}>
                              <CardContent
                                sx={{
                                  flex: "1 0 auto",
                                  fontSize: { xxs: 10, xs: 11, xs4:13  },
                                  mt: {xxs:-4,xs:-4,xs6:-2}
                                }}
                                className={classes.propertyDetailsText}
                              >
                                {revLeaf.properties.reviewLeaf.propertyDetails
                                  .bedrooms ? (
                                  <>
                                    <BedOutlinedIcon

                                      sx={{
                                        color: "#606060",
                                        fontSize: {
                                          xxs: 14,
                                          xs: "large",
                                        },
                                      }}
                                    />{" "}
                                    {
                                      revLeaf.properties.reviewLeaf
                                        .propertyDetails.bedrooms
                                    }{" "}

                                  </>
                                ) : (
                                  <> </>
                                )}

                                {revLeaf.properties.reviewLeaf.propertyDetails
                                  .bathrooms ? (
                                  <>
                                    <BathtubOutlinedIcon
                                      sx={{
                                        color: "#606060",
                                        fontSize: {
                                          xxs: 12,
                                          xs: 16,
                                        },
                                        mb: 0.3,
                                      }}
                                    />{" "}
                                    {
                                      revLeaf.properties.reviewLeaf
                                        .propertyDetails.bathrooms
                                    }{" "}
                                  </>
                                ) : (
                                  <> </>
                                )}

                                {revLeaf.properties.reviewLeaf.propertyDetails
                                  .carSpaces ? (
                                  <>
                                    <DirectionsCarFilledOutlinedIcon
                                      sx={{
                                        color: "#606060",
                                        fontSize: {
                                          xxs: 13,
                                          xs: 17,
                                        },
                                        mb: 0.3,
                                      }}
                                    />{" "}
                                    {
                                      revLeaf.properties.reviewLeaf
                                        .propertyDetails.carSpaces
                                    }{" "}
                                  </>
                                ) : (
                                  <> </>
                                )}
                              </CardContent>
                            </Grid>

                          </Grid>
                          


                          
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </PerfectScrollbar>
              </Card>
            ) : (

              <>
            
              {!resultIsCluster && mobileCluster ? (

                <Card
                  className={classes.cardMobileContainer}
                  sx={{ cursor: "pointer"}}
  
                  onClick={() =>
                    window.open(
                      `/reviews/address/${mobileCluster.properties.reviewLeaf.addressAlias}`,
                      "_blank"
                    )
                  }
                >
                  <Grid container>
                    <Grid item xxs={6}>
                      {mobileCluster.properties.reviewLeaf.propertyDetails
                        .photos[0] ? (
                        <>
                          <Carousel
                            autoPlay={false}
                            cycleNavigation={true}
                            indicators={false}
                            sx={{height:{xxs:120,xs:130,xs4:160,xs6:200}}}
                            // height={500}
                          >
                            {mobileCluster.properties.reviewLeaf.propertyDetails.photos.map(
                              (img, i) => (
                                <img
                                  key={i}
                                  src={img}
                                  className={classes.imgCarouselMaps}
                                />
                              )
                            )}
                          </Carousel>
                        </>
                      ) : (
                        <>
                          <img src={imgproperty} className={classes.imgCarouselMaps} />
                        </>
                      )}
                    </Grid>

                    <Grid item xxs={6}>
                      <Grid container>
                        <Grid item xxs={9}>
                          <CardContent sx={{ flex: "1 0 auto" }}>
                            <Typography
                            className={classes.addressText}
                              variant="h5"
                              sx={{
                                fontSize: { xxs: 11, xs: 13, xs4:15 },
                                fontWeight: 500,
                                mt:0,
                              }}
                            >
                              {mobileCluster.properties.reviewLeaf.reviewAddress}
                            </Typography>
                          </CardContent>
                        </Grid>

                        <Grid
                          item
                          xxs={3}
                          container
                          direction="column"
                          alignItems="end"
                          justify="end"
                          sx={{ pt: {xxs:0,xs:0,xs4:0,xs6:1}, pr: 2 }}
                        >
                          <Box className={classes.starIcon} >
                          {isAnyBookMarkChnged ? (
                            Object.keys(bookmarkShapeData).some(function (k) {
                              return (
                                bookmarkShapeData[k].reviewId ===
                                mobileCluster.properties.reviewLeaf._id
                              );
                            }) ? (
                              <IconButton
                                onClick={(e) =>
                                  handleBookmarkDelete(
                                    e,
                                    mobileCluster.properties.reviewLeaf._id
                                  )
                                }
                              >
                                <StarRoundedIcon
                                  sx={{
                                    fontSize: {xxs:25,xs:30},
                                    color: "#FDDA0D",
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <>
                                {user?.result ? (
                                  <IconButton
                                    onClick={(e) =>
                                      handleBookmarkSubmit(
                                        e,
                                        mobileCluster.properties.reviewLeaf._id
                                      )
                                    }
                                    sx={{
                                      "& :hover": {
                                        color: "#FDDA0D",
                                      },
                                    }}
                                  >
                                    <StarBorderRoundedIcon
                                      sx={{
                                        fontSize: {xxs:25,xs:30},
                                        color: "#D0D0D0",
                                      }}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={(e) =>
                                      handleBookmarkSubmitNonLoggedIn(e)
                                    }
                                  >
                                    <StarBorderRoundedIcon
                                      sx={{
                                        fontSize: {xxs:25,xs:30},
                                        color: "#D0D0D0",
                                      }}
                                    />
                                  </IconButton>
                                )}
                              </>
                            )
                          ) : Object.keys(bookmarks).some(function (k) {
                              return (
                                bookmarks[k].reviewId ===
                                mobileCluster.properties.reviewLeaf._id
                              );
                            }) ? (
                            <IconButton
                              onClick={(e) =>
                                handleBookmarkDelete(
                                  e,
                                  mobileCluster.properties.reviewLeaf._id
                                )
                              }
                            >
                              <StarRoundedIcon
                                sx={{
                                  fontSize: {xxs:25,xs:30},
                                  color: "#FDDA0D",
                                }}
                              />
                            </IconButton>
                          ) : (
                            <>
                              {user?.result ? (
                                <IconButton
                                  onClick={(e) =>
                                    handleBookmarkSubmit(
                                      e,
                                      mobileCluster.properties.reviewLeaf._id
                                    )
                                  }
                                  sx={{
                                    "& :hover": {
                                      color: "#FDDA0D",
                                    },
                                  }}
                                >
                                  <StarBorderRoundedIcon
                                    sx={{
                                      fontSize: {xxs:25,xs:30},
                                      color: "#D0D0D0",
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={(e) =>
                                    handleBookmarkSubmitNonLoggedIn(e)
                                  }
                                >
                                  <StarBorderRoundedIcon
                                    sx={{
                                      fontSize: {xxs:25,xs:30},
                                      color: "#D0D0D0",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </>
                          )}
                          </Box>
                        </Grid>

                        <Grid item xxs={12}>
                          <CardContent sx={{ flex: "1 0 auto" }}>
                            <Typography
                            className={classes.tenancyPeriodText}
                              variant="body1"
                              sx={{
                                fontSize: { xxs: 11, xs: 13, xs4:15  },
                                fontWeight: 400,
                                mt: -3,
            
                              }}
                            >
                              {mobileCluster.properties.reviewLeaf.startMonth}{" "}
                              {mobileCluster.properties.reviewLeaf.startYear} -{" "}
                              {mobileCluster.properties.reviewLeaf.endMonth}{" "}
                              {mobileCluster.properties.reviewLeaf.endYear}{" "}
                            </Typography>
                          </CardContent>
                        </Grid>

                        <Grid item xxs={12}>
                          <CardContent
                            sx={{
                              flex: "1 0 auto",
                              fontSize: { xxs: 10, xs: 11, xs4:13  },
                              mt: {xxs:-4,xs:-4,xs4:-2,xs6:0}
                            }}
                            className={classes.propertyDetailsText}
                          >
                            {mobileCluster.properties.reviewLeaf.propertyDetails
                              .bedrooms ? (
                              <>
                                <BedOutlinedIcon
                                  sx={{
                                    color: "#606060",
                                    fontSize: {
                                      xxs: 16,
                                      xs: "large",
                                    },
                                  }}
                                />{" "}
                                {
                                  mobileCluster.properties.reviewLeaf
                                    .propertyDetails.bedrooms
                                }{" "}
                              </>
                            ) : (
                              <> </>
                            )}

                            {mobileCluster.properties.reviewLeaf.propertyDetails
                              .bathrooms ? (
                              <>
                                <BathtubOutlinedIcon
                                  sx={{
                                    color: "#606060",
                                    fontSize: { xxs: 14, xs: 16 },
                                    mb: 0.3,
                                  }}
                                />{" "}
                                {
                                  mobileCluster.properties.reviewLeaf
                                    .propertyDetails.bathrooms
                                }{" "}
                              </>
                            ) : (
                              <> </>
                            )}

                            {mobileCluster.properties.reviewLeaf.propertyDetails
                              .carSpaces ? (
                              <>
                                <DirectionsCarFilledOutlinedIcon
                                  sx={{
                                    color: "#606060",
                                    fontSize: { xxs: 15, xs: 17 },
                                    mb: 0.3,
                                  }}
                                />{" "}
                                {
                                  mobileCluster.properties.reviewLeaf
                                    .propertyDetails.carSpaces
                                }{" "}
                              </>
                            ) : (
                              <> </>
                            )}
                          </CardContent>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              ) : (
                <></>
              )}

              </>
            )}
          </>
        ) : (
          <></>
        )}

    </Box>
    </div>
  );
};

export default Map;
