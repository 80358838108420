import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";

import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

const OrderConfirmation = () => {
  return (
    <>
      <Navbar />
      <Grid container xxs={12}>
        <Grid item xxs={12} sm={12}>
          <Container sx={{ mb: 5 }}>
            <Typography
              variant="h2"
              style={{
                color: "black",
                fontFamily: "Open Sans",
                fontWeight: "700",
                fontSize: "45px",
              }}
              sx={{ mt: 20 }}
            >
              Thank you for your order
            </Typography>

            <Typography
              varient="body2"
              style={{
                color: "black",
              }}
              sx={{ mt: 5, mb: 2 }}
            >
              Your order and instructions have been sent to your email.
            </Typography>

            <Typography
              varient="body2"
              style={{
                color: "black",
              }}
              sx={{ mb: 5 }}
            >
                   {"When you are ready to action a call, you can find your order in your "}
                  
                  <Link href="/user/dashboard" variant="body2">
                  Dashboard
                </Link>

                  {"."}
            </Typography>

            <Divider light sx={{ mb: 5 }} />

            <Accordion sx={{ mt: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h7">Next Steps</Typography>
              </AccordionSummary>

              <AccordionDetails sx={{mb:2}}>

                <Typography variant="body2" sx={{ mb: 2 }}>
                  You may start a call request any time between 9am - 5pm, 7 days a week. 
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                   
                </Typography>


                <Typography variant="body2" sx={{ mb: 2 }}>
                  But before you do, have a think about the questions that you would like to ask to get the most of this experience.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Refer to the example questions that have been sent to your email for inspiration.
                </Typography>


                <Typography variant="body2" sx={{ mt: 2 }}>
                  There are a total of five (5) Call Credits for you to attempt to connect a conference call.
                </Typography>

                <Typography variant="body2" sx={{ mt: 2 }}>
                  Make sure to use up all five of your credits until you connect.
                </Typography>

                <Typography variant="body2" sx={{ mt: 2 }}>
                  In case the tenant does not answer your call requests, we will refund you if all your credits have been used within the Call Window.
                </Typography>

                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {"Please check your instruction email or visit the "}

                    <Link href="/faq">
                      Frequently Asked Questions
                    </Link>

                    {" page for more information."}
                  </Typography>

              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ mt: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h7">Our Policies</Typography>
              </AccordionSummary>

              <AccordionDetails>

                    <Box sx={{mb:1}}>
                      <Link href="/marketplace-terms" variant="body2">
                        Marketplace Terms & Conditions
                      </Link>
                    </Box>
                    <Box sx={{mb:1}}>
                      <Link href="/privacy-policy" variant="body2">
                        Privacy Policy
                      </Link>
                    </Box>
                    <Box sx={{mb:2}}>
                      <Link href="/website-terms" variant="body2">
                        Website Terms & Conditions
                      </Link>
                    </Box>


              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ mt: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h7">Contact Us</Typography>
              </AccordionSummary>

              <AccordionDetails>
              <Typography variant="body2" sx={{ mb: 2 }}>
                <a href = "mailto:enquiries@aussierentreviews.com.au">
                    enquiries@aussierentreviews.com.au
                  </a> 
              </Typography>
              

              </AccordionDetails>
            </Accordion>

          </Container>
        </Grid>
      </Grid>

      <StickyFooter />
    </>
  );
};

export default OrderConfirmation;
