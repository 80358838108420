import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({


    [theme.breakpoints.up("xxs")]: {

        imgListingCarousel: {
            width:"100%",
            height: 200,

        },

    },

    [theme.breakpoints.up("xs")]: {

        imgListingCarousel: {
            width:"100%",
            height: 225,

        },

    },

    [theme.breakpoints.up("xs4")]: {

        imgListingCarousel: {
            width:"100%",
            height: 350,

        },

    },

    [theme.breakpoints.up("sm")]: {

        imgListingCarousel: {
            width:"100%",
            height: 500,

        },
    },
    [theme.breakpoints.up("md")]: {

        imgListingCarousel: {
            width:"100%",
            height: 600,

        },
    },
    [theme.breakpoints.up("lg")]: {

        imgListingCarousel: {
            width:"100%",
            height: 700,

        },
    },


    }));