import * as actionType from '../constants/actionTypes.mjs';


export default (state = { isLoading: true, reviews: [] }, action) => {

    switch (action.type) {
        case actionType.FETCH_ALL:
            return { ...state, reviews: action.payload.data };
        case actionType.CREATE:
            return { ...state, reviews: [...state.reviews, action.payload] };

        case actionType.FETCH_BY_SEARCH:
            return {
                ...state,
                reviews: action.payload.data,
                currentPage: action.payload.currentPage,
                numberOfPages: action.payload.numberOfPages,
                priorSearchTerm: action.payload.priorSearchTerm,
                mapReviews: action.payload.mapData,
            };
        case actionType.FETCH_BY_CAN_URL:
                return { ...state, reviews: action.payload.data };

        case actionType.FETCH_BY_REVIEW_ID:
                return { ...state, reviewbyid: action.payload.data };

        case actionType.FETCH_BY_ID:
                return { ...state, reviews: action.payload.data };
        case actionType.START_LOADING:
            return { ...state, isLoading: true };
        case actionType.END_LOADING:
            return { ...state, isLoading: false };
        default:
            return state;
        case actionType.DELETE:
            return { ...state, reviews: state.reviews.filter((review) => review._id !== action.payload) };
        case actionType.UPDATE:
            return { ...state, reviews: state.reviews.map((review) => (review._id === action.payload._id ? action.payload : review)) };
    }

}

