import { Box, Button, Card, TextField, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useDispatch} from "react-redux";
import React, { useState, useEffect } from "react";
import { inspectionSubcriberForm } from "../../actions/users";

export const PostNewsletter = () => {


  const initialState = { email: ""};
  const [formData, setForm] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formData));
    setIsSubmit(true);
    

  };

  useEffect(() => {

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(inspectionSubcriberForm(formData));
    }

  }, [formErrors]);

  const validate = (values) => {

    const errors = {};

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;


    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    return errors;
  };


  return (
    
    <Card
      elevation={1}
      sx={{
        py: 5,
        px: 4,
        width:'50%'
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          xs={12}
          md={12}
          sx={{
            order: {
              xs: 1,
              md: 0
            }
          }}
        >
           <form
              autoComplete="off"
              noValidate
              className="centerContactForm"
              onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              label="Email address"
              name="email"
              sx={{ flexGrow: 1 }}
              type="email"
              onChange={(e) => setForm({ ...formData, email: e.target.value })}
            />
            <Button
              fullWidth
              size="large"
              sx={{ mt: 2 }}
              variant="contained"
              className='Button Submit' 
              type="submit" 
            >
              SEND TO INBOX
            </Button>
          </form>
          <Typography variant="body1" sx={{fontSize:14, mt:2}}>
          {formErrors.email}
          </Typography>

          {(isSubmit && Object.keys(formErrors).length === 0) ? (
              <Box>
                <Typography variant="body1" sx={{fontSize:14, mt:2}}>
                  PDF sent! Please check your inbox in a few minutes.
                </Typography>
              </Box>
            ) : (
              <Box ></Box>
            )}

        </Grid>

      </Grid>
    </Card>
  );
};
