import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";


const ReviewNotAvailable = () => {


  return (
    <>

      <Navbar />

      <Grid component="main" sx={{ height: "100vh" }}>
        <Grid
          mt={8}
          mb={2}
          container
          direction="column"
          alignItems="center"
          justify="center"
          item
          xxs={12}
        >
            <Box
            component="main"
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              minHeight: '60vh'
            }}
          >
             <Typography
            align="center"
            color="textPrimary"
            variant="h3"
          >
            This address or review is not yet available.
          </Typography>

          </Box>
  
        </Grid>
        <StickyFooter />
      </Grid>
    </>
  );
};

export default ReviewNotAvailable;
