import React from 'react';
import GoogleMapReact from 'google-map-react';
import CircleIcon from "@mui/icons-material/Circle";
import mapStyles from './mapStyles';
import useStyles from './reviewListingStyles';

const MapReviewListing = ({ places}) => {
  
  const classes = useStyles();
  const coordinates = { lat: places.reviewAddressCoordinate.lat, lng: places.reviewAddressCoordinate.lng }; 

  return (
    <div className={classes.mapContainer}>
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_PUBLIC_KEY }}
        defaultCenter={coordinates}
        center={coordinates}
        defaultZoom={16}
        margin={[50, 50, 50, 50]}
        options={{ disableDefaultUI: true, zoomControl: false, styles: mapStyles }}
      >
        <div
          lat={Number(coordinates.lat)}
          lng={Number(coordinates.lng)}
        >
                   <CircleIcon
                    style={{
                      color: "#F28C28",
                      stroke: "white",
                      strokeWidth: 1.5,
                      fontSize: 25,
                    }}
                  />

        </div>

      </GoogleMapReact>

    </>
    </div>
  );
};

export default MapReviewListing;
