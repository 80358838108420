import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import LazyLoad from 'react-lazy-load';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Carousel from "react-material-ui-carousel";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import MapIcon from "@mui/icons-material/Map";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";


import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
// import PerfectScrollbar from "react-perfect-scrollbar";


import { getReviewsBySearch } from "../../actions/reviews";
import {
  fetchUserBookmarks,
  createUserBookmark,
  deleteUserBookmark,
} from "../../actions/users";

import imgproperty from "../../assets/images/no-property-photo-available.png";
import "./styles.css";
import useStyles from "./styles";
import "../../index.css";

import Navbar from "../../components/Navbar/Navbar";
import Map from "../../components/Map/Map";
import StickyFooter from "../../components/Footer/Footer";

const Results = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  const userID = user?.result?._id;

  const {
    reviews: { reviews, isLoading, priorSearchTerm, numberOfPages, mapReviews },
    bookmarks: { bookmarks },
  } = useSelector((state) => state);


  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDefaultListView, setIsDefaultListView] = useState(true);

  //--- user bookmarks

  const [isAnyBookMarkChnged, setIsAnyBookMarkChnged] = useState(false);
  const [bookmarkShapeData, setBookmarkShapeData] = useState([]);

  useEffect(() => {
    //load up independant shape index to avoid re-rendering
    if (bookmarkShapeData.length == 0 && !isAnyBookMarkChnged) {
      setBookmarkShapeData(bookmarks);
    }
  }, [bookmarks]);

  const handleBookmarkDelete = async (e, revId) => {
    e.stopPropagation();
    let arr = bookmarkShapeData;
    let deletePosIndx = null;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].reviewId === revId) {
        deletePosIndx = i;
        arr.splice(deletePosIndx, 1);
        break;
      }
    }
    setBookmarkShapeData(arr);

    dispatch(deleteUserBookmark(userID, revId));
    setIsAnyBookMarkChnged(true);
  };

  const handleBookmarkSubmit = async (e, revId) => {
    e.stopPropagation();
    let arr = bookmarkShapeData;
    arr.push({ userId: userID, reviewId: revId });

    setBookmarkShapeData(arr);

    dispatch(createUserBookmark({ userId: userID, reviewId: revId }));
    setIsAnyBookMarkChnged(true);
  };

  const handleBookmarkSubmitNonLoggedIn = async (e) => {
    e.stopPropagation();
    navigate("/auth");
  };

  //---search params with pagination
  //pagination

  const [currPage, setcurrPage] = useState(1);

  const handleChange = (e, pg) => {
    setcurrPage(pg); //1 step lag, this sets currPage after code exits "handleChange".
    const searchTrim = priorSearchTerm;
    dispatch(getReviewsBySearch({ searchTrim }, pg));
  };

  //mounts on direct url visit
  const searchQuery = window.location.href.split("search/")[1];
  const searchTrim = searchQuery.trim();

  useEffect(() => {
    dispatch(getReviewsBySearch({ searchTrim }, 1));
    if (user) {
      dispatch(fetchUserBookmarks(userID));
    }
  }, [searchTrim]);

  // const [coords, setCoords] = useState({ lat: -33.96507, lng: 151.10647 }); //test
  const [bounds, setBounds] = useState(null);
  const [childClicked, setChildClicked] = useState(null);
  const [resultIsCluster, setResultIsCluster] = useState(null);
  

  return (
    <>
      <div>
          <Helmet>
            <title>Search Results - Aussie Rent Reviews</title>
            <meta name="description" content="Search all Aussie Rent Review listings and addresses here."/>
          </Helmet>
      </div>
      <Navbar />

      <AppBar
        component="div"
        position="fixed"
        indicatorColor="primary"
        style={{
          marginTop: "50px",
          backgroundColor: "#f9f9f9",
          boxShadow: "none",
          zIndex: 100,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          style={{ zIndex: 100 }}
        >
          {isDefaultListView ? (
            <IconButton
              style={{
                borderRadius: 5,
                backgroundColor: "#E8E8E8",
              }}
              onClick={() => setIsDefaultListView(false)}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: 10, fontWeight: 500, pr: 0.5 }}
              >
                Map View
              </Typography>
              <MapIcon
                variant="outlined"
                sx={{ color: "#333333", fontSize: 20 }}
              />
            </IconButton>
          ) : (
            <IconButton
              style={{
                borderRadius: 5,
                backgroundColor: "#E8E8E8",
              }}
              onClick={() => setIsDefaultListView(true)}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: 10, fontWeight: 500, pr: 0.5 }}
              >
                List View
              </Typography>

              <FormatListBulletedIcon
                variant="outlined"
                sx={{ color: "#333333", fontSize: 20 }}
              />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {isLoading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <Grid container sx={{ height: {xxs:"87vh",sm:"100vh"} }}>
            {isDefaultListView ? (
              <Grid
                item
                sx={{ display: { xxs: "none", lg: "grid" } }}
                xxs={false}
                xs={false}
                sm={false}
                md={false}
                lg={7}
              >
                <Box style={{ position: "-webkit-sticky", position: "sticky" }}>
                <Grid
                  sx={{
                    pl: { xxs: 0, lg: 2 },
                    pt: { xxs: 0, lg: 12 },
                    position: { xxs: false, lg: "sticky" },
                    top: 0,
                  }}
                >
                  {mapReviews ? (
                    <Map
                      places={mapReviews}
                      filteredPlaces={reviews}
                      setChildClicked={setChildClicked}
                      childClicked={childClicked}
                      bounds={bounds}
                      setBounds={setBounds}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                </Box>
              </Grid>
            ) : (
              <Grid item xxs={12}>
                {/* <Box style={{ position: "-webkit-sticky", position: "sticky" }}> */}

                <Grid
                  sx={{
                    pl: { xxs: 0, lg: 2 },
                    pt: { xxs: 0, lg: 12 },
                    position: { xxs: false, lg: "sticky" },
                    top: 0,
                  }}
                >
                  {mapReviews? (
                    <>
                      <Map
                        places={mapReviews}
                        filteredPlaces={reviews}
                        setChildClicked={setChildClicked}
                        childClicked={childClicked}
                        bounds={bounds}
                        setBounds={setBounds}

                        
                        // setCoords={setCoords}
                        // coords={coords}
                      />


                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
                {/* </Box> */}
              </Grid>
            )}

            {isDefaultListView ? (
              <Grid item xxs={12} xs={12} sm={12} md={12} lg={5}>
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 14,
                    maxWidth: { lg: 600 },
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <Box width={585}>
                      <Typography variant="h2" fontWeight={500} mb={5} mt={0}>
                        Reviews available
                      </Typography>
                      {/* add sort button */}
                    </Box>
                  </Grid>

                  {!reviews.length ? (
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justify="center"
                    >
                      <Box width={585}>
                        <Typography variant="body2" mb={60} mt={3} ml={0.2}>
                          This search found no results. Please try another
                          search query.
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction="column"
                      alignItems="left"
                      justify="center"
                    >
                      {reviews?.map((revItem, revId) => (
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justify="center"
                          style={{ minHeight: "5vh" }}
                        >
                          <Card
                            className={classes.root}
                            onClick={() =>
                              navigate(
                                `/reviews/address/${revItem.addressAlias}`
                              )
                            }
                            sx={{ mb: { xxs: 2 }, cursor: "pointer" }}
                          >
                            <Grid container spacing={3}>
                              <Grid item xxs={12}>
                                <Box>
                                  {revItem.propertyDetails.photos.length > 0 ? (
                                    <>
                                      <Box>
                                        <Carousel
                                          autoPlay={false}
                                          cycleNavigation={true}
                                          sx={{
                                            height: {
                                              xxs: 150,
                                              xs: 200,
                                              xs4: 300,
                                              sm: 300,
                                            },
                                          }}
                                          swipe={false}
                                          key={revId}
                                          indicators={false}
                                        >
                                          {revItem.propertyDetails.photos.map(
                                            (img, i) => (

                                              <LazyLoad>
                                                <img
                                                  key={i}
                                                  src={img}
                                                  className={classes.imgCarousel}
                                                  alt={"Rent Review for "+ revItem.reviewAddress} 
                                                />
                                              </LazyLoad>
                                            )
                                          )}
                                        </Carousel>
                                      </Box>
                                    </>
                                  ) : (
                                    <>
                                      <LazyLoad>
                                        <img
                                          src={imgproperty}
                                          className={classes.imgCarousel}
                                          alt="Default photo for Rent Review Listing"
                                        />
                                      </LazyLoad>
                                    </>
                                  )}
                                  <Grid container>
                                    <Grid item xxs={10}>
                                      <CardContent sx={{ flex: "1 0 auto" }}>
                                        <Typography variant="h5">
                                          {revItem.reviewAddress}
                                        </Typography>
                                      </CardContent>
                                    </Grid>
                                    <Grid
                                      item
                                      xxs={2}
                                      container
                                      direction="column"
                                      alignItems="end"
                                      justify="end"
                                      sx={{ pt: 1.5, pr: 2 }}
                                    >
                                      {isAnyBookMarkChnged ? (
                                        Object.keys(bookmarkShapeData).some(
                                          function (k) {
                                            return (
                                              bookmarkShapeData[k].reviewId ===
                                              revItem._id
                                            );
                                          }
                                        ) ? (
                                          <IconButton
                                            onClick={(e) =>
                                              handleBookmarkDelete(
                                                e,
                                                revItem._id
                                              )
                                            }
                                          >
                                            <StarRoundedIcon
                                              sx={{
                                                fontSize: 30,
                                                color: "#FDDA0D",
                                              }}
                                            />
                                          </IconButton>
                                        ) : (
                                          <>
                                            {user?.result ? (
                                              <IconButton
                                                onClick={(e) =>
                                                  handleBookmarkSubmit(
                                                    e,
                                                    revItem._id
                                                  )
                                                }
                                                sx={{
                                                  "& :hover": {
                                                    color: "#FDDA0D",
                                                  },
                                                }}
                                              >
                                                <StarBorderRoundedIcon
                                                  sx={{
                                                    fontSize: 30,
                                                    color: "#D0D0D0",
                                                  }}
                                                />
                                              </IconButton>
                                            ) : (
                                              <IconButton
                                                onClick={(e) =>
                                                  handleBookmarkSubmitNonLoggedIn(
                                                    e
                                                  )
                                                }
                                              >
                                                <StarBorderRoundedIcon
                                                  sx={{
                                                    fontSize: 30,
                                                    color: "#D0D0D0",
                                                  }}
                                                />
                                              </IconButton>
                                            )}
                                          </>
                                        )
                                      ) : Object.keys(bookmarks).some(function (
                                          k
                                        ) {
                                          return (
                                            bookmarks[k].reviewId ===
                                            revItem._id
                                          );
                                        }) ? (
                                        <IconButton
                                          onClick={(e) =>
                                            handleBookmarkDelete(e, revItem._id)
                                          }
                                        >
                                          <StarRoundedIcon
                                            sx={{
                                              fontSize: 30,
                                              color: "#FDDA0D",
                                            }}
                                          />
                                        </IconButton>
                                      ) : (
                                        <>
                                          {user?.result ? (
                                            <IconButton
                                              onClick={(e) =>
                                                handleBookmarkSubmit(
                                                  e,
                                                  revItem._id
                                                )
                                              }
                                              sx={{
                                                "& :hover": {
                                                  color: "#FDDA0D",
                                                },
                                              }}
                                            >
                                              <StarBorderRoundedIcon
                                                sx={{
                                                  fontSize: 30,
                                                  color: "#D0D0D0",
                                                }}
                                              />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              onClick={(e) =>
                                                handleBookmarkSubmitNonLoggedIn(
                                                  e
                                                )
                                              }
                                            >
                                              <StarBorderRoundedIcon
                                                sx={{
                                                  fontSize: 30,
                                                  color: "#D0D0D0",
                                                }}
                                              />
                                            </IconButton>
                                          )}
                                        </>
                                      )}
                                    </Grid>

                                    <Grid item xxs={12}>
                                      <CardContent sx={{ flex: "1 0 auto" }}>
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            mt: -4,
                                          }}
                                        >
                                          {revItem.startMonth}{" "}
                                          {revItem.startYear} -{" "}
                                          {revItem.endMonth} {revItem.endYear}{" "}
                                        </Typography>
                                      </CardContent>
                                    </Grid>
                                  </Grid>

                                  <Grid container>
                                    <Grid item xxs={9}>
                                      <CardContent
                                        sx={{
                                          flex: "1 0 auto",
                                          fontSize: 15,
                                          mt: -3,
                                        }}
                                      >
                                        {revItem.propertyDetails.bedrooms ? (
                                          <>
                                            <BedOutlinedIcon
                                              sx={{
                                                color: "#606060",
                                                fontSize: "large",
                                              }}
                                            />{" "}
                                            {revItem.propertyDetails.bedrooms}{" "}
                                          </>
                                        ) : (
                                          <> </>
                                        )}

                                        {revItem.propertyDetails.bathrooms ? (
                                          <>
                                            <BathtubOutlinedIcon
                                              sx={{
                                                color: "#606060",
                                                fontSize: "16px",
                                                mb: 0.3,
                                              }}
                                            />{" "}
                                            {revItem.propertyDetails.bathrooms}{" "}
                                          </>
                                        ) : (
                                          <> </>
                                        )}

                                        {revItem.propertyDetails.carSpaces ? (
                                          <>
                                            <DirectionsCarFilledOutlinedIcon
                                              sx={{
                                                color: "#606060",
                                                fontSize: "17px",
                                                mb: 0.3,
                                              }}
                                            />{" "}
                                            {revItem.propertyDetails.carSpaces}{" "}
                                          </>
                                        ) : (
                                          <> </>
                                        )}
                                      </CardContent>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      ))}

                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <Pagination
                          count={numberOfPages}
                          size="large"
                          page={currPage}
                          variant="outlined"
                          shape="rounded"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Container
                    disableGutters
                    maxWidth="sm"
                    component="main"
                    minHeight="80vh"
                  />
                </Box>

                <StickyFooter />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Results;
