import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createStripeOnboardingConfirm } from "../../actions/users";

const StripeOnboardConfirm = () => {

const dispatch = useDispatch();

  useEffect(() => {

    dispatch(createStripeOnboardingConfirm())
    .then((res) => {
        
        window.location = res.payload.url;
      })
      .catch((e) => {
        console.error(e.error);
      });


  }, []);

  return (
    <></>
  )

};

export default StripeOnboardConfirm;
