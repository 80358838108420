import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";
import LazyLoad from 'react-lazy-load';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/Circle";
import { getReviewListingByCanaconicalUrl, createCheckOut } from "../../actions/reviews";
import Navbar from "../../components/Navbar/Navbar";
import MapReviewListing from "../../components/Map/MapReviewListing";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import PermPhoneMsgRoundedIcon from "@mui/icons-material/PermPhoneMsgRounded";
import IconButton from "@mui/material/IconButton";
import StickyFooter from "../../components/Footer/Footer";
import imgproperty from "../../assets/images/no-property-photo-available.png";
import useStyles from "./styles";

const ReviewListing = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const addressAlias = location.pathname.split("address/")[1];

  const user = JSON.parse(localStorage.getItem("profile"));

  // mounts on direct url visit
  useEffect(() => {
    dispatch(getReviewListingByCanaconicalUrl(addressAlias));
  }, []);

  const { reviews } = useSelector((state) => state.reviews);

  const initialState = { id: "", addressName: "" };
  const [checkoutData, setCheckoutData] = useState(initialState);


  const handleSubmit = (e) => {

    if (user) {

      if (checkoutData.id !== "") (
        dispatch(createCheckOut(checkoutData))
        .then((res) => {
          
          window.location = res.payload.url;
        })
        .catch((e) => {
          console.error(e.error);
        })
      )

    } else {
      navigate("/auth");
    }
  };

  useEffect(() => {   
    if (checkoutData !== initialState) {
      handleSubmit();
    } 
  }, [checkoutData]);



  return (
    <>
      {reviews[0] ? (
        <div>
          <Helmet>
            <title>Tenant's Review for {reviews[0].reviewAddress}</title>
            <meta
              name="description"
              content={
                "Speak to a prior tenant of " +
                reviews[0].reviewAddress +
                " for their review on living in this specific property - Aussie Rent Reviews"
              }
            />
          </Helmet>
        </div>
      ) : (
        <></>
      )}

      <Navbar />

      <Grid component="main" sx={{ height: "100vh" }}>
        <Grid
          mt={8}
          mb={2}
          container
          direction="column"
          alignItems="center"
          justify="center"
          item
          xxs={12}
        >
          {reviews[0] ? (
            <>
              {reviews[0].propertyDetails.photos[0] ? (
                <Box sx={{ width: "100%", maxWidth: { md: 1000 } }}>
                  <Carousel
                    autoPlay={false}
                    cycleNavigation={true}
                    indicators={true}
                    sx={{
                      height: {
                        xxs: 200,
                        xs: 225,
                        xs4: 350,
                        sm: 500,
                        md: 600,
                        lg: 700,
                      },
                    }}
                    swipe={false}
                    indicatorIconButtonProps={{ style: { marginTop: -100 } }}
                  >
                    {reviews[0].propertyDetails.photos.map((img, i) => (
                      <LazyLoad>
                        <img
                          key={i}
                          src={img}
                          className={classes.imgListingCarousel}
                          alt={"Rent Review for " + reviews[0].reviewAddress}
                        />
                      </LazyLoad>
                    ))}
                  </Carousel>
                </Box>
              ) : (
                <Box
                  sx={{
                    height: {
                      xxs: 200,
                      xs: 225,
                      xs4: 350,
                      sm: 500,
                      md: 600,
                      lg: 700,
                    },
                  }}
                >
                  <LazyLoad>
                    <img
                      src={imgproperty}
                      className={classes.imgListingCarousel}
                      alt="Default photo for Rent Review Listing"
                    />
                  </LazyLoad>
                </Box>
              )}

              <Grid
                item
                xxs={12}
                container
                direction="column"
                alignItems="center"
                justify="center"
                mt={2}
              >
                <Container>
                  <Typography
                    variant="h2"
                    fontWeight={500}
                    sx={{ ml: { xxs: 0, md: 10 }, mr: { xxs: 0, md: 10 } }}
                    mt={2}
                  >
                    {reviews[0].reviewAddress}
                  </Typography>
                </Container>
              </Grid>

              <Grid
                item
                xxs={12}
                container
                direction="row"
                alignItems="center"
                justify="center"
                mt={3}
              >
                <Container>
                  {reviews[0].propertyDetails.bedrooms ? (
                    <>
                      <BedOutlinedIcon
                        sx={{
                          color: "#606060",
                          fontSize: "large",
                          ml: { xxs: 0, md: 10 },
                        }}
                      />{" "}
                      {reviews[0].propertyDetails.bedrooms}{" "}
                    </>
                  ) : (
                    <> </>
                  )}

                  {reviews[0].propertyDetails.bathrooms ? (
                    <>
                      <BathtubOutlinedIcon
                        sx={{
                          color: "#606060",
                          fontSize: "16px",
                          mb: 0.3,
                          ml: 0.5,
                        }}
                      />{" "}
                      {reviews[0].propertyDetails.bathrooms}{" "}
                    </>
                  ) : (
                    <> </>
                  )}

                  {reviews[0].propertyDetails.carSpaces ? (
                    <>
                      <DirectionsCarFilledOutlinedIcon
                        sx={{
                          color: "#606060",
                          fontSize: "17px",
                          mb: 0.3,
                          ml: 0.5,
                        }}
                      />{" "}
                      {reviews[0].propertyDetails.carSpaces}{" "}
                    </>
                  ) : (
                    <> </>
                  )}
                </Container>
              </Grid>

              <Grid
                item
                xxs={12}
                container
                direction="column"
                alignItems="center"
                justify="center"
                mt={2}
              >
                <Container>
                  <Divider
                    sx={{
                      ml: { xxs: 0, md: 9 },
                      mr: { xxs: 0, md: 9 },
                      mt: 2,
                      mb: 4,
                    }}
                  />

                  {reviews.length == 1 ? (
                    <Typography
                      variant="h3"
                      sx={{ ml: { xxs: 0, md: 10, mr: { xxs: 0, md: 10 } } }}
                      mt={2}
                    >
                      1 Review Available
                    </Typography>
                  ) : (
                    <Typography
                      variant="h2"
                      // fontWeight={500}
                      sx={{ ml: { xxs: 0, md: 10 }, mr: { xxs: 0, md: 10 } }}
                      mt={2}
                    >
                      {reviews.length} Reviews Available
                    </Typography>
                  )}
                </Container>

                <Container>
                  <Typography
                    variant="h6"
                    fontSize={16}
                    sx={{ ml: { xxs: 0, md: 10 }, mr: { xxs: 0, md: 10 } }}
                    mt={3}
                  >
                    Tenancy Period
                  </Typography>
                  <Box mt={3}></Box>

                  {reviews?.map((revItem, i) => (
                    <Card
                      sx={{
                        ml: { xxs: 0, md: 10 },
                        mr: { xxs: 0, md: 10 },
                        mt: 2,
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xxs={8}
                          sm={10}
                          md={10}
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <Typography
                            variant="h6"
                            fontWeight={400}
                            fontSize={16}
                            sx={{ ml: 3 }}
                            mt={2.5}
                            mb={2}
                          >
                            {" "}
                            {revItem.startMonth} {revItem.startYear} -{" "}
                            {revItem.endMonth} {revItem.endYear}{" "}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          sx={{ cursor: "pointer" }}
                          xxs={4}
                          sm={1}
                          md={1}
                          style={{ backgroundColor: "#fda94f" }}
                          onClick={() => {
                            setCheckoutData({
                              ...checkoutData,
                              id: revItem._id,
                              addressName: revItem.reviewAddress,
                            });
                          }}
                          direction="row"
                          alignItems="center"
                          justify="center"
                          display="flex"
                        >
                          <Container>
                            <IconButton
                              style={{
                                borderRadius: 100,
                                backgroundColor: "#fda94f",
                              }}
                              sx={{ ml: { xxs: -2, xs: 0, sm: -2, md: 0 } }}
                              onClick={() => {
                                setCheckoutData({
                                  ...checkoutData,
                                  id: revItem._id,
                                  addressName: revItem.reviewAddress,
                                });
                                // handleSubmit();
                              }}
                            >
                              <PermPhoneMsgRoundedIcon
                                variant="outlined"
                                sx={{
                                  color: "#FFFFFF",
                                  fontSize: { xxs: 25, xs: 25, sm: 30 },
                                }}
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: { xxs: 8, xs: 9, sm: 10 },
                                  fontWeight: 500,
                                  ml: 1,
                                }}
                                style={{ color: "#FFFFFF" }}
                              >
                                Access Review
                              </Typography>
                            </IconButton>
                          </Container>
                        </Grid>

                        <Grid
                          item
                          sx={{ cursor: "pointer" }}
                          xxs={1}
                          sm={1}
                          style={{ backgroundColor: "#fda94f" }}
                          onClick={() => {
                            setCheckoutData({
                              ...checkoutData,
                              id: revItem._id,
                              addressName: revItem.reviewAddress,
                            });
                            // handleSubmit();
                          }}
                        ></Grid>
                      </Grid>
                    </Card>
                  ))}

                  <Divider
                    sx={{
                      ml: { xxs: 0, md: 9 },
                      mr: { xxs: 0, md: 9 },
                      mt: 4,
                      mb: 4,
                    }}
                  />
                </Container>

                <Container>
                  <Typography
                    variant="h3"
                    sx={{
                      ml: { xxs: 0, md: 10, mr: { xxs: 0, md: 10 } },
                      mb: 1,
                    }}
                  >
                    Review Specifications
                  </Typography>

                  <List>
                    <ListItem sx={{ mt: 0, ml: { xxs: 0, md: 10 } }}>
                      <CircleIcon sx={{ fontSize: "7px", mr: 2 }} />

                      <ListItemText
                        primary={
                          <Typography type="body2">
                            Standard rate of $100 per Review (Conference Call).
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ mt: -2, ml: { xxs: 0, md: 10 } }}>
                      <CircleIcon sx={{ fontSize: "7px", mr: 2 }} />

                      <ListItemText
                        primary={
                          <Typography type="body2">
                            A 20-minute telephone conversation with a verified
                            tenant who has resided at this address.
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ mt: -2, ml: { xxs: 0, md: 10 } }}>
                      <CircleIcon sx={{ fontSize: "7px", mr: 2 }} />

                      <ListItemText
                        primary={
                          <Typography type="body2">
                            Immediate scheduling. Five (5) call attempts to use
                            any time between 9am - 5pm, 7 days a week.
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ mt: -2, ml: { xxs: 0, md: 10 } }}>
                      <CircleIcon sx={{ fontSize: "7px", mr: 2 }} />

                      <ListItemText
                        primary={
                          <Typography type="body2">
                            Full discretion over the property questions you may
                            ask, enabling a highly tailored review.
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ mt: -2, ml: { xxs: 0, md: 10 } }}>
                      <CircleIcon sx={{ fontSize: "7px", mr: 2 }} />

                      <ListItemText
                        primary={
                          <Typography type="body2">
                            Questions must relate to the tenant's experience
                            renting in the property.
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ mt: -2, ml: { xxs: 0, md: 10 } }}>
                      <CircleIcon sx={{ fontSize: "7px", mr: 2 }} />

                      <ListItemText
                        primary={
                          <Typography type="body2">
                            Both participant's details including name and phone
                            number remain anonymised.
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ mt: -2, ml: { xxs: 0, md: 10 } }}>
                      <CircleIcon sx={{ fontSize: "7px", mr: 2 }} />

                      <ListItemText
                        primary={
                          <Typography type="body2">
                            Detailed example questions are included. (Optional
                            to use)
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ mt: -2, ml: { xxs: 0, md: 10 } }}>
                      <CircleIcon sx={{ fontSize: "7px", mr: 2 }} />

                      <ListItemText
                        primary={
                          <Typography type="body2">
                            A refund is provided if the tenant (Reviewer) cannot
                            be reached within the designated Call Window.
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem sx={{ mt: -2, ml: { xxs: 0, md: 10 } }}>
                      <CircleIcon sx={{ fontSize: "7px", mr: 2 }} />

                      <ListItemText
                        primary={
                          <Typography type="body2">
                            Call Window: by 5pm of the subsequent two (2) days
                            after the date of purchase.
                          </Typography>
                        }
                      />
                    </ListItem>


                  </List>
                  <Typography
                    variant="body2"
                    sx={{
                      ml: { xxs: 0, md: 10, mr: { xxs: 0, md: 10 } },
                      mb: 3,
                      fontWeight:400,
                    }}
                  >
                    {"See "}

                    <HashLink smooth to="/faq">
                      Frequently Asked Questions
                    </HashLink>

                    {" for more information"}
                  </Typography>


                  <Divider
                    sx={{
                      ml: { xxs: 0, md: 9 },
                      mr: { xxs: 0, md: 9 },
                      mt: 1,
                      mb: 4,
                    }}
                  />
                </Container>

                <Container>
                  <MapReviewListing places={reviews[0]} />
                </Container>
              </Grid>
            </>
          ) : (
            <Box
            component="main"
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              minHeight: '60vh'
            }}
          >
             <Typography
            align="center"
            color="textPrimary"
            variant="h3"
          >
            This address or review is not available.
          </Typography>

          </Box>
          )}
        </Grid>
        <StickyFooter />
      </Grid>
    </>
  );
};

export default ReviewListing;
