import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import Carousel from "react-material-ui-carousel";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

import { fetchReviewsByUserBookmarks, fetchUserBookmarks, createUserBookmark, deleteUserBookmark } from "../../actions/users";

import imgproperty from "../../assets/images/no-property-photo-available.png";
import "./styles.css";
import useStyles from "./styles";
import "../../index.css";

import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

const MySavedReviews = () => {

  const user = JSON.parse(localStorage.getItem("profile"));
  const userID = user?.result?._id;

  const {
    reviews: { reviews, isLoading, priorSearchTerm, numberOfPages, mapReviews },
    bookmarks: { bookmarks },
  } = useSelector((state) => state);


  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //--- user bookmarks
  const [isAnyBookMarkChnged, setIsAnyBookMarkChnged] = useState(false);
  const [bookmarkShapeData, setBookmarkShapeData] = useState([]);

    useEffect(() => {
      //load up independant shape index to avoid re-rendering
      if ((bookmarkShapeData.length == 0) && (!isAnyBookMarkChnged)) {
        setBookmarkShapeData(bookmarks)
      }
  }, [bookmarks]);

  const handleBookmarkDelete = async (e,revId) => {
    e.stopPropagation();

    let arr = bookmarkShapeData
    let deletePosIndx = null

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].reviewId ===revId ) {
        deletePosIndx = i
        arr.splice(deletePosIndx, 1)
        break
      } 
    }
    setBookmarkShapeData(arr)

    dispatch(deleteUserBookmark(userID,revId));
    setIsAnyBookMarkChnged(true)
  };

  const handleBookmarkSubmit = async (e,revId) => {

    e.stopPropagation();
    let arr = bookmarkShapeData
    arr.push({ userId:userID,  reviewId:revId })

    setBookmarkShapeData(arr)

    dispatch(createUserBookmark({userId: userID, reviewId: revId}));
    setIsAnyBookMarkChnged(true)
  };


  useEffect(() => {
    dispatch(fetchReviewsByUserBookmarks(userID));
    dispatch(fetchUserBookmarks(userID));

  }, []);


  return (
    <>
      <Navbar />

        <>
        <div>
          <Helmet>
            <title>My Saved Reviews - Aussie Rent Reviews</title>
            <meta name="description" content="View your bookmarked reviews and addresses on the Aussie Rent Review platform."/>
          </Helmet>
        </div>

          <Grid container sx={{ height: "100vh" }}>
          
            <Grid item xxs={12}>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 12,
                  // maxWidth: { lg: 600 },
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <Box width={585}>
                    <Typography variant="h2" fontWeight={500} mb={5} mt={2}>
                      My Saved Reviews
                    </Typography>

                  </Box>
                </Grid>

                

                {!reviews.length? (
                  <Box width={585} minHeight={"50vh"}>
                    <Typography
                      variant="body1"
                      ml={0.2}
                    >
                      You have not yet saved any reviews
                    </Typography>
                  </Box>
                ) : (
                  <Grid
                    container
                    direction="column"
                    alignItems="left"
                    justify="center"
                  >
                    {reviews?.map((revItem, revId) => (
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: "5vh" }}
                      >
                        <Card className={classes.root}
                          onClick={() => navigate( `/reviews/address/${revItem.addressAlias}` ) } 
                          sx={{ mb: { xxs: 2 },  cursor: 'pointer'  }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xxs={12}>
           
                                {revItem.propertyDetails.photos[0] ? (
                                  <>
                                   <Box>
                                    <Carousel
                                      autoPlay={false}
                                      cycleNavigation={true}
                                      indicators={false}
                                      sx={{height:{xxs:150, xs:200, xs4:300, sm:300}}}
                                      swipe={false}
                                      key={revId}
                                    >
                                      {revItem.propertyDetails.photos.map(
                                        (img, i) => (
                                          <img
                                            key={i}
                                            src={img}
                                            // className="imgCarousel"
                                            className={classes.imgCarousel}
                                          />
                                        )
                                      )}
                                    </Carousel>
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={imgproperty}
                                      // className="imgCarousel"
                                      className={classes.imgCarousel}
                                    />
                                  </>
                                )}
                   
                                <Grid container sx={{pt:1}} >
                                  <Grid item xxs={10}>
                                    <CardContent sx={{ flex: "1 0 auto" }}>
                                      <Typography
                                        variant="h5"

                                      >
                                        {revItem.reviewAddress}
                                      </Typography>
                                    </CardContent>
                                  </Grid>
                                  <Grid
                                    item
                                    xxs={2}
                                    container
                                    direction="column"
                                    alignItems="end"
                                    justify="end"
                                    sx={{ pt: 1.5, pr: 2 }}
                                  >
                                    {isAnyBookMarkChnged?  

                                      Object.keys(bookmarkShapeData).some(function (k) {
                                        return ( bookmarkShapeData[k].reviewId === revItem._id );
                                      }) ? (
                                        <IconButton onClick={(e) => handleBookmarkDelete(e,revItem._id)}>
                                            <StarRoundedIcon sx={{fontSize:30, color:"#FDDA0D"  }} />
                                        </IconButton>
                                      ) : (

                                        <IconButton onClick={(e) =>handleBookmarkSubmit(e,revItem._id)} sx={{"& :hover": { color: "#FDDA0D" } }}>
                                            <StarBorderRoundedIcon sx={{fontSize:30, color:"#D0D0D0"  }} />
                                        </IconButton>
                                      )
                                    :
                                      Object.keys(bookmarks).some(function (k) {
                                        return (bookmarks[k].reviewId === revItem._id );
                                      }) ? (
                                        <IconButton onClick={(e) => handleBookmarkDelete(e,revItem._id)}>
                                            <StarRoundedIcon sx={{fontSize:30, color:"#FDDA0D" }} />
                                        </IconButton>
                                      ) : (
                                        <IconButton onClick={(e) =>  handleBookmarkSubmit(e,revItem._id)} sx={{"& :hover": { color: "#FDDA0D" } }} >
                                            <StarBorderRoundedIcon sx={{fontSize:30, color:"#D0D0D0" }} />
                                        </IconButton>
                                      )
                                    }


                                  </Grid>

                                  <Grid item xxs={12}>
                                    <CardContent sx={{ flex: "1 0 auto" }}>
                                      <Typography
                                        variant="body1"
                                        sx={{
             
                                          mt: -4,
                                        }}
                                      >
                                        {revItem.startMonth} {revItem.startYear}{" "}
                                        - {revItem.endMonth} {revItem.endYear}{" "}
                                      </Typography>
                                    </CardContent>
                                  </Grid>
                                </Grid>

                                <Grid container>
                                  <Grid item xxs={9}>
                                    <CardContent
                                      sx={{ flex: "1 0 auto", fontSize: 15, mt:-3 }}
                                    >
                                      {revItem.propertyDetails
                                        .bedrooms ? (
                                        <>
                                          <BedOutlinedIcon
                                            sx={{
                                              color: "#606060",
                                              fontSize: "large",
                                            }}
                                          />{" "}
                                          {
                                            revItem.propertyDetails
                                              .bedrooms
                                          }{" "}
                                        </>
                                      ) : (
                                        <> </>
                                      )}

                                      {revItem.propertyDetails
                                        .bathrooms ? (
                                        <>
                                          <BathtubOutlinedIcon
                                            sx={{
                                              color: "#606060",
                                              fontSize: "16px",
                                              mb: 0.3,
                                            }}
                                          />{" "}
                                          {
                                            revItem.propertyDetails
                                              .bathrooms
                                          }{" "}
                                        </>
                                      ) : (
                                        <> </>
                                      )}

                                      {revItem.propertyDetails
                                        .carSpaces ? (
                                        <>
                                          <DirectionsCarFilledOutlinedIcon
                                            sx={{
                                              color: "#606060",
                                              fontSize: "17px",
                                              mb: 0.3,
                                            }}
                                          />{" "}
                                          {
                                            revItem.propertyDetails
                                              .carSpaces
                                          }{" "}
                                        </>
                                      ) : (
                                        <> </>
                                      )}

                  
                                    </CardContent>
                                  </Grid>

                                </Grid>
                      
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ))}


                  </Grid>
                )}

              </Box>

              <StickyFooter />

              
            </Grid>

          </Grid>
          

        </>

    </>
  );
};

export default MySavedReviews;
