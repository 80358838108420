import React, { useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";

import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

const NewReviewSubmission = () => {

  const navigate = useNavigate()

  useEffect(() => {
      setTimeout(() => {
          navigate('/user/my-account')
      }, 12000)
      }, [])
 

  return (
    <>
      <Navbar />
      <Grid container xxs={12}>
        <Grid item xxs={12} sm={12}>
          <Container sx={{ mb: 5 }}>
            <Typography
              variant="h1"
              style={{
                color: "black",
                fontFamily: "Open Sans",
                fontWeight: "700",
                fontSize: "45px",
              }}
              sx={{ mt: 20 }}
            >
              Your review has been submitted!
            </Typography>
            <Typography
              varient="body2"
              style={{
                color: "black",
              }}
              sx={{ mt: 5, mb: 0 }}
            >
              We are working hard to validate your details! 
            </Typography>

            <Typography
              varient="body2"
              style={{
                color: "black",
              }}
              sx={{ mt: 2, mb: 0 }}
            >
              In the mean time, please create your Stripe account to enable payments. 
              Stripe handles your bank details securely as a third party service. No bank details are accesed or stored on this platform.
            </Typography>

            <Typography
              varient="body2"
              style={{
                color: "black",
              }}
              sx={{ mt: 2, mb: 5 }}
            >
              Redirecting you to your account for Stripe...
            </Typography>

            <Divider light sx={{ mb: 5 }} />

            <Accordion sx={{ mt: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h7">Contact Us</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography variant="body2" sx={{ mb: 2 }}>
      
                      <a href = "mailto:enquiries@aussierentreviews.com.au">
                          enquiries@aussierentreviews.com.au
                        </a> 
                
                </Typography>

              </AccordionDetails>
            </Accordion>
          </Container>
        </Grid>
      </Grid>

      <StickyFooter />
    </>
  );
};

export default NewReviewSubmission;
