import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DateTime } from "luxon";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import ReactJsAlert from "reactjs-alert"; 

import Navbar from "../../components/Navbar/Navbar";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import StickyFooter from "../../components/Footer/Footer";
import { SeverityPill } from "../../components/SeverityPill/SeverityPill";
import { fetchReviewsByUser, fetchOrdersByReviewer, fetchOrdersByBuyer  } from "../../actions/users";
import { createConferenceCall } from "../../actions/call";


const MyDashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [orderId, setConferenceOrderId] = useState({test:1}); /// Fix this
  //also add fetchOrdersByUser.... and thats were we get the reviewID from. 

  const user = JSON.parse(localStorage.getItem("profile"));
  const userID = user?.result?._id;

  useEffect(() => {
    dispatch(fetchReviewsByUser(userID));
    dispatch(fetchOrdersByReviewer(userID));
    dispatch(fetchOrdersByBuyer(userID));

  }, [userID]);
  

  const { reviews } = useSelector((state) => state.userReviews);
  const { ordersBuyer, ordersReviewer} = useSelector((state) => state.orders);

  const scrollContainer = {
    maxHeight: "700px",
  };


  const handleConferenceSubmit =  (orderId,isReturnCall) => {
    dispatch(createConferenceCall({orderId:orderId,isReturnCall:isReturnCall}));
  };

  //temporary alert handlers
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertTitle, setAlertTitle] = useState("");


  return (
    <>
      {user?.result ? (
        <>
         <div>
          <Helmet>
            <title>My Dashboard - Aussie Rent Reviews</title>
            <meta name="description" content="Manage your Aussie Rent Review orders and actions in this dashboard. "/>
          </Helmet>
          </div>
          <Navbar />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
            }}
          >
            <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item lg={8} md={8} xs={12} xxs={12}>
                  <form autoComplete="off" noValidate {...props}>
                    <Card>
                      <CardHeader
                        title="Dashboard"
                        sx={{ mb: -1, mt: 1, ml: 1 }}
                      />

                      <Divider sx={{ mt: 2, mb: 3 }} />

                      <Typography
                        variant="body1"
                        sx={{ pl: 3, mb:2, pr:3 }}
                        color="text.secondary"
                        style={{ display: "flex" }}
                      >
                        Please ensure your mobile number is up to date for each of your listed and purchased reviews. 
                      </Typography>

     
                      <Typography
                        variant="body1"
                        sx={{ pl: 3, mb:3, pr:3 }}
                        color="text.secondary"
                        style={{ display: "flex" }}
                      >
                        All calls will be hosted by Aussie Rent Reviews, keeping your phone and personal details private.
                      </Typography>

                    </Card>
                  </form>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
            }}
          >
             <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
              <Grid item xxs={12}>
                <Card {...props}>
                  <CardHeader title="My Review Listings" />
                  <CardContent sx={{mt:-3}}> 
                    <Typography variant='body2' sx={{fontSize:'14px'}} >These listings will earn for you when someone purchases your review.</Typography>
                  </CardContent>
  
                  <PerfectScrollbar style={scrollContainer}>
                    <Box>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" >Status</TableCell>
                            <TableCell align="center" >Address</TableCell>
                            <TableCell align="center" >Period</TableCell>
                            <TableCell align="center" >My Phone (Private)</TableCell>
                            <TableCell align="center" >Review ID</TableCell>
                            <TableCell align="center" ></TableCell>{" "}
                            {/* empty column header for actions */}
                            {/* <TableCell sortDirection="desc">
                                                    <Tooltip enterDelay={300} title="Sort">
                                                        <TableSortLabel active direction="desc">
                                                            Date
                                                        </TableSortLabel>
                                                    </Tooltip>
                                                </TableCell> */}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {reviews?.map((item) => (
                            <TableRow hover key={item.id}>
                              {/* <TableCell> {format(item.createdAt, 'dd/MM/yyyy')}</TableCell> */}
                              <TableCell align="center"  sx={{fontSize:14}}>
                                <SeverityPill
                                  color={
                                    (item.status === "Active" && "success") ||
                                    (item.status === "Denied" && "error") ||
                                    "warning"
                                  } //PENDING STATUS
                                >
                                  {item.status}
                                </SeverityPill>
                              </TableCell>
                              <TableCell align="center"  sx={{fontSize:14}}>{item.reviewAddress}</TableCell>
                              <TableCell align="center"  sx={{fontSize:14}}>
                                {item.startMonth} {item.startYear} -{" "}
                                {item.endMonth} {item.endYear}
                              </TableCell>

                              <TableCell  align="center" sx={{fontSize:14}}>{item.mobileNumber}</TableCell>
                              <TableCell align="center"  sx={{fontSize:14}}>{item._id}</TableCell>

                              <TableCell align="center" >
                                <Box>
                                <button
                                    className="btn-edit"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (item.addressAlias) {
                                      navigate(`/reviews/address/${item.addressAlias}`);
                                      } else {
                                        navigate(`/reviews/address/not-available`);
                                      }
                                    }}
                                  >
                                    View
                                  </button>
                                  |


                                  <button
                                    className="btn-edit"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate("/edit", { state: { reviewId: item._id } });
                                      
                                    }}
                                  >
                                    Edit
                                  </button>
                                  |
                                  <button
                                    className="btn-delete"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate("/reviews/confirm-delete", { state: { reviewId: item._id } });
                                    }}
                                  >
                                    Delete
                                  </button>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Grid>
            </Container>
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
            }}
          >
             <Container maxWidth="xl" sx={{  mb: 4 }}>
              <Grid item xxs={12}>
                <Card {...props}>
                  <CardHeader title="Orders (Reviewer)" />
                  <CardContent sx={{mt:-3}}> 
                    <Typography variant='body2' fontSize='14px'>Complete a conference call order to earn. The Buyer will call you within the Call Window.</Typography>
                  </CardContent>
  
                  <PerfectScrollbar style={scrollContainer}>
                    <Box>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" >Type</TableCell>
                            <TableCell align="center" >Status</TableCell>
                            <TableCell align="center" >My Phone (Private)</TableCell>
                            <TableCell align="center" >Action</TableCell>
                            <TableCell align="center" >Credits</TableCell>
                            <TableCell align="center" >Address</TableCell>
                            <TableCell align="center" >Period</TableCell>
                            <TableCell align="center" >Order Id</TableCell>

                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {ordersReviewer?.map((item) => (
                            <TableRow hover key={item.id}>
                              {/* <TableCell> {format(item.createdAt, 'dd/MM/yyyy')}</TableCell> */}
                              <TableCell align="center"  sx={{fontSize:14}}>SELL</TableCell>

                              <TableCell align="center"  sx={{fontSize:14}}>
                                <SeverityPill
                                  color={
                                    (item.status === "active" && "secondary") ||
                                    (item.status === "completed" && "green") ||
                                    (item.status === "expired" && "green") ||
                                    (item.status === "refunded" && "info") ||
                                    (item.status === "refund_dispute" && "warning") ||
                                    (item.status === "suspended" && "error") ||
                                    "warning"
                                  } //PENDING STATUS
                                >
                                  {item.status}
                                </SeverityPill>
                              </TableCell>
                              <TableCell align="center"  sx={{fontSize:14}}>{item.sellerMobile}</TableCell>
                              <TableCell align="center"  sx={{fontSize:14}}>
                                    <Button   variant="contained"  size="small" type="submit" 
                                        style={{
                                          borderRadius: "2px",
                                          backgroundColor: "#333333",
                                            fontFamily: 'Poppins', 
                                            fontWeight:'500' 
                                          }}
                                        sx={{ color: "#ffffff" }}
      
                                        // onClick={() => handleConferenceSubmit(item._id,true)}

                                        onClick={() => {
                                          setAlertStatus(true);
                                          setAlertType("warning");
                                          setAlertTitle("Feature Temporarily Unavailable");
                                        }}
                                      >
                                        Return Call
                                      </Button>
                                
                                </TableCell>
                              <TableCell align="center"  sx={{fontSize:14}}>{item.sellerCallCredits}</TableCell>
                              <TableCell align="center"  sx={{fontSize:14}}>{item.reviewAddress}</TableCell>
                              <TableCell align="center"  sx={{fontSize:14}}>
                                {item.startMonth} {item.startYear} -{" "}
                                {item.endMonth} {item.endYear}
                              </TableCell>

                              <TableCell align="center"  sx={{fontSize:14}}>{item._id}</TableCell>


                            </TableRow>
                          ))}
                        </TableBody>


                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Grid>
            </Container>
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              mt: 3,
              // minHeight:"25vh",
            }}
          >
            <Container maxWidth="xl">
              <Grid item xxs={12}>
                <Card {...props}>
                  <CardHeader title="Orders (Buyer)" />
                  <CardContent sx={{mt:-3}}> 
                    <Typography variant='body2' fontSize='14px'>Access and action your purchased reviews here. Start a call request to conduct a rent review. </Typography>
                  </CardContent>
                  <PerfectScrollbar style={scrollContainer}>
                    <Box>
                      <Table stickyHeader>
                          <TableHead>
                              <TableRow>
                                <TableCell align="center" >Type</TableCell>
                                <TableCell align="center" >Status</TableCell>
                                <TableCell align="center" >My Phone (Private)</TableCell>
                                <TableCell align="center" >Call Window (Ends At)</TableCell>
                                <TableCell align="center" >Credits</TableCell>
                                <TableCell align="center" >Action</TableCell>
                                
                                <TableCell align="center" >Address</TableCell>
                                <TableCell align="center" >Period</TableCell>
                                <TableCell align="center" >Order Id</TableCell>
                                <TableCell align="center" ></TableCell>{" "}
                                {/* empty column header for actions */}
                                {/* <TableCell sortDirection="desc">
                                                        <Tooltip enterDelay={300} title="Sort">
                                                            <TableSortLabel active direction="desc">
                                                                Date
                                                            </TableSortLabel>
                                                        </Tooltip>
                                                    </TableCell> */}
                              </TableRow>
                            </TableHead>

                        <TableBody>
                          {ordersBuyer?.map((item) => (
                            <TableRow hover key={item.id}>
                            {/* <TableCell> {format(item.createdAt, 'dd/MM/yyyy')}</TableCell> */}
                            <TableCell align="center"  sx={{fontSize:14}}>BUY</TableCell>

                            <TableCell align="center"  sx={{fontSize:14}}>
                            <SeverityPill
                                  color={
                                    (item.status === "active" && "secondary") ||
                                    (item.status === "completed" && "success") ||
                                    (item.status === "expired" && "warning") ||
                                    (item.status === "refunded" && "info") ||
                                    (item.status === "refund_dispute" && "warning") ||
                                    (item.status === "suspended" && "error") ||
                                    "warning"
                                  } 
                                >
                                  {item.status}
                            </SeverityPill>
                            </TableCell>
                            <TableCell align="center"  sx={{fontSize:14}}>{item.buyerMobile}</TableCell>
                            <TableCell align="center"  sx={{fontSize:14}}>
                              {"5pm, " + DateTime.fromISO(item.callWindowEndLocal).toLocaleString(DateTime.DATE_FULL)}
                              </TableCell>


                            <TableCell align="center"  sx={{fontSize:14}}>{item.buyerCallCredits}</TableCell>
                            <TableCell align="center"  sx={{fontSize:14}}>
                                    <Button   variant="contained"  size="small" type="submit" 
                                        style={{
                                          borderRadius: "2px",
                                          backgroundColor: "#333333",
                                            fontFamily: 'Poppins', 
                                            fontWeight:'500' 
                                          }}
                                        sx={{ color: "#ffffff" }}
      
                                        // onClick={() => handleConferenceSubmit(item._id,false)}
                                        onClick={() => {
                                          setAlertStatus(true);
                                          setAlertType("warning");
                                          setAlertTitle("Feature Temporarily Unavailable");
                                        }}


                                      >
                                        Start Call
                                      </Button>
                            </TableCell>

                            
                            <TableCell align="center"  sx={{fontSize:14}}>{item.reviewAddress}</TableCell>
                            <TableCell align="center"  sx={{fontSize:14}}>
                              {item.startMonth} {item.startYear} -{" "}
                              {item.endMonth} {item.endYear}
                            </TableCell>

                            <TableCell align="center"  sx={{fontSize:14}}>{item._id}</TableCell>

                            <TableCell align="center" >
                              <Box>
                                <button
                                  className="btn-edit"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate("/user/orders/edit", { state: { orderId: item._id } });
                                    
                                  }}
                                >
                                  Edit Phone
                                </button>

                              </Box>
                            </TableCell>
                          </TableRow>

                          ))}
                        </TableBody>
                      </Table>


                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Grid>
            </Container>
          </Box>

          
          <ReactJsAlert
                      status={alertStatus} // true or false
                      type={alertType} // success, warning, error, info
                      title={alertTitle}
                      quotes={true}
                      quote=
                      {"Concierge will be contacting you within business hours to complete your order."}
                      Close={() => setAlertStatus(false)}
                      autoCloseIn={100000}
                    />

          <Container
            disableGutters
            maxWidth="sm"
            component="main"
            sx={{ pb: 5 }}
          />
          <StickyFooter />
        </>
      ) : (
        <>
        <Navbar />
            <AccessDenied/>
        <StickyFooter />
        </>
      )}
    </>
  );
};
export default MyDashboard;
