import React from "react";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar/Navbar";
import StickyFooter from "../../components/Footer/Footer";

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

const PrivacyPolicy = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Privacy Policy - Aussie Rent Reviews</title>
        </Helmet>
      </div>
      <Navbar />

      <Grid container xxs={12} sx={{ minHeight: "70vh" }}>
        <Grid item xxs={12} sm={12}>
          <Container>
            <Grid container spacing={3} alignItems="center">
              <Box sx={{ ml: 4, mr: 1, mt: 15 }}>
                <p align="center">
                  <a name="_Toc8723798">Aussie rent reviews</a>
                </p>
                <p align="center">PRIVACY POLICY</p>
                <p>1 INTRODUCTION</p>
                <p>
                  This document sets out the privacy policy of Aussie Rent
                  Reviews Pty Ltd ACN 664109022 (referred to in this privacy
                  policy as ‘<strong>we</strong>’, ‘<strong>us</strong>’, or ‘
                  <strong>our’</strong>).
                </p>
                <p>
                  The <em>Privacy Act 1988</em> (Cth) (
                  <strong>Privacy Act</strong>) requires entities bound by the
                  Australian Privacy Principles to have a privacy policy. We
                  take our privacy obligations seriously and we’ve created this
                  privacy policy to explain how we store, maintain, use and
                  disclose personal information.
                </p>
                <p>
                  By providing personal information (including sensitive
                  information) to us, you consent to our storage, maintenance,
                  use and disclosing of personal information in accordance with
                  this privacy policy.
                </p>
                <p>
                  We may change this privacy policy from time to time by posting
                  an updated copy on our website and we encourage you to check
                  our website regularly to ensure that you are aware of our most
                  current privacy policy.
                </p>
                <p>
                  <a name="_Toc8723799">
                    2 TYPES OF PERSONAL INFORMATION WE COLLECT
                  </a>
                </p>
                <p>
                  The personal information we collect may include the following:
                </p>
                <p>(a) name;</p>
                <p>
                  (b) mailing or street address, including former residential
                  addresses;
                </p>
                <p>(c) email address;</p>
                <p>(d) social media information;</p>
                <p>(e) telephone number and other contact details;</p>
                <p>(f) age;</p>
                <p>(g) date of birth;</p>
                <p>
                  (h) sensitive information that you choose to, but do not have
                  to, provide to another user of our Platform located at &nbsp;
                  <a href="http://www.aussierentreviews.com.au/">
                    www.aussierentreviews.com.au
                  </a>
                  &nbsp; (<strong>Platform</strong>), such as information or an
                  opinion about yours or an individual’s racial or ethnic
                  origin, political opinions or associations, religious or
                  philosophical beliefs, sexual orientation or practices,
                  criminal record or health or genetic information);
                </p>
                <p>
                  (i) information in connection with client surveys,
                  questionnaires and promotions;
                </p>
                <p>
                  (j) your device identity and type, I.P. address, geo-location
                  information, page view statistics, advertising data and
                  standard web log information;
                </p>
                <p>(k) information about third parties; and</p>
                <p>
                  (l) any other information provided by you to us via our
                  website or our online presence, or otherwise required by us or
                  provided by you.
                </p>
                <p>
                  <a name="_Toc8723800">3 HOW PERSONAL INFORMATION</a>
                  IS COLLECTED
                </p>
                <p>
                  We will collect your personal information in a lawful and fair
                  way. We will only collect your personal information where you
                  have consented to it, or otherwise in accordance with the law.
                </p>
                <p>
                  <u>
                    How we collect information from<strong> you</strong>
                  </u>
                </p>
                <p>We may collect personal information where you:</p>
                <p>(a) contact us through our website;</p>
                <p>(b) receive goods or services from us;</p>
                <p>
                  (c) receive or provide reviews or otherwise interact with
                  other users on our Platform;
                </p>
                <p>(d) submit any of our online inquiry forms;</p>
                <p>
                  (e) communicate with us via email, telephone, SMS, social
                  applications (such as LinkedIn, Facebook or Twitter) or
                  otherwise;
                </p>
                <p>
                  (f) interact with our website, social applications, services,
                  content and advertising; and
                </p>
                <p>
                  (g) invest in our business or enquire as to a potential
                  purchase in our business.
                </p>
                <p>
                  <u>
                    How we collect information from{" "}
                    <strong>third parties</strong>
                  </u>
                </p>
                <p>
                  Where possible, we collect your personal information directly
                  from you. However, there may be occasions when we collect
                  personal information (including sensitive information) about
                  you from someone else. This would generally happen where a
                  user of our Platform shares information about you to another
                  user in a review of a property on our Platform.
                </p>
                <p>
                  <u>
                    How <strong>you</strong> provide information for someone
                    else
                  </u>
                </p>
                <p>
                  If you are providing personal and/or sensitive information on
                  behalf of someone else, you must have the consent of that
                  person to provide their personal and/or sensitive information
                  to us to be collected, used, and disclosed in accordance with
                  this privacy policy.
                </p>
                <p>
                  If you are providing personal and/or sensitive information on
                  behalf of someone under the age of 18 (<strong>Minor</strong>
                  ), you must be that Minor’s parent or legal guardian and you
                  must provide consent for the Minor’s personal and/or sensitive
                  information to be collected, used and disclosed in accordance
                  with this privacy policy.
                </p>
                <p>
                  <u>
                    How we collect information from <strong>cookies</strong>
                  </u>
                </p>
                <p>
                  We may also collect personal information from you when you use
                  or access our website or our social media pages. This may be
                  done through use of web analytics tools, ‘cookies’ or other
                  similar tracking technologies that allow us to track and
                  analyse your website usage. Cookies are small files that store
                  information on your computer, mobile phone or other device and
                  enable and allow the creator of the cookie to identify when
                  you visit different websites. If you do not wish information
                  to be stored as a cookie, you can disable cookies in your web
                  browser.
                </p>
                <p>
                  We may use Google Analytics to collect and process data,
                  including when you use third party websites or apps. To find
                  out more see &nbsp;
                  <a href="http://www.google.com/policies/privacy/partners/">
                    How Google uses data when you use our partners’ sites or
                    apps.
                  </a>
                  &nbsp;
                </p>
                <p>
                  <a name="_Toc8723801">4 USE OF YOUR PERSONAL INFORMATION</a>
                </p>
                <p>
                  We collect and use personal information for the following
                  primary purposes:
                </p>
                <p>(a) to provide goods, services or information to you;</p>
                <p>
                  (b) to store on our Platform as a recording for other users of
                  the Platform to purchase but only in circumstances where you
                  choose to provide such information as part of a conference
                  call that you receive or provide relating to a property review
                  through our Platform;
                </p>
                <p>(c) for record keeping and administrative purposes;</p>
                <p>
                  (d) to provide information about you to our contractors,
                  employees, consultants, agents or other third parties for the
                  purpose of providing goods or services to you;
                </p>
                <p>
                  (e) to improve and optimise our service offering and customer
                  experience;
                </p>
                <p>
                  (f) to comply with our legal obligations, resolve disputes or
                  enforce our agreements with third parties;
                </p>
                <p>
                  (g) to send you administrative messages, reminders, notices,
                  updates, security alerts, and other information requested by
                  you; and
                </p>
                <p>(h) to consider an application of employment from you.</p>
                <p>
                  <br />
                  We may also use your personal information for:
                </p>
                <p>
                  (i) secondary purposes closely related to the primary purpose,
                  in circumstances where you would reasonably expect such use;
                </p>
                <p>
                  (j) such purposes where we reasonably believe that use of your
                  personal information is necessary to lessen or prevent a
                  serious threat to the life, health or safety of any
                  individual, or to public health or safety, and it is
                  unreasonable or impracticable to obtain your consent;
                </p>
                <p>
                  (k) any other purpose for which we receive consent from you;
                  or
                </p>
                <p>
                  (l) any other purpose which is permitted or required under
                  applicable privacy laws.
                </p>
                <p>5 HOW WE DISCLOSE YOUR PERSONAL INFORMATION</p>
                <p>
                  We respect your privacy, and we will take reasonable steps to
                  keep your personal information confidential and protected. We
                  may disclose your personal information to:
                </p>
                <p>
                  (a) any user of our Platform but only where such information
                  was provided by you during a call conference as part of a
                  review service on our Platform, and was recorded by our
                  Platform (in this case any of your former addresses and tenure
                  details will be de-identified by us);
                </p>
                <p>
                  (b) our professional advisors such as lawyers, accountants and
                  auditors;
                </p>
                <p>(c) our related entities; or</p>
                <p>
                  (d) any third parties you have consented personal information
                  to be disclosed to.
                </p>
                <p>
                  We may also disclose personal information to third party
                  contractors as required for us to provide our goods and
                  services to you, such as cloud-service providers, IT
                  professionals, marketing agencies and debt collection
                  agencies.
                </p>
                <p>
                  We take care to work with such third parties who we believe
                  maintain an acceptable standard of data security and require
                  them not to use your personal information for any purpose
                  except for those activities we have asked them to perform on
                  our behalf.
                </p>
                <p>
                  We will not otherwise disclose your personal information
                  unless:
                </p>
                <p>
                  (e) you have consented to us disclosing your personal
                  information for particular circumstances;
                </p>
                <p>
                  (f) as needed in an emergency or in investigation suspected
                  criminal activity;
                </p>
                <p>
                  (g) we are required to disclose under a subpoena, court order
                  or other mandatory reporting requirements;
                </p>
                <p>
                  (h) we reasonably believe that disclosure of your information
                  is necessary to lessen or prevent a serious threat to the
                  life, health or safety of any individual, or to public health
                  or safety, and it is unreasonable or impracticable to obtain
                  your consent;
                </p>
                <p>
                  (i) it is reasonably necessary for the establishment, exercise
                  or defence of a legal claim; or
                </p>
                <p>(j) it is otherwise authorised or required by law.</p>
                <p>6 storage of YOUR PERSONAL INFORMATION</p>
                <p>
                  All personal information we collect is stored on servers
                  either located in Australia or in the USA. Our server provider
                  in the USA is Mongodb and their privacy policy may be found{" "}
                  <a href="https://www.mongodb.com/legal/dpa">here</a>.
                </p>
                <p>
                  The cloud service providers we engage may operate overseas
                  disaster recovery sites or have personnel overseas who may
                  access the personal information we hold to assist us in
                  managing our servers.
                </p>
                <p>
                  We also may use Google Analytics to track web traffic
                  information which is operated by Google which stores
                  information across multiple countries.
                </p>
                <p>
                  When you communicate with us through a social media service
                  such as Facebook or Twitter, the social media provider and its
                  partners may collect and hold your personal information
                  overseas.
                </p>
                <p>
                  7 SENSITIVE INFORMATION<u></u>
                </p>
                <p>
                  <strong>
                    <u>Collection</u>
                  </strong>
                  <u> of sensitive information</u>
                </p>
                <p>
                  We will only collect sensitive information about you where
                  such information was provided by you or a third party as part
                  of receiving or providing a property review service through
                  our Platform and was recorded by our Platform; We will only
                  collect this sensitive information where you consent to such
                  collection and either directly provide us with this
                  information or it is provided by a referring health care
                  provider.
                </p>
                <p>
                  <strong>
                    <u>Types</u>
                  </strong>
                  <u> of sensitive information we collect</u>
                </p>
                <p>
                  The sensitive information we collect may include information
                  or an opinion about yours or another individual’s:
                </p>
                <p>(a) health information;</p>
                <p>(b) racial or ethnic origin;</p>
                <p>(c) political opinions or associations;</p>
                <p>(d) religious or philosophical beliefs;</p>
                <p>(e) sexual orientation or practices;</p>
                <p>(f) criminal record or health or genetic information;</p>
                <p>
                  (g) any other sensitive information provided by you or a third
                  party to us via our website or platforms, or otherwise
                  provided by you or a third party to us.
                </p>
                <p>
                  <u>
                    How we <strong>use</strong> your sensitive information
                  </u>
                </p>
                <p>
                  Your sensitive information will only be used for the purpose
                  of:
                </p>
                <p>(h) providing you with our goods and services;</p>
                <p>
                  (i) to store on our Platform as a recording for other users of
                  the Platform to purchase but only in circumstances where such
                  information is provided as part of a review of a property
                  through our Platform that you receive or provide;
                </p>
                <p>
                  (j) complying with our legal obligations, resolving disputes
                  or enforcing our agreements with you;
                </p>
                <p>
                  (k) sending you messages, reminders, notices, updates,
                  security alerts, and other information requested by you; or
                </p>
                <p>
                  (l) any other purpose which is permitted or required under
                  applicable privacy laws.
                </p>
                <p>
                  <u></u>
                </p>
                <p>
                  <u>
                    How we <strong>disclose</strong> your sensitive information
                  </u>
                </p>
                <p>
                  Your sensitive information will only be disclosed to third
                  parties for the purpose of:
                </p>
                <p>
                  (m) those parties purchasing a recording of a property review
                  on our Platform, where such a recording contains your
                  sensitive information; or
                </p>
                <p>
                  (n) any other purpose which is permitted or required under
                  applicable privacy laws.
                </p>
                <p>
                  <u>
                    How you can <strong>withdraw</strong>{" "}
                    <strong>consent</strong>
                  </u>
                </p>
                <p>
                  If you wish to withdraw your consent to our collection, use or
                  disclosure of your sensitive information, please contact us
                  using the contact details set out below. We will deal with all
                  such requests within a reasonable timeframe.
                </p>
                <p>
                  <a name="_Toc8723802">8 MARKETING</a>
                </p>
                <p>
                  We may at times send you marketing communications which will
                  be done in accordance with the <em>Spam Act 2003</em> (Cth) (
                  <strong>Spam Act</strong>
                  ).
                </p>
                <p>
                  If we do, we may use email, SMS, social media, phone or mail
                  to send you direct marketing communications.
                </p>
                <p>
                  Where consent is needed, we will ask you for your consent
                  before sending you marketing communications, except where you:
                </p>
                <p>
                  (a) have explicitly opted-in to receiving email marketing from
                  us in the past; or
                </p>
                <p>
                  (b) were given the option to opt-out of email marketing when
                  you initially signed up for one of our platforms and you did
                  not do so.
                </p>
                <p>
                  You can, at any time, opt out of receiving marketing materials
                  from us by using the opt-out facility provided (e.g., an
                  unsubscribe link on emails we send you) or by contacting us
                  via the details provided at the end of this privacy policy. We
                  will implement such a request as soon as possible, however,
                  cannot guarantee that such a response will be immediate.
                </p>
                <p>9 DE-IDENTIFIED INFORMATION</p>
                <p>
                  The information we collect may have analytical, educational,
                  or commercial value to us. Where we have de-identified the
                  information we have collected, we reserve the right to process
                  and distribute such information.
                </p>
                <p>10 SECURITY</p>
                <p>
                  We take reasonable steps to ensure your personal information
                  is secure and protected from misuse or unauthorised access.
                  Our information technology systems are password protected, and
                  we use a range of administrative and technical measures to
                  protect these systems. However, we cannot guarantee the
                  security of your personal information.
                </p>
                <p>
                  <a name="_Toc8723803">11 LINKS</a>
                </p>
                <p>
                  Our website may contain links to other websites. Those links
                  are provided for convenience and may not remain current or be
                  maintained. We are not responsible for the privacy practices
                  of those linked websites and we suggest you review the privacy
                  policies of those websites before using them.
                </p>
                <p>
                  <a name="_Toc8723804">
                    12 REQUESTING ACCESS OR CORRECTING YOUR PERSONAL INFORMATION
                  </a>
                </p>
                <p>
                  If you wish to request access to the personal information we
                  hold about you, please contact us using the contact details
                  set out below including your name and contact details. We may
                  need to verify your identity before providing you with your
                  personal information. In some cases, we may be unable to
                  provide you with access to all your personal information and
                  where this occurs, we will explain why. We will deal with all
                  requests for access to personal information within a
                  reasonable timeframe.
                </p>
                <p>
                  If you think that any personal information we hold about you
                  is inaccurate, please contact us using the contact details set
                  out below and we will take reasonable steps to ensure that it
                  is corrected.
                </p>
                <p>
                  <a name="_Toc8723805">13 COMPLAINTS</a>
                </p>
                <p>
                  If you wish to complain about how we handle your personal
                  information held by us, please contact us using the details
                  set out below including your name and contact details. We will
                  investigate your complaint promptly and respond to you within
                  a reasonable timeframe.
                </p>
                <p>
                  <a name="_Toc8723806">14 CONTACT US</a>
                </p>
                <p>
                  For further information about our privacy policy or practices,
                  or to access or correct your personal information, or make a
                  complaint, please contact us using the details set out below:
                </p>
                <p>
                  <strong>Email</strong>: enquiries@aussierentreviews.com.au
                </p>
                <p>Our privacy policy was last updated on 17 January 2023.</p>
              </Box>
            </Grid>
          </Container>
        </Grid>
      </Grid>

      <StickyFooter />
    </>
  );
};

export default PrivacyPolicy;
