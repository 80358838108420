import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({

  root: {
    "& .MuiFormLabel-root ": {
      "font-size": "16px !important",
    },
    '& label.Mui-focused': {
      color: 'gray',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'light-grey',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },

  },

}));
